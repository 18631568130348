import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import useStyles from './styles';

const EmrTable = ({ data }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Year</TableCell>
            <TableCell align="center">emr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {data && data.length > 0 ? (
              data
                .sort((a, b) => b.year - a.year)
                .map(row => (
                  <TableRow key={row.year}>
                    <TableCell scope="row" align="center">
                      {row.year}
                    </TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan="3" align="center">
                  No records to display
                </TableCell>
              </TableRow>
            )}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmrTable;
