import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { title, sectionKey } from '../../routes/path';
import { currentCompany } from '../../../actionTypes';
import EMRateForm from '../../containers/EMRate';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const EMRatePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { emrInfo, emrArrays, loading, success, error, naicsCodes } = useSelector(store => store.currentCompany.emrate);

  useEffect(() => {
    dispatch({ type: currentCompany.emrate.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleSubmit = values => {
    dispatch({
      type: currentCompany.emrate.update.start,
      payload: { companyId, values, sectionKey: sectionKey.private.company.emr },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.emr, { companyName })}</title>
      </Helmet>
      <EMRateForm
        handleSubmit={handleSubmit}
        loading={loading}
        emrInfo={emrInfo}
        emrArrays={emrArrays}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
        companyId={companyId}
        naicsCodes={naicsCodes}
      />
    </>
  );
};

export default EMRatePage;
