import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormSwitch = ({ handleChange, size = 'small', field: { onChange, ...fieldProps }, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...props}
          {...fieldProps}
          checked={fieldProps.value}
          color="primary"
          onChange={e => {
            handleChange && handleChange(e, fieldProps);
            onChange(e);
          }}
        />
      }
    />
  );
};

export default FormSwitch;
