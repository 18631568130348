import { call, put, takeLatest } from 'redux-saga/effects';

import { settings, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { values } }) {
  try {
    const { data } = yield call(dataProvider.createList, 'bls', values);

    yield put({
      type: settings.BLSData.update.end,
      payload: { data },
    });
  } catch (e) {
    yield put({ type: settings.configuration.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateBLSData() {
  yield takeLatest(settings.BLSData.update.start, update);
}
