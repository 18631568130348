import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField } from 'formik';

import FormInput from '../../../components/FormInput';
import useStyles from './styles';

const ParagraphType = ({ label, name, level, readOnly }) => {
  const classes = useStyles({ level });

  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={8}>
        <Typography variant="subtitle2" gutterBottom className={classes.innerWrapper}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FastField name={name} component={FormInput} disabled={readOnly} />
      </Grid>
    </Grid>
  );
};

export default ParagraphType;
