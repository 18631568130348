import React from 'react';
import { Button } from '@material-ui/core';

import useStyles from '../styles';

const ShowMoreLessButton = ({ moreFilter, setMoreFilter }) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      color="primary"
      classes={{
        root: classes.button,
      }}
      fullWidth
      onClick={() => setMoreFilter(!moreFilter)}
    >
      {moreFilter ? 'less' : 'more'} filters
    </Button>
  );
};

export default ShowMoreLessButton;
