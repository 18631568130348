import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogTitle, DialogContent, Grid, Typography } from '@material-ui/core';
import { Field, FieldArray, Formik } from 'formik';

import FormTypes from '../FormTypes';
import useStyles from './styles';
import schema from './validation';
import FormSwitch from '../../../components/FormSwitch';

const SwitchWrapper = props => {
  const countParams = props.form.values.companyRules.length > 0;
  useEffect(() => {
    if (props.form.dirty) {
      props.form.setFieldValue(props.field.name, countParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countParams]);
  return <FormSwitch disabled={countParams} {...props} />;
};

const ModalContainer = ({ open, options, data, handleClose, handleClear, handleSave }) => {
  const classes = useStyles();
  const onSubmit = values => {
    handleSave(data.id, values);
    handleClose();
  };

  const initialValues = {
    ...data,
    description: (data && data.description) || '',
    companyRules:
      (data &&
        data.companyRules.map(item => ({
          ...item,
          conditionValue: item.conditionValue || '',
        }))) ||
      [],
  };

  const createOptions =
    data &&
    data.key &&
    options
      .filter(item => item.pqfFieldKey === data.key)
      .map(opt => ({
        value: opt.id,
        label: opt.text,
      }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={data && schema(data.type, data.dropdownType, createOptions)}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, resetForm, dirty, isValid }) => (
        <Dialog
          open={open}
          onClose={handleClose}
          onExited={() => {
            resetForm();
            handleClear();
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          <DialogTitle>{data && data.questionTitle}</DialogTitle>
          <DialogContent>
            {data && (
              <FieldArray
                name="companyRules"
                render={arrayHelpers => (
                  <FormTypes
                    type={data.type}
                    createOptions={createOptions}
                    values={values.companyRules}
                    optionType={data.dropdownType}
                    pqfFieldId={data && data.id}
                    arrayHelpers={arrayHelpers}
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions classes={{ root: classes.modalActionsRow }}>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="body1"> Visibility </Typography>
                    </Grid>
                    <Grid item>
                      <Field name="visibility" component={SwitchWrapper} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        onClick={handleSubmit}
                        disabled={!dirty || !isValid}
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={handleClose} variant="contained" size="small" autoFocus>
                        close
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default ModalContainer;
