import { call, put, takeLatest } from 'redux-saga/effects';

import { notification, pages } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* download({ payload: { companyId, ownerId } }) {
  try {
    const { data } = yield call(dataProvider.getOnly, `companies/${companyId}/grade/scores/owner/${ownerId}`, {});

    yield put({ type: pages.dashboard.getOwnerGrading.end, payload: { ownerGradingList: data.scores } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: pages.dashboard.getOwnerGrading.error, payload: [] });
  }
}

export default function* ownerGradingSaga() {
  yield takeLatest(pages.dashboard.getOwnerGrading.start, download);
}
