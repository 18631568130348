import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    width: theme.spacing(5),
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      cursor: 'pointer',
    },
  },
  textContainer: {
    width: `calc(100% - ${theme.spacing(5.5)}px)`,
  },
  text: {
    fontSize: theme.typography.subtitle1.fontSize,
    cursor: 'pointer',
  },
}));

export default useStyles;
