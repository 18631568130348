import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';

import { workCategory as workCategorySchema } from '../../../schema';
import { currentCompany, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* updateWorkCategory({ payload: { companyId, values, sectionKey } }) {
  try {
    const categories = yield call(dataProvider.createList, `companies/${companyId}/work-categories`, values);
    const listCategories = normalize(categories.data.data, [workCategorySchema]);
    yield put({ type: entity.add, payload: listCategories.entities });
    yield put({
      type: currentCompany.workCategory.update.end,
      payload: { categories: listCategories.result },
    });
    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.workCategory.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateWorkCategorySaga() {
  yield takeLatest(currentCompany.workCategory.update.start, updateWorkCategory);
}
