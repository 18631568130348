import { call, put, takeLatest } from 'redux-saga/effects';

import { file, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* add({ payload: { files, companyId, sectionKey } }) {
  try {
    const { data } = yield call(
      dataProvider.createList,
      `companies/${companyId}/related-document/${sectionKey}/save`,
      files,
    );

    yield put({ type: file.add.end, payload: { files: data.map(file => ({ ...file, companyId })) } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: file.add.error });
  }
}

export default function* addFilesSaga() {
  yield takeLatest(file.add.start, add);
}
