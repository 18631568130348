import React, { useState } from 'react';
import { Typography, Button, CircularProgress } from '@material-ui/core';

import GradeModal from '../../GradeModal';
import useStyles from './styles';

const InProgressNotification = ({
  creation,
  handleSubmitDocument,
  downloading,
  grade,
  list,
  loading,
  getUnOfficialGrade,
  handleDownloadGrade,
}) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Typography variant="h5">
        You have started the PQF generation process. To complete it click
        {creation && <CircularProgress size={22} className={classes.progress} />}
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={creation}
          onClick={handleSubmitDocument}
          className={classes.button}
        >
          Submit PQF
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          disabled={creation}
          onClick={() => {
            setModalOpen(true);
            getUnOfficialGrade();
          }}
          className={classes.button}
        >
          Check preliminary grade
        </Button>
      </Typography>
      <GradeModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        list={list}
        gradeLetter={grade}
        loadingData={loading && modalOpen}
        loading={downloading}
        handleDownloadGrade={handleDownloadGrade}
      />
    </>
  );
};

export default InProgressNotification;
