import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { companyId, values, sectionKey } }) {
  try {
    yield call(dataProvider.createOne, `companies/${companyId}/largest-job`, values);
    yield put({
      type: currentCompany.reference.updateJobInfo.end,
      payload: values,
    });
    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.reference.updateJobInfo.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateJobInfoSaga() {
  yield takeLatest(currentCompany.reference.updateJobInfo.start, update);
}
