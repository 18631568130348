import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import isEqual from 'lodash.isequal';

import useStyles from './styles';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import EmrRateInfo from './EmrRateInfo';
import CommonTableForm from '../CommonTable';
import { emrTableSchema } from '../../../utils/validate/shemas';
import FormNumberInput from '../../components/FormNumberInput';
import PromptNotification from '../../components/PromptComponent';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { getCurrentYearsOptions } from '../../../utils/constants/yearHelper';

const EMRateForm = ({
  handleSubmit,
  loading,
  emrArrays,
  emrInfo,
  success,
  error,
  allowUpdate,
  companyId,
  naicsCodes,
}) => {
  const classes = useStyles();
  const { isBasicContractor } = useCompanyInfo(companyId);

  const [status, setStatus] = useState({
    form: {
      values: {},
      dirty: false,
      isValid: isBasicContractor,
    },
    table: {
      values: [],
      changed: false,
    },
  });

  const handleSubmitClick = () => {
    const naicsIdArray = naicsCodes.filter(obj => obj.code === status.form.values.naicsCode);

    const valuesToSend = {
      emrInfo: {
        ...status.form.values,
        state: status.form.values.state || null,
        anniversaryDate: status.form.values.anniversaryDate || null,
        sic: status.form.values.sic || null,
        naicsCode: status.form.values.naicsCode || null,
        naicsId: naicsIdArray.length ? naicsIdArray[0].id : null,
      },
      emrs: status.table.values.map(item => ({
        id: item.saveId,
        year: item.year,
        amount: parseFloat(item.amount),
      })),
    };
    handleSubmit(valuesToSend);

    setStatus({
      form: {
        ...status.form,
        dirty: false,
      },
      table: {
        ...status.table,
        changed: false,
      },
    });
  };

  if (loading && !Object.keys(emrInfo).length) return <PageLoader />;
  const canSubmit = (status.form.isValid && status.form.dirty) || (status.form.isValid && status.table.changed);

  const tableData = emrArrays.map(item => ({ ...item, saveId: item.id, id: item.year }));

  return (
    <>
      <PromptNotification formStatus={status.form.dirty || status.table.changed} />
      <FormBody loading={loading} disabled={!allowUpdate}>
        <Grid item lg={12}>
          <EmrRateInfo
            isBasicContractor={isBasicContractor}
            companyId={companyId}
            emrInfo={emrInfo}
            handleSubmit={handleSubmitClick}
            formUpdate={(values, dirty, isValid) => setStatus({ ...status, form: { values, dirty, isValid } })}
            naicsCodes={naicsCodes}
          />
          <Grid container>
            <Typography variant="h5" gutterBottom className={classes.title}>
              EMR per year
            </Typography>
          </Grid>
          <CommonTableForm
            specialFilter
            loading={loading}
            tableData={tableData}
            setTableData={values =>
              setStatus({
                ...status,
                table: {
                  values,
                  changed: !isEqual(values, tableData),
                },
              })
            }
            allowUpdate={allowUpdate}
            label="EMR"
            initialTableValues={{ saveId: '0', year: '', amount: '' }}
            fieldName="amount"
            maskType="floatNumber"
            fieldComponent={FormNumberInput}
            validationSchema={emrTableSchema}
            getYearsOptions={getCurrentYearsOptions}
          />
        </Grid>
        {allowUpdate && (
          <LoadingButton
            fixed
            disabled={!canSubmit}
            success={success}
            error={error}
            loading={loading}
            onClick={handleSubmitClick}
            text="save"
          />
        )}
      </FormBody>
    </>
  );
};
export default EMRateForm;
