import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  titleYears: {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
  },
  tableBody: {
    '& tr td:nth-child(n + 3)': {
      borderLeft: `1px solid ${theme.palette.grey['300']}`,
      width: '12%',
    },
  },
}));

export default useStyles;
