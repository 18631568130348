const state = {
  loading: false,
  stack: [],
  success: false,
  error: false,
};

const initialState = {
  legal: state,
  citations: state,
  envprog: state,
  hearconserv: state,
  respprot: state,
  hazardcomm: state,
  osha: state,
  spillwaste: state,
  substabuse: state,
  empeng: state,
  medprog: state,
  envmeet: state,
  protecteq: state,
  corrproc: state,
  equipmat: state,
  subprog: state,
  inspprog: state,
  envtrain: state,
  workfordev: state,
  envdoc: state,
};

export default initialState;
