import { call, put, takeLatest } from 'redux-saga/effects';

import dataProvider from '../../store/dataProvider';
import { workingHours } from '../../actionTypes';

export function* get({ payload: { contractorId, ownerId } }) {
  try {
    const { data } = yield call(dataProvider.getOnly, `companies/${contractorId}/working-hours/${ownerId}`);

    yield put({
      type: workingHours.list.end,
      payload: data,
    });
  } catch (e) {
    yield put({ type: workingHours.list.error });
  }
}

export default function* getWorkingHours() {
  yield takeLatest(workingHours.list.start, get);
}
