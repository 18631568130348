import React, { useState } from 'react';
import { Paper, Grid, Typography, Link as MaterialLink, Tooltip, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import { useDispatch } from 'react-redux';

import { currentCompany } from '../../../../actionTypes';
import CompanyLogo from '../../../components/CompanyLogo';
import Ellipsis from '../../../components/Ellipsis';
import OwnerGrading from '../OwnerGrading';
import GradeSystem from '../GradeSystem';
import FavoriteButton from '../FavoriteButton';
import phoneFormatting from '../../../../utils/textFormaters/phone';
import ImageUpload from './ImageUpload';
import useStyles from './styles';
import { addDollarCommaSigns } from '../../../../utils/containersHelpers';
import { userTypes, contractorLevels } from '../../../../utils/constants';
import { useUserPermissions } from '../../../../utils/effects/userPermissions';
import SingleOwnerGrade from '../SingleOwnerGrade';
import WorkingHoursModal from '../../WorkingHoursModal';

const CompanyCard = ({
  company,
  ownerGradingSystem,
  generalInfo,
  grade,
  handleAddToFavorite,
  loadingListAddToFavorite,
  updatingImage,
  userCompanyId,
}) => {
  const [openWorkingHoursModal, setOpenWorkingHoursModal] = useState(false);
  const dispatch = useDispatch();
  const { type } = useUserPermissions();
  const { mark, list, loading, handleDownloadGrade } = grade;

  const {
    streetAddress,
    logo,
    companyName,
    companyDescription,
    companyLevel,
    webSite,
    phoneNumber,
    emailAddress,
    latestEmrYear,
    latestEmrAmount,
    previousFinancialYearBls,
    previousFinancialYearTrir,
    workersCompensationAmount,
    isFavourite,
    withOverdue,
    id: companyId,
  } = company;
  const { street, city, state, zip } = streetAddress;
  const { isDraft } = generalInfo;

  const { ownerGrading, listOwnerGrading, handleDownloadOwnerGrade, getOwnerGrading } = ownerGradingSystem;

  const indicateRule = previousFinancialYearTrir !== null && previousFinancialYearBls !== null;

  const classes = useStyles({ indicate: indicateRule ? previousFinancialYearTrir < previousFinancialYearBls : null });

  const isSafetyCouncil = type === userTypes.SafetyCouncil;

  const onCompanyImageChange = image =>
    dispatch({
      type: currentCompany.info.updateImage.start,
      payload: {
        image,
        id: companyId,
      },
    });

  const onCompanyImageDelete = () =>
    dispatch({
      type: currentCompany.info.deleteImage.start,
      payload: {
        id: companyId,
      },
    });

  const handleOpenWorkingHoursModal = () => setOpenWorkingHoursModal(prev => !prev);

  return (
    <Paper className={classes.paperContainer}>
      <Grid container justify="space-between">
        <Grid item xs={type === userTypes.Owner ? 4 : 6}>
          <Grid container>
            <Grid item xs={type === userTypes.Owner ? 2 : 1} className={classes.logoWrapper}>
              <CompanyLogo src={logo} href={webSite} name={companyName} />
            </Grid>
            <Grid item xs={type === userTypes.Owner ? 9 : 10}>
              <Grid container justify="flex-start" alignItems="center" spacing={1} className={classes.name}>
                <Grid item>
                  <Ellipsis title={companyName} typographyVariant="h3" />
                </Grid>
                {type === userTypes.Owner && (
                  <Grid item>
                    <FavoriteButton
                      id={companyId}
                      isFavourite={isFavourite}
                      loadingListAddToFavorite={loadingListAddToFavorite}
                      handleAddToFavorite={handleAddToFavorite}
                    />
                  </Grid>
                )}
                {withOverdue && (
                  <Grid item className={classes.containerIcon}>
                    <Tooltip title="Expiration date for some documents is over">
                      <ErrorSharpIcon className={classes.iconError} />
                    </Tooltip>
                  </Grid>
                )}
                {isDraft && (
                  <Grid item className={classes.containerIcon}>
                    <Tooltip title="Company information that is displayed on this page is preliminary. It has not been reviewed or approved by Safety Council yet.">
                      <WarningIcon className={classes.icon} />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              {companyDescription && (
                <Ellipsis
                  title={companyDescription}
                  typographyVariant="subtitle2"
                  gutterBottom
                  lines={3}
                  classes={classes.description}
                />
              )}
              {webSite && (
                <Typography variant="subtitle2" gutterBottom>
                  <MaterialLink target="_blank" href={`//${webSite}`}>
                    {webSite}
                  </MaterialLink>
                </Typography>
              )}
              {(street || city || state || zip) && (
                <Typography variant="subtitle2" gutterBottom>
                  {street && `${street}, `}
                  {city && `${city}, `}
                  {state && `${state}, `}
                  {zip && `${zip}`}
                </Typography>
              )}
              {phoneNumber && (
                <Typography variant="subtitle2" gutterBottom>
                  <MaterialLink href={`tel:${phoneNumber}`}>{phoneFormatting(phoneNumber)}</MaterialLink>
                </Typography>
              )}
              {emailAddress && (
                <Typography variant="subtitle2" gutterBottom>
                  {emailAddress}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={2}>
          <Grid className={classes.imageUpload}>
            {(isSafetyCouncil || company.image) && (
              <ImageUpload
                allowUpdate={isSafetyCouncil}
                imageLink={company.image}
                loading={updatingImage}
                onChange={onCompanyImageChange}
                onDelete={onCompanyImageDelete}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={type === userTypes.Owner ? 6 : 3}>
          <Grid container justify="flex-end">
            <Grid item xs={type === userTypes.Owner ? 4 : 8}>
              <Grid container spacing={1}>
                {latestEmrYear && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <strong> Latest EMR Year:</strong> {latestEmrYear}
                    </Typography>
                  </Grid>
                )}
                {latestEmrAmount !== null && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <strong> Latest EMR Amount:</strong> {latestEmrAmount}
                    </Typography>
                  </Grid>
                )}
                {!!workersCompensationAmount && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <strong> Workers Compensation Amount:</strong> {addDollarCommaSigns(workersCompensationAmount)}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    <strong> TRIR and BLS: </strong>
                    <span className={classes.indicateColor}>
                      {previousFinancialYearTrir ?? 'TBD'} / {previousFinancialYearBls ?? 'TBD'}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justify="flex-end" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    {contractorLevels[companyLevel]}
                  </Typography>
                </Grid>
                <Grid item xs={type === userTypes.Owner ? 6 : 12}>
                  <GradeSystem grade={mark} list={list} loading={loading} handleDownloadGrade={handleDownloadGrade} />
                </Grid>
                <Grid item xs={type === userTypes.Owner ? 6 : 12}>
                  {/* ownerGrade */}
                  {type === userTypes.Owner && (
                    <SingleOwnerGrade handleDownloadOwnerGrade={handleDownloadOwnerGrade} ownerGrading={ownerGrading} />
                  )}

                  {type === userTypes.SafetyCouncil && (
                    <OwnerGrading
                      getOwnerGrading={getOwnerGrading}
                      listOwners={listOwnerGrading}
                      ownerGrading={ownerGrading}
                      handleDownloadOwnerGrade={handleDownloadOwnerGrade}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {(company.canExportOwnWorkingHours || company.canExportWorkingHours) && (
            <div className={classes.workingHoursExport}>
              <Button size="medium" variant="contained" color="primary" onClick={handleOpenWorkingHoursModal}>
                View working hours
              </Button>
              <WorkingHoursModal
                companyId={companyId}
                isOpen={openWorkingHoursModal}
                onClose={handleOpenWorkingHoursModal}
                withSearchByOwner={type !== userTypes.Owner}
                ownerId={userCompanyId}
                userType={type}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyCard;
