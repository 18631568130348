import React from 'react';
import { Grid } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';

import Ellipsis from '../../../components/Ellipsis';
import { getStateNameByAbbreviation } from '../../../../utils/constants/usaStates';
import IconWithPopUp from '../../../components/IconWithPopUp';

export default ({ handleRestore }) => [
  {
    title: 'Company Name',
    render: ({ companyName }) => <Ellipsis title={companyName} />,
    sortField: 'companyName',
    defaultSort: 'asc',
    width: '35%',
  },
  {
    title: 'Company Type',
    render: ({ typeName }) => <Ellipsis title={typeName} />,
    sortField: 'typeName',
    defaultSort: 'asc',
    width: '12%',
  },
  {
    title: 'City',
    render: ({ streetAddress: { city } }) => <Ellipsis title={city || ''} />,
    sortField: 'city',
    width: '10%',
  },
  {
    title: 'State',
    render: ({ streetAddress: { state } }) => <Ellipsis title={getStateNameByAbbreviation(state)} />,
    sortField: 'state',
    width: '10%',
  },
  {
    title: 'Website',
    render: ({ webSite }) => <Ellipsis title={webSite} showLink={true} href={webSite} target="_blank" />,
    sorting: false,
    width: '25%',
  },
  {
    title: 'Actions',
    sorting: false,
    width: '8%',
    render: ({ id }) => (
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center">
          <IconWithPopUp
            title="Are you sure you want to restore this company?"
            handler={() => handleRestore(id)}
            tooltipTitle="Restore"
          >
            <RestoreIcon size="small" color="primary" />
          </IconWithPopUp>
        </Grid>
      </Grid>
    ),
  },
];
