import React from 'react';
import { FastField, Formik } from 'formik';
import { Card } from '@material-ui/core';

import FileUploaderInput from '../../components/FileUploaderInput';
import { createFileValue } from '../../../utils/pageHelpers';
import useStyles from './styles';

const UploadPsmAuditForm = ({ handlePsmSubmit, psmAuditList, companyId, hasPermissions }) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardWrapper}>
      <Formik initialValues={{ psmAudit: createFileValue(psmAuditList) }} onSubmit={handlePsmSubmit} enableReinitialize>
        {({ submitForm }) => {
          return (
            <FastField
              name="psmAudit"
              component={FileUploaderInput}
              label="Upload PSM"
              companyId={companyId}
              hasPermissions={hasPermissions}
              listType="documentList"
              handlePsmSubmit={submitForm}
              cardTitle="PSM Audit Documents"
              completedBy
              completedOn
            />
          );
        }}
      </Formik>
    </Card>
  );
};

export default UploadPsmAuditForm;
