import { currentCompany } from '../../actionTypes';

const initialState = {
  requestInProgress: false,
  loading: false,
  financialInfo: {},
  financialArrays: [],
  success: false,
  error: false,
};

export default function finInfo(state = initialState, action) {
  switch (action.type) {
    case currentCompany.finInfo.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.finInfo.get.end:
      return {
        ...state,
        loading: false,
        financialInfo: action.payload.financialInfo,
        financialArrays: action.payload.financialArrays,
      };

    case currentCompany.finInfo.get.error:
      return {
        ...state,
        loading: false,
        financialInfo: {},
        financialArrays: [],
      };

    case currentCompany.finInfo.update.start:
      return { ...state, requestInProgress: true, loading: true };

    case currentCompany.finInfo.update.end:
      return {
        ...state,
        loading: false,
        requestInProgress: false,
        financialInfo: action.payload.financialInfo,
        financialArrays: action.payload.financialArrays,
        success: true,
        error: false,
      };

    case currentCompany.finInfo.update.error:
      return {
        ...state,
        loading: false,
        requestInProgress: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
