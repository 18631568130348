import { call, put, takeLatest } from 'redux-saga/effects';

import { settings, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { value } }) {
  try {
    yield call(dataProvider.updateOnly, 'configuration', value);
    localStorage.setItem('promptConfirm', JSON.stringify(value.promptConfirm));

    yield put({
      type: settings.configuration.update.end,
      payload: value,
    });
  } catch (e) {
    yield put({ type: settings.configuration.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateSettings() {
  yield takeLatest(settings.configuration.update.start, update);
}
