import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import { enumTypes } from '../../../utils/constants/formBuilder';
import FormBody from '../../components/FormBody';
import PageLoader from '../../components/PageLoader';
import PromptNotification from '../../components/PromptComponent';
import FormButtons from './FormButtons';
import AddRow from './AddRow';
import SortableWrap from './SortableWrap';
import { createStructure, normalizeValues, normalizeValuesWithId } from './formaters';
import { validationSchema } from './validation';
import ContractorAddendumForm from '../ContractorAddendumForm';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import useStyles from './styles';

const handleAddQuestion = (setFieldValue, values) => {
  const generateKey = uuidv4();
  setFieldValue('fields', {
    ...values,
    [generateKey]: {
      id: generateKey,
      generated: true,
      parentId: null,
      showOnCondition: false,
      order: Object.keys(values).filter(key => values[key].parentId === null).length,
      text: '',
      type: enumTypes.Paragraph,
      isRequired: false,
      isActive: true,
      hasChild: false,
      children: {},
      options: [],
      parentAnswerCondition: null,
    },
  });
};

const FormBuilder = ({ loading, saving, success, error, list, handleSave }) => {
  const [previewModal, setPreviewModal] = useState({ status: false, values: {} });
  const classes = useStyles();
  const { id } = useParams();
  const { companyId } = useCompanyInfo(id);

  const onSubmit = val => handleSave(normalizeValues(val.fields, null));
  const convertValues = createStructure(list);

  if (loading) return <PageLoader />;

  return (
    <>
      <Formik
        initialValues={{ fields: convertValues }}
        enableReinitialize
        onSubmit={val => onSubmit(val)}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ values, dirty, isValid, setFieldValue, handleSubmit }) => (
          <FormBody loading={loading} disabled={saving}>
            <Grid item xs={12}>
              <PromptNotification formStatus={dirty} />
              <SortableWrap
                parent={null}
                childrenKeys={Object.keys(values.fields).filter(key => values.fields[key].parentId === null)}
                companyId={companyId}
              />
              <AddRow handleAdd={() => handleAddQuestion(setFieldValue, values.fields)} />
            </Grid>
            <FormButtons
              fixed
              hasValues={Object.keys(values.fields).length > 0}
              handlePreview={() => setPreviewModal({ status: true, values: values.fields })}
              isValid={isValid}
              disabled={!dirty || !isValid}
              success={success}
              error={error}
              loading={saving}
              onClick={handleSubmit}
            />
          </FormBody>
        )}
      </Formik>
      <Dialog
        open={previewModal.status}
        onClose={() => setPreviewModal(prevState => ({ ...prevState, status: false }))}
        onExited={() => setPreviewModal({ status: false, values: {} })}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle>Preview addendum</DialogTitle>
        <DialogContent>
          <ContractorAddendumForm
            preview
            questions={normalizeValuesWithId(previewModal.values, null)}
            loading={false}
            answers={[]}
            companyId={companyId}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.actions }}>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Button
                onClick={() => setPreviewModal(prevState => ({ ...prevState, status: false }))}
                variant="contained"
                size="small"
                color="primary"
                autoFocus
              >
                close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormBuilder;
