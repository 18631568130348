import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { pages, notification, entity } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { document } from '../../../schema';

export function* submit({ payload: { companyId, pqfDocumentId } }) {
  try {
    const { data } = yield call(
      dataProvider.createOne,
      `companies/${companyId}/pqf/${pqfDocumentId}/review/submit`,
      {},
    );
    // update status for PQF
    const conditions = yield call(dataProvider.getOnly, `companies/${companyId}/conditions`);

    // normalize documents
    const normalizeDoc = normalize([data], [document]);
    yield put({ type: entity.add, payload: normalizeDoc.entities });

    yield put({
      type: pages.dashboard.updateCondition.update,
      payload: { conditions: conditions.data },
    });

    yield put({
      type: pages.dashboard.submitDocument.end,
    });
  } catch (e) {
    yield put({
      type: pages.dashboard.submitDocument.error,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* submitDocument() {
  yield takeLatest(pages.dashboard.submitDocument.start, submit);
}
