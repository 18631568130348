import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { userTypes } from '../constants';

export function useUserPermissions(id) {
  const userId = useSelector(state => state.auth.check.userId);
  const { companyType, companyId } = useSelector(state => state.entity.user[userId]);

  return {
    type: companyType,
    userCompanyId: companyId,
    allowUpdate: companyType === userTypes.SafetyCouncil || companyId === id,
  };
}

export function useCheckUserCompany() {
  const { id } = useParams();
  const permissions = useUserPermissions();
  return permissions.update('company', { id });
}
