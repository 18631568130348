// Fork from https://github.com/marmelab/react-admin/blob/master/packages/ra-data-json-server/src/index.ts

import { stringify } from 'query-string';
import get from 'lodash.get';

import { fetchJson, flattenObject } from './fetchUtils';

/**
 *
 * queryParams = {
 *   filter: '',
 *   pagination: {
 *     page: number,
 *     perPage: number,
 *   },
 *   sort: {
 *     field: string,
 *     order: 'asc' || 'desc'
 *   },
 *   ids: array of id
 * };
 *
 * getOne              => GET ${apiUrl}${resource}/:id
 * getOnly             => GET ${apiUrl}${resource}
 * getList             => GET ${apiUrl}${resource}?${queryParams}
 *
 * updateOne           => PUT  ${apiUrl}${resource}/:id
 * updateOnly          => PUT  ${apiUrl}${resource}
 * updateList          => PUT  ${apiUrl}${resource}
 *
 * createOne           => POST ${apiUrl}${resource}
 * createList          => POST ${apiUrl}${resource}
 *
 * deleteOne           => DELETE ${apiUrl}${resource}/:id
 * deleteList          => DELETE ${apiUrl}${resource}
 *
 */

function generateQuery(params) {
  let query = get(params, 'filter', false) ? flattenObject(params.filter) : {};
  if (get(params, 'pagination', false)) {
    const { page, perPage } = params.pagination;
    query = {
      ...query,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
  }
  if (get(params, 'sort', false)) {
    const { field, order } = params.sort;
    query = {
      ...query,
      _sort: field,
      _order: order,
    };
  }
  if (get(params, 'ids', false)) {
    query = {
      ...query,
      ids: params.ids,
    };
  }

  if (Object.keys(query).length) {
    return `?${stringify(query, { arrayFormat: 'comma', encode: false })}`;
  }
  return '';
}

export default (apiUrl, httpClient = fetchJson) => ({
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getList: (resource, params) => {
    const url = `${apiUrl}/${resource}${generateQuery(params)}`;

    return httpClient(url).then(({ headers, json }) => {
      const total = headers.has('x-total-count')
        ? parseInt(
            headers
              .get('x-total-count')
              .split('/')
              .pop(),
            10,
          )
        : null;
      return {
        data: json,
        total,
      };
    });
  },

  getOnly: (resource, params) =>
    httpClient(`${apiUrl}/${resource}${generateQuery(params)}`).then(({ json }) => ({
      data: json,
    })),

  updateOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  updateOneFormData: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: params,
    }).then(({ json }) => ({ data: json })),

  updateList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  updateOnly: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params),
    }).then(({ json }) => ({ data: json })),

  createOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json,
    })),

  createOneFormData: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: params,
    }).then(({ json }) => ({
      data: json,
    })),

  createList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params),
    }).then(({ json }) => ({
      data: json,
    })),

  deleteOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      body: JSON.stringify(params), //  { ids: [ 'id1' , 'id2', 'id3' ] }
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),
});
