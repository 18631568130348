import React from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4 } from 'uuid';

import { fileUploader } from '../../../utils/providerHelpers/fileUploader';
import { notification } from '../../../actionTypes';
import useStyles from './styles';

const bytesInMegabyte = 1024 * 1024;

const DropZone = ({ url, maxFileSize, dropped, accept, onDrop, onUploadUpdate, onUploaded, onUploadStart, single }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ dropped });
  const onError = error => dispatch({ type: notification.add, payload: { error } });
  const maxFileSizeUpload = maxFileSize * bytesInMegabyte;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: !single,
    onDropAccepted: acceptedFiles => {
      const filesClaim = acceptedFiles.reduce((acc, file) => {
        if (file.size > maxFileSizeUpload) {
          onError(`File ${file.name} is too large`);
          return acc;
        }

        return [
          ...acc,
          {
            file,
            id: uuidv4(),
          },
        ];
      }, []);
      onUploadStart();
      onDrop(filesClaim.map(({ id, file }) => ({ id, name: file.name })));
      fileUploader(filesClaim, url, onUploadUpdate, onError).then(result => onUploaded(result));
    },
  });

  return (
    <div {...getRootProps({ className: classes.dropBody })}>
      <input {...getInputProps()} />
      <Typography variant="h5" align="center">
        {single ? 'Drag \'n\' drop a file here, or click to select a file' : 'Drag \'n\' drop some files here, or click to select files'}
      </Typography>
    </div>
  );
};

export default DropZone;
