import React from 'react';
import { FastField } from 'formik';
import { Typography, Grid } from '@material-ui/core';

import useStyles from './styles';

const MinMax = ({ title, handleChange, names, minValues, maxValues, number, component, type, masktype }) => {
  const classes = useStyles();

  const { minName, maxName } = names;
  return (
    <Grid container justify="flex-end" alignItems="flex-start">
      <Typography variant="h5" gutterBottom className={classes.typography}>
        {title}
      </Typography>
      <Grid item sm={2}>
        <FastField
          className={classes.inputRow}
          label="Min"
          placeholder="Min"
          name={minName}
          min={minValues && minValues.min}
          max={minValues && minValues.max}
          number={number}
          handleChange={handleChange}
          component={component}
          type={type}
          maskType={masktype}
        />
      </Grid>
      <Typography className={classes.dash}>-</Typography>
      <Grid item sm={2}>
        <FastField
          className={classes.inputRow}
          label="Max"
          placeholder="Max"
          name={maxName}
          min={maxValues && maxValues.min}
          max={maxValues && maxValues.max}
          number={number}
          handleChange={handleChange}
          component={component}
          type={type}
          maskType={masktype}
        />
      </Grid>
    </Grid>
  );
};

export default MinMax;
