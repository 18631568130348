import refineNumberString from './refineNumberString';

export const setAmountOnSubmit = value => {
  let amount = 0;
  if (value) {
    if (typeof value === 'string') {
      amount = refineNumberString(value);
    } else {
      amount = value;
    }
  }
  return amount;
};
