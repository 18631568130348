import { useSelector } from 'react-redux';
import get from 'lodash.get';

import { userTypes, contractorLevelEnum } from '../constants';

export function useCompanyInfo(id) {
  const currentUser = useSelector(state => state.auth.check.userId);
  const currentCompanyId = useSelector(state => state.entity.user[currentUser].companyId);
  const companyId = id || currentCompanyId;
  const companyName = useSelector(state => get(state, `entity.company[${companyId}].name`, ''));
  const companyType = useSelector(state => get(state, `entity.company[${companyId}].typeName`, ''));
  const level = useSelector(state => get(state, `entity.company[${companyId}].level`, ''));
  const isBasicContractor = companyType === userTypes.Contractor && level === contractorLevelEnum.BasicContractor;

  return { companyId, companyName, companyType, level, isBasicContractor };
}
