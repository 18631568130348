import React from 'react';
import { Typography } from '@material-ui/core';

import TooltipWrapper from '../../../../../components/TooltipWrapper';
import formatDate from '../../../../../../utils/textFormaters/date';

const ExpirationDate = ({ expirationDate }) => {
  const currentDate = new Date();
  const isExpired = currentDate.toISOString() > expirationDate;

  return (
    <TooltipWrapper tooltip={isExpired} title="The expiration date is expired">
      <Typography display="inline" variant="body1" color={isExpired ? 'error' : 'initial'}>
        {formatDate(expirationDate)}
      </Typography>
    </TooltipWrapper>
  );
};

export default ExpirationDate;
