import { createEnum } from '../actionTypesHelpers';

const options = {
  'Official and managers': null,
  Professionals: null,
  Technicians: null,
  Sales: null,
  'Office and clerical': null,
  'Craft Workers (skilled)': null,
  'Operatives (semiskilled)': null,
  'Laborers (unskilled)': null,
  'Service workers': null,
};

const typesEnum = createEnum(options);
export default Object.keys(typesEnum).map(key => ({
  label: key,
  value: typesEnum[key],
}));
