import React from 'react';
import Masked from 'react-text-mask';

const MaskedInput = ({ inputRef, ...other }) => {
  return (
    <Masked
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
};

export default MaskedInput;
