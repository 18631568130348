import React, { useState, useRef, useEffect, useMemo } from 'react';
import { v3 as uuidv3 } from 'uuid';
import { useLocation } from 'react-router-dom';

import MenuItem from './MenuItem';
import BackButton from './BackButton';
import useStyles from './styles';

// create object with uniq id for each menu item
const addIdToItems = list =>
  list.reduce((acc, item) => {
    const id = uuidv3(item.title, uuidv3.DNS);
    if (item.children) {
      return {
        ...acc,
        [id]: {
          id,
          ...item,
          children: addIdToItems(item.children),
        },
      };
    }
    return {
      ...acc,
      [id]: {
        id,
        ...item,
      },
    };
  }, {});

// calculate active parent iff it exist
const getActive = (path, obj) =>
  Object.keys(obj).reduce((acc, item) => {
    const parent =
      obj[item].children &&
      Object.keys(obj[item].children).filter(childId => obj[item].children[childId].to === path).length &&
      item;
    return (parent && obj[parent]) || acc;
  }, null);

const Menu = ({ menu, validKeys, loadingKeys, showValidation }) => {
  const { pathname } = useLocation();

  const menuWithId = useMemo(() => addIdToItems(menu), [menu]);
  const current = getActive(pathname, menuWithId);

  const [active, setActive] = useState(current);
  const [height, setHeight] = useState(0);

  const wrapperRef = useRef(null);
  const classes = useStyles({ active: !!active, height });

  useEffect(() => {
    if (active && active.id) {
      setHeight(document.querySelector(`[data-menu-id="${active.id}"]`).scrollHeight);
    } else {
      setHeight(wrapperRef.current.scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const handleClick = item => {
    const isItemActive = () => active && active.id === item.id;
    const itemHasChildren = () => !!item.children;
    const isTopLevelItem = () => !!item.icon;

    if (isItemActive()) {
      setActive(null);
      return;
    }

    if (isTopLevelItem()) {
      setActive(itemHasChildren() ? item : null);
    }
  };

  return (
    <>
      <BackButton back={!!active} handleClick={() => setActive(null)} title={active ? active.title : 'Menu'} />
      <div className={classes.menu}>
        <ul ref={wrapperRef}>
          {Object.keys(menuWithId).map(id => (
            <li key={id} className={active && active.id === id ? classes.active : undefined}>
              <MenuItem
                item={menuWithId[id]}
                validKeys={validKeys}
                loadingKeys={loadingKeys}
                showValidation={showValidation}
                active={active}
                handleClick={() => handleClick(menuWithId[id])}
                current={pathname === menuWithId[id].to}
              />

              {menuWithId[id].children && (
                <ul className={classes.submenuBlock} data-menu-id={id}>
                  {Object.keys(menuWithId[id].children).map(childId => (
                    <li key={childId}>
                      <MenuItem
                        item={menuWithId[id].children[childId]}
                        validKeys={validKeys}
                        loadingKeys={loadingKeys}
                        showValidation={showValidation}
                        active={active}
                        handleClick={() => handleClick(menuWithId[id].children[childId])}
                        current={pathname === menuWithId[id].children[childId].to}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default React.memo(Menu);
