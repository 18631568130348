import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: ({ size }) => ({
    width: size || theme.spacing(3),
    height: size || theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
      maxWidth: '80%',
      maxHeight: '80%',
      pointerEvents: 'none',
    },
  }),
}));

export default useStyles;
