import React from 'react';
import Masked from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const getMask = (type = 'anyNumber') => {
  const numberMask = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: '',
  });

  const dollarMask = createNumberMask({
    prefix: '$',
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    integerLimit: 13,
  });

  const dollar10Number = createNumberMask({
    prefix: '$',
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    integerLimit: 10,
  });

  const floatMask = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: '',
    allowDecimal: true,
    decimalLimit: 4,
  });

  const commaDivideMask = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
  });

  const maskTypes = {
    anyNumber: numberMask,
    floatNumber: floatMask,
    dollarNumber: dollarMask,
    commaDivide: commaDivideMask,
    dollarMaskTenNumbers: dollar10Number,
    fromZero: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    fromOne: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    zip: [/\d/, /\d/, /\d/, /\d/, /\d/],
  };

  if (!maskTypes[type]) {
    throw new Error('Unknown Number Input type');
  }

  return maskTypes[type];
};

const MaskedInput = ({ maskType, inputRef, ...other }) => {
  return (
    <Masked
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={getMask(maskType)}
      guide={false}
      showMask={false}
    />
  );
};

export default MaskedInput;
