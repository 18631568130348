import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: true,
  companyData: {},
  subsidiaries: [],
  success: false,
  error: false,
};

export default function parentCompany(state = initialState, action) {
  switch (action.type) {
    case currentCompany.parentCompany.get.start:
      return initialState;

    case currentCompany.parentCompany.get.end:
      return {
        ...state,
        loading: false,
        companyData: action.payload.companyData,
        subsidiaries: action.payload.subsidiaries,
      };
    case currentCompany.parentCompany.get.error:
      return {
        ...state,
        loading: false,
        companyData: {},
        subsidiaries: [],
      };

    case currentCompany.parentCompany.create.start:
      return { ...state, loading: true };

    case currentCompany.parentCompany.create.end:
      return {
        ...state,
        loading: false,
        companyData: action.payload.companyData,
        subsidiaries: action.payload.subsidiaries,
        success: true,
        error: false,
      };
    case currentCompany.parentCompany.create.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case currentCompany.parentCompany.update.start:
      return { ...state, loading: true };

    case currentCompany.parentCompany.update.end:
      return {
        ...state,
        loading: false,
        companyData: action.payload.companyData,
        subsidiaries: action.payload.subsidiaries,
        success: true,
        error: false,
      };

    case currentCompany.parentCompany.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
