import React from 'react';
import { Grid } from '@material-ui/core';

import { yesnoOptions } from '../../../../utils/constants/formConstats';
import QuestionWithOptions from '../../../components/QuestionWithOptions';
import OfficersSubForm from '../OfficersSubForm';

const OfficersSubFormWrapper = ({ isOfficerExists, formNames, position, questionName, ...props }) => {
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <QuestionWithOptions title={`Do you have a company ${position}?`} name={questionName} options={yesnoOptions} />
      </Grid>
      {isOfficerExists === 'Yes' && <OfficersSubForm position={position} names={formNames} {...props} />}
    </Grid>
  );
};

export default OfficersSubFormWrapper;
