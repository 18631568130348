import qestions from '../../store/questions.json';
const hardcodeKeys = {
  YearCreated: 'Year Established Under Present Firm Name',
  StateContractorNumber: 'State contractor number',
  Description: 'Description',
  MailingAddressCity: 'Mailing address: City',
  MailingAddressState: 'Mailing address: State',
  MailingAddressZip: 'Mailing address: Zip',
  MailingAddressStreet: 'Mailing address: Street',
  StreetAddressCity: 'Street address: City',
  StreetAddressState: 'Street address: State',
  StreetAddressZip: 'Street address: Zip',
  StreetAddressStreet: 'Street address: Street',
  CeoEmailAddress: 'CEO/Owner: Email address',
  BidManagerContactInfoContactPersonFirstName: 'Bid manager: First name',
  BidManagerContactInfoContactPersonLastName: 'Bid manager: Last name',
  BidManagerContactInfoContactPersonTitle: 'Bid manager: Title',
  BidManagerContactInfoEmailAddress: 'Bid manager: Email address',
  BidManagerContactInfoPhoneNumber: 'Bid manager: Phone number',
  BidManagerContactInfoFaxNumber: 'Bid manager: Fax number',
  InsurancePolicyContactInfoContactPersonFirstName: 'Company Contact for Insurance Information: First name',
  InsurancePolicyContactInfoContactPersonLastName: 'Company Contact for Insurance Information: Last name',
  InsurancePolicyContactInfoContactPersonTitle: 'Company Contact for Insurance Information: Title',
  InsurancePolicyContactInfoPhoneNumber: 'Company Contact for Insurance Information: Phone number',
  InsurancePolicyContactInfoFaxNumber: 'Company Contact for Insurance Information: Fax number',
  insurpolQ13: 'Workers Compensation Carrier',
  GeneralLiabilityName: 'General Liability Carrier: Name',
  GeneralLiabilityExpirationDate: 'General Liability Carrier: Expiration date',
  GeneralLiabilityAmount: 'General Liability Carrier: Coverage amount',
  IsParentCompanyExist: 'Do you have a Parent Company?',
  IsSubsidiaryExist: 'Do you have a Subsidiary Company?',
  OrganizationInformationFormOfBusiness: 'Form of business',
  OrganizationInformationIncorporationDate: 'Incorporation date',
  OrganizationInformationIncorporationState: 'Incorporation state',
  OrganizationInformationPercentMinorityOwned: 'Percent Minority/Female Owned',
  OrganizationInformationEeoCategory: 'EEO Category',
  CompanyServices: 'Company services',
  CompanyWorkCategories: 'Work categories',
  DunsNumber: 'Dun & Bradstreet Scores and Ratings: DUNS Number',
  BondingCapacity: 'Bank Information: Bonding capacity',
  FinRating: 'Dun & Bradstreet Scores and Ratings: D&B Financial Rating',
  BankReference: 'Bank Information: Reference',
  CompanyLargestJobAmount: 'Largest Job during the last 3 years: Amount',
  CompanyLargestJobCustomerName: 'Largest Job during the last 3 years: Customer name',
  CompanyLargestJobDescription: 'Largest Job during the last 3 years: Description',
  Certifications: 'Name of the highest ranking safety & health professional in your company: Certifications',
  ReportsToName: 'Name of the highest ranking safety & health professional in your company: Reports to name',
  ReportsToTitle: 'Name of the highest ranking safety & health professional in your company: Reports to title',
  ContactPersonFirstName: 'Name of the highest ranking safety & health professional in your company: First name',
  ContactPersonLastName: 'Name of the highest ranking safety & health professional in your company: Last name',
  ContactPersonTitle: 'Name of the highest ranking safety & health professional in your company: Title',
  EmailAddress: 'Email address',
  PhoneNumber: 'Phone number',
  EmrInfoSic: 'SIC',
  EmrInfoNaics: 'NAICS',
  EmrInfoState: 'State of origin',
  EmrInfoAnniversaryDate: 'EMR Anniversary Date',
  CompanyCraftsmenCertificationQuestion:
    'Do you comply with the SLCUC Participation Policy for contractor worker certification?',
  CeoName: 'CEO/Owner name',
  CeoDateHired: 'CEO/Owner Date Hired',
  PresidentIsPositionExist: 'Existing president position',
  VicePresidentIsPositionExist: 'Existing vice president position',
  TreasurerIsPositionExist: 'Existing treasurer position',
  WorkersCompensationIsSelfInsured: 'Are you self insured for Workers Compensation Insurance?',
  HasVehicleInsurance: 'Do you have company Automobile Insurance?',
  BankLineOfCredit: 'Bank line of credit',
  MinProjectSize: 'Your Firm Desired Project Size: Minimum',
  MaxProjectSize: 'Your Firm Desired Project Size: Maximum',
  NetWorth: 'Dun & Bradstreet Scores and Ratings: Net Worth',
  CompanyCraftsmenCertificationQuestionCompliesWithSLCUC:
    'Do you comply with the SLCUC Participation Policy for contractor worker certification?',
  CompanyCraftsmenCertificationQuestionCompliesWithGoldenTriangle:
    'Do you comply with the Golden Triangle Business Roundtable Crat Certification and Assessment Policy?',
  CompanyCraftsmenCertificationQuestionHasTrainingPrograms:
    'Do you have training/certification programs for certifying "A" Craftsmen?',
  CompanyCraftsmenCertificationQuestionHasPracticalTestingPrograms:
    'Do you have practical (hands-on) testing programs for certifying "A" Craftsmen?',
  CompanyCraftsmenCertificationQuestionIsNccerAssessmentCenter: 'Is the Company an accredited NCCER Assessment Center?',
  contlicense: "Upload State Contractor's License",
  emrdoc: 'Upload EMR Documentation (Insurance Carrier) (Last 3 Years)',
  oshadoc: 'Upload OSHA 300/300A Logs (Last 3 Years)',
  certificate: 'Upload General Liability Insurance Certificate',
  OrganizationInformationAverageNumberOfEmployees: 'Average number of employees',
  HealthProfContactPersonFirstName: 'Contact person first name',
  HealthProfContactPersonLastName: 'Contact person last name',
  HealthProfContactPersonTitle: 'Contact person title',
  HealthProfEmailAddress: 'Email address',
  HealthProfPhoneNumber: 'Phone number',
  Emr1Year: 'EMR per year',
  workerins: 'Upload Workers Compensation Insurance',
  finaudit: 'Upload Audited Financial Statements (Last 2 Fiscal Years)',
  autoinsur: 'Upload Automobile Insurance',
};

const yesNoKeys = qestions.reduce((acc, item) => ({ ...acc, [item.key]: item.text }), {});

export const getQuestionTitle = key => {
  if (hardcodeKeys[key]) return hardcodeKeys[key];
  if (yesNoKeys[key]) return yesNoKeys[key];
  console.warn(`Such key doesnt exist ${key}`);
  return key;
};
