import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  gutterBottom: {
    padding: theme.spacing(0, 0, 2),
  },
  button: {
    padding: theme.spacing(7 / 8),
    minWidth: theme.spacing(5),
    color: theme.palette.background.primary.main,
    borderColor: theme.palette.background.primary.main,
    '&:hover': {
      borderColor: theme.palette.background.primary.main,
    },
  },
}));

export default useStyles;
