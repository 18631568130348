import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formRadioContainer: {
    margin: theme.spacing(0, 1),
    '& > h6': {
      marginRight: theme.spacing(4),
    },
  },
  itemContainer: {
    padding: theme.spacing(1),
  },
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  title: {
    margin: theme.spacing(2, 0),
  },
}));

export default useStyles;
