import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    maxWidth: '150px',
    gap: '8px',
    marginTop: '8px',
  },
  textField: {
    maxWidth: '100px',
  },
}));

export default useStyles;
