import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textField: ({ isTextArea }) => ({
    position: 'relative',
    padding: theme.spacing(isTextArea ? 1 : 0, 0),
    '& .MuiInputBase-input': {
      paddingTop: theme.spacing(1),
    },
  }),
}));

export default useStyles;
