import createEnum from '../../../../utils/actionTypesHelpers/createEnum';

export const sharingStatus = createEnum({
  NotShared: null,
  Pending: null,
  Accepted: null,
  Rejected: null,
});

export const notificationStatus = createEnum({
  acceptFollowSuccess: null,
  rejectFollowSuccess: null,
  dismissedSuccess: null,
});

export const userActionStatus = createEnum({
  acceptFollowSuccess: 'accepted',
  rejectFollowSuccess: 'rejected',
});
