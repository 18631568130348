import React from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { FastField, Formik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';

const EditForm = ({
  values,
  handleSubmit,
  setItemToEdit,
  validationSchema,
  label,
  amountFieldName,
  amountFieldNumber,
  amountFieldMaskType,
  amountFieldComponent,
}) => {
  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {props => {
        return (
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Grid container alignItems="center" justify="center">
                <p>{props.initialValues.year}</p>
              </Grid>
            </TableCell>
            <TableCell colSpan={3} align="center">
              <Grid container alignItems="center">
                <FastField
                  label={label}
                  name={amountFieldName}
                  number={amountFieldNumber}
                  component={amountFieldComponent}
                  maskType={amountFieldMaskType}
                />
              </Grid>
            </TableCell>
            <TableCell colSpan={2} align="center">
              <Grid container direction="row" justify="center" alignItems="center">
                <IconButton aria-label="Done" color="primary" onClick={() => props.handleSubmit()}>
                  <DoneIcon />
                </IconButton>
                <IconButton aria-label="Cancel" color="default" onClick={() => setItemToEdit(null)}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </TableCell>
          </TableRow>
        );
      }}
    </Formik>
  );
};

export default EditForm;
