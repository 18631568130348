import createEnum from '../actionTypesHelpers/createEnum';
import { API_URL } from '../../config/api';

export const TOAST_AUTOCLOSE = 4000;

export const httpStatusCodes = {
  minValidCode: 200,
  maxValidCode: 300,
};

export const fileUploadUrl = `${API_URL}/companies/:companyId/related-document/:sectionKey`;

export const filePsmUploadUrl = `${API_URL}/companies/:companyId/psm-audit/upload`;

export const fileAddendumUploadUrl = `${API_URL}/companies/:companyId/addendum/file/upload`;

export const userTypes = createEnum({
  SafetyCouncil: null,
  Owner: null,
  Contractor: null,
});

export const contractorLevelEnum = createEnum({
  BasicContractor: null,
  PsmContractor: null,
});

export const contractorLevels = {
  BasicContractor: 'Basic Contractor',
  PsmContractor: 'PSM Contractor',
};

export const contractorLevelPathEnum = createEnum({
  BasicContractor: 'basic-contractor',
  PsmContractor: 'psm-contractor',
});

export const companyTypes = createEnum({
  SafetyCouncil: null,
  Owner: null,
  ...contractorLevels,
});

export const pqfStatuses = createEnum({
  Completed: {
    key: 'Completed',
    title: 'Completed',
  },
  InProgress: {
    key: 'InProgress',
    title: 'In Progress',
  },
  Rejected: {
    key: 'Rejected',
    title: 'Rejected',
  },
  PendingReview: {
    key: 'PendingReview',
    title: 'Pending Review',
  },
  Empty: {
    key: 'Empty',
    title: 'Empty',
  },
});

function createStatusesEnum(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    return { ...acc, [key]: obj[key].key };
  }, {});
}

export const reviewStatuses = {
  notReviewed: 'NotReviewed',
  approve: 'Approved',
  reject: 'Rejected',
};

function createStatusesOptionsEnum(obj) {
  return Object.keys(obj).reduce((acc, key) => [...acc, { id: obj[key].key, name: obj[key].title }], []);
}

export const statusesOptions = createStatusesOptionsEnum(pqfStatuses);
export const pqfStatusesEnum = createStatusesEnum(pqfStatuses);

export const companyTypeOptions = createStatusesOptionsEnum(companyTypes);
