import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import useStyles from './styles';
import { userTypes } from '../../../utils/constants';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const WarningDocumentUpdate = ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  const save = skipResetValidationAfterSuccess => {
    localStorage.setItem('skipResetValidationAfterSuccess', skipResetValidationAfterSuccess);

    handleConfirm();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item>
          <WarningIcon className={classes.icon} />
        </Grid>
        <Grid item>
          <DialogTitle className={classes.title}>
            If you save current changes your PQF submission will be recalled. <br /> You will need to resubmit the PQF
            document
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogActions>
        <Button autoFocus onClick={() => save(false)} color="primary" variant="contained" size="small">
          Save
        </Button>
        {type === userTypes.SafetyCouncil && (
          <Button onClick={() => save(true)} variant="contained" size="small" autoFocus>
            Save without recall
          </Button>
        )}
        <Button onClick={handleClose} variant="contained" size="small" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDocumentUpdate;
