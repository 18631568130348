import React from 'react';
import { FastField } from 'formik';
import { Typography } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import FormDatePicker from '../../../components/FormDataPicker';
import useStyles from './styles';
import FormNumberInput from '../../../components/FormNumberInput';

const InsuranceSubForm = ({ title, names }) => {
  const classes = useStyles();

  const { date, name, amount } = names;

  return (
    <>
      <Typography variant="h5" gutterBottom display="inline">
        {title}
      </Typography>
      <FastField className={classes.inputRow} textOnly label="Name" name={name} component={FormInput} />
      <FastField label="Expiration Date" name={date} component={FormDatePicker} />
      <FastField
        className={classes.inputRow}
        label="Coverage Amount"
        name={amount}
        maskType="dollarNumber"
        component={FormNumberInput}
      />
    </>
  );
};

export default InsuranceSubForm;
