import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tooltip } from '@material-ui/core';

import routes from '../../../routes/path';
import formatPath from '../../../../utils/textFormaters/formatPath';
import useStyles from './styles';

const types = {
  Completed: 'Completed',
  InProgress: 'In Progress',
  PendingReview: 'Pending Review',
  Rejected: 'Rejected',
};

const CellStatus = ({ id, status, message, companyId }) => {
  const classes = useStyles({ status });

  const linkProps = (status === types.Rejected || status === types.Completed) && {
    component: Link,
    to: formatPath(routes.private.review, { id: companyId, pqfDocumentId: id }),
  };

  const Wrapper = ({ children }) =>
    status === types.Rejected ? (
      <Tooltip
        arrow
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        title={<Typography variant="body1">{message}</Typography>}
      >
        {children}
      </Tooltip>
    ) : (
      <div>{children}</div>
    );

  return (
    <Wrapper>
      <Typography {...linkProps} variant="body1" display="inline" className={classes.status}>
        {types[status]}
      </Typography>
    </Wrapper>
  );
};

export default CellStatus;
