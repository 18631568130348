import React from 'react';
import { FastField } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import useStyles from './styles';

const OfficerManagerSubForm = ({ position, names }) => {
  const classes = useStyles();

  const { firstName, lastName, title, fax, phone, email } = names;
  return (
    <Grid item xs={3}>
      <Typography variant="h5" gutterBottom>
        {position}
      </Typography>
      <FastField className={classes.inputRow} textOnly label="First Name" name={firstName} component={FormInput} />
      <FastField className={classes.inputRow} textOnly label="Last Name" name={lastName} component={FormInput} />
      <FastField className={classes.inputRow} label="Title" name={title} component={FormInput} />
      <FastField className={classes.inputRow} label="Email" name={email} type="email" component={FormInput} />
      <FastField className={classes.inputRow} label="Phone Number" name={phone} type="tel" component={FormInput} />
      <FastField className={classes.inputRow} label="Fax" name={fax} type="tel" component={FormInput} />
    </Grid>
  );
};

export default OfficerManagerSubForm;
