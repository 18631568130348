import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  notificationPopper: {
    minWidth: theme.spacing(40),
    marginRight: theme.spacing(8),
  },
  notificationContent: {
    maxHeight: theme.spacing(60),
    scrollbarWidth: 'none',
    overflowX: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  notificationItem: {
    display: 'block',
    whiteSpace: 'normal',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.palette.grey['500']}`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

export default useStyles;
