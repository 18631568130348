import React, { memo, useMemo } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

import useStyles from '../styles';

const Toolbar = ({
  withSearchByOwner,
  selectedOwner,
  setSelectedOwner,
  selectedYear,
  setSelectedYear,
  ownerList,
  workingHoursList,
}) => {
  const classes = useStyles();

  const ownersOptions = useMemo(() => ownerList?.map(item => ({ id: item.id, label: item.name, value: item.id })), [
    ownerList,
  ]);

  const yearOptions = useMemo(
    () => workingHoursList?.map(item => ({ id: item.year, label: item.year, value: item.year })),
    [workingHoursList],
  );

  return (
    <div className={classes.columns}>
      {withSearchByOwner && (
        <FormControl variant="filled" size="small" className={classes.column}>
          <Select
            value={selectedOwner}
            onChange={event => setSelectedOwner(event.target.value)}
            variant="outlined"
            className={classes.select}
          >
            {ownersOptions.map((item, index) => (
              <MenuItem key={item.id || index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {yearOptions.length > 0 && (
        <FormControl variant="filled" size="small" className={classes.column}>
          <Select
            value={selectedYear}
            onChange={event => setSelectedYear(event.target.value)}
            variant="outlined"
            className={classes.select}
          >
            {yearOptions?.map((item, index) => (
              <MenuItem key={item.id || index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default memo(Toolbar);
