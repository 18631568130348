import { put, call, takeLatest } from 'redux-saga/effects';

import dataProvider from '../../../store/dataProvider';
import headerNotification from '../../../actionTypes/headerNotification';
import { notification } from '../../../actionTypes';

export function* list({ payload: { companyId } }) {
  try {
    const response = yield call(dataProvider.getList, `companies/${companyId}/notifications`);
    yield put({
      type: headerNotification.list.end,
      payload: response.data.data,
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
    yield put({
      type: headerNotification.list.end,
      payload: [],
    });
  }
}

export default function* headerNotificationListSaga() {
  yield takeLatest(headerNotification.list.start, list);
}
