import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash.get';

import { title } from '../../routes/path';
import Dashboard from '../../containers/Dashboard';
import { filePsmAudit, pages, company as companyActionType } from '../../../actionTypes';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import currentCompany from '../../../actionTypes/currentCompany';

const perPage = 5;
const SafetyCouncilType = 'SafetyCouncil';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId } = useCompanyInfo(id);
  const { type, userCompanyId } = useUserPermissions();
  const hasPermissions = type === SafetyCouncilType;

  const {
    loading,
    generalInfo,
    notification,
    organizationInfo,
    services,
    documents,
    uploadDocuments,
    addendum,
    filePqf,
    fileDocument,
    grade,
    listLoadingScorecard,
    listOwnerGrading,
    ownerGrading,
    currentOwnerGrade,
  } = useSelector(store => store.pages.dashboard);
  const company = useSelector(store => get(store, `entity.company[${companyId}]`, null));
  const documentList = useSelector(store => documents.list.map(id => get(store, `entity.document[${id}]`, {})));
  const uloadDocumentList = useSelector(store =>
    uploadDocuments.list.map(id => get(store, `entity.uploadDocument[${id}]`, {})),
  );

  const listPsm = useSelector(store => get(store, `filePsmAudit.list`, []));

  const unofficialGrade = useSelector(store => store.pages.unofficialGrade);
  const loadingListAddToFavorite = useSelector(store => store.company.list.loadingListAddToFavorite);

  const getDashboardData = useCallback(() => {
    const documentQuery = {
      pagination: {
        page: 1,
        perPage,
      },
    };

    dispatch({
      type: pages.dashboard.get.start,
      payload: { companyId, documentQuery, type, ownerId: userCompanyId },
    });
  }, [companyId, userCompanyId, type, dispatch]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  useEffect(() => {
    dispatch({
      type: filePsmAudit.get.start,
      payload: { companyId },
    });
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch({
      type: currentCompany.companyAdminInfo.get.start,
      payload: { companyId },
    });
  }, [companyId, dispatch]);

  const categories = useSelector(state => state.entity.workCategory);
  const groups = useSelector(state =>
    state.currentCompany.workCategory.groups.map(id => state.entity.workCategoryGroup[id]),
  );
  const { categories: allowedCategories } = useSelector(state => state.currentCompany.workCategory);

  const allCategories = groups.reduce((acc, group) => [...acc, ...group.workCategories], []);

  const workCategoriesValues = allCategories.reduce((acc, categoryId) => {
    return {
      ...acc,
      [categoryId]: {
        isEmployee: allowedCategories.includes(categoryId) && get(categories, `${categoryId}.isEmployee`, false),
        isSubContractor:
          allowedCategories.includes(categoryId) && get(categories, `${categoryId}.isSubContractor`, false),
      },
    };
  }, {});

  const handleSubmitDocument = pqfDocumentId =>
    dispatch({
      type: pages.dashboard.submitDocument.start,
      payload: { companyId, pqfDocumentId },
    });

  const handlePsmSubmit = ({ psmAudit: { createList } }) => {
    dispatch({
      type: filePsmAudit.add.start,
      payload: {
        companyId,
        files: createList.map(item => {
          const { id, ...rest } = item;
          return { ...rest };
        }),
      },
    });
  };

  const handleChangePage = offset => {
    const documentQuery = {
      pagination: {
        page: offset / perPage + 1,
        perPage,
      },
    };
    dispatch({ type: pages.dashboard.documents.start, payload: { companyId, documentQuery } });
  };

  const handleLoadDocument = (companyId, fileId) => {
    dispatch({
      type: pages.dashboard.loadDocument.start,
      payload: { companyId, fileId },
    });
  };

  const handleLoadAllDocuments = (companyId, pqfRelatedDocumentId, key) => {
    dispatch({
      type: pages.dashboard.loadAllUploadDocument.start,
      payload: { companyId, pqfRelatedDocumentId, key },
    });
  };

  const handleDownloadGrade = scores => {
    dispatch({
      type: pages.dashboard.gradeDownload.start,
      payload: { companyId, scores, companyName: company.companyName },
    });
  };

  const getUnOfficialGrade = () =>
    dispatch({
      type: pages.dashboard.unofficialGrade.start,
      payload: { companyId },
    });

  const handleAddToFavorite = async (id, state) => {
    await dispatch({
      type: companyActionType.addToFavorite.start,
      payload: { id, state, companyId: userCompanyId },
    });
    getDashboardData();
  };

  const handleDownloadScoreCard = pqfDocumentId =>
    dispatch({
      type: pages.dashboard.downloadPQFScorecard.start,
      payload: { pqfDocumentId, companyId: userCompanyId },
    });

  const getOwnerGrading = ownerId =>
    dispatch({
      type: pages.dashboard.getOwnerGrading.start,
      payload: { ownerId, companyId },
    });

  const handleDownloadOwnerGrade = (ownerId, scores) =>
    dispatch({
      type: pages.dashboard.downloadOwnerScorecard.start,
      payload: { companyId, companyName: company.companyName, ownerId, scores },
    });

  const handleAdminPanelCard = value =>
    dispatch({
      type: currentCompany.companyAdminInfo.update.start,
      payload: { value, companyId },
    });

  return (
    <>
      <Helmet>
        <title>{title.private.index}</title>
      </Helmet>
      <Dashboard
        loading={loading}
        notification={{
          ...notification,
          unofficialGrade: {
            ...unofficialGrade,
            downloading: grade.loading,
            getUnOfficialGrade,
            handleDownloadGrade,
          },
        }}
        ownerGradingSystem={{
          listOwnerGrading,
          getOwnerGrading,
          ownerGrading,
          handleDownloadOwnerGrade,
        }}
        currentOwnerGrade={currentOwnerGrade}
        company={company}
        generalInfo={generalInfo}
        loadingListAddToFavorite={loadingListAddToFavorite.includes(companyId)}
        handleAddToFavorite={handleAddToFavorite}
        hasPermissions={hasPermissions}
        widgets={{ organizationInfo, services }}
        handleSubmitDocument={handleSubmitDocument}
        handlePsmSubmit={handlePsmSubmit}
        handleAdminPanelCard={handleAdminPanelCard}
        psmAuditList={listPsm}
        documents={{
          perPage,
          list: documentList,
          loading: documents.loading,
          page: documents.page,
          total: documents.total,
        }}
        uploadDocuments={{
          list: uloadDocumentList,
          handleLoadAllDocuments,
          fileDocument,
        }}
        filePqf={filePqf}
        addendum={addendum}
        grade={{
          ...grade,
          handleDownloadGrade,
        }}
        handleLoad={handleLoadDocument}
        handleChangePage={handleChangePage}
        workCategoriesValues={workCategoriesValues}
        allowedCategories={allowedCategories}
        categories={categories}
        groups={groups}
        handleDownloadScoreCard={handleDownloadScoreCard}
        listLoadingScorecard={listLoadingScorecard}
        userCompanyId={userCompanyId}
      />
    </>
  );
};

export default DashboardPage;
