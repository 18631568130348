import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const [companyData, subsidiariesData] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/parent-company`),
      call(dataProvider.getList, `companies/${companyId}/subsidiaries`, {}),
    ]);
    yield put({
      type: currentCompany.parentCompany.get.end,
      payload: { companyData: companyData.data, subsidiaries: subsidiariesData.data },
    });
  } catch (e) {
    yield put({ type: currentCompany.parentCompany.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getParentCompanySaga() {
  yield takeLatest(currentCompany.parentCompany.get.start, get);
}
