import { company } from '../../actionTypes';

const initialState = {
  favoriteOwnerList: [],
  loading: false,
  error: null,
};

export default function favoriteOwnerList(state = initialState, action) {
  switch (action.type) {
    case company.getFavoriteOwnerList.start:
      return { ...state, loading: true };

    case company.getFavoriteOwnerList.end:
      return {
        ...state,
        loading: false,
        favoriteOwnerList: action.payload,
      };

    case company.getFavoriteOwnerList.error:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
