import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* create({ payload: { companyId, contractorLevel, values } }) {
  try {
    const { data } = yield call(
      dataProvider.createOne,
      `companies/${companyId}/addendum?contractorLevel=${contractorLevel}`,
      values,
    );
    yield put({
      type: currentCompany.ownerAddendum.create.end,
      payload: { list: data },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.ownerAddendum.create.error });
  }
}

export default function* createAddendumSaga() {
  yield takeLatest(currentCompany.ownerAddendum.create.start, create);
}
