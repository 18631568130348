import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(2),
  },
  stars: {
    color: theme.palette.warning.main,
    display: 'inline-table',
    verticalAlign: 'middle',
  },
  phoneContainer: {
    marginTop: theme.spacing(2),
  },
  followButton: {
    marginTop: theme.spacing(2),
  },
  indicateColor: ({ indicate }) => ({
    color:
      (indicate === null && theme.palette.warning.main) ||
      (indicate && theme.palette.success.main) ||
      theme.palette.error.main,
  }),
  nameWrapper: {
    marginBottom: theme.spacing(0.04),
  },
  logoWrapper: {
    marginRight: theme.spacing(2),
  },
  description: {
    whiteSpace: 'pre-line',
    width: 'fit-content',
  },
  name: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.warning.light,
  },
  iconError: {
    color: theme.palette.error.light,
  },
  containerIcon: {
    lineHeight: 1,
  },
  imageUpload: {
    width: '100%',
    height: '100%',
    maxHeight: '200px',
    maxWidth: '200px',
  },
  workingHoursExport: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

export default useStyles;
