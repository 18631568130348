import React from 'react';
import { FastField } from 'formik';
import { Grid } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import { maxIntNumber } from '../../../../utils/constants/formConstats';
import useStyles from './styles';

const textAreaType = 'textarea';
const textType = 'text';

const formatTypes = {
  '{0}': {
    min: 0,
    max: maxIntNumber,
    number: 'integer',
  },
  '{0}%': {
    min: 0,
    max: 100,
    type: 'percentage',
    number: 'integer',
  },
};

const TextFieldType = ({ name, label, type = 'text', format }) => {
  const classes = useStyles({ isTextArea: type === textAreaType });

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item lg={format ? 4 : 12} sm={format ? 4 : 12} className={classes.textField}>
        <FastField
          name={name}
          component={FormInput}
          label={label}
          type={type === textAreaType ? textType : type}
          rows={type === textAreaType ? 4 : 1}
          multiline={type === textAreaType}
          {...formatTypes[format]}
        />
      </Grid>
    </Grid>
  );
};

export default TextFieldType;
