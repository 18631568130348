import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 0, 0, 2),
  },
  progress: {
    verticalAlign: 'middle',
  },
}));

export default useStyles;
