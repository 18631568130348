import { call, put, takeLatest } from 'redux-saga/effects';

import { pages, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, pqfRelatedDocumentId, key } }) {
  try {
    yield call(
      dataProvider.getOnly,
      `companies/${companyId}/related-document/${key}/download/${pqfRelatedDocumentId}`,
      {},
    );
    yield put({
      type: pages.dashboard.loadAllUploadDocument.end,
      payload: { documentId: pqfRelatedDocumentId },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({
      type: pages.dashboard.loadAllUploadDocument.error,
      payload: { documentId: pqfRelatedDocumentId },
    });
  }
}

export default function* loadAllUploadDocumentSaga() {
  yield takeLatest(pages.dashboard.loadAllUploadDocument.start, get);
}
