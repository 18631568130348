import * as Yup from 'yup';

import { enums, maxIntNumber } from '../../../utils/constants/formConstats';
import errors from '../../../utils/validate/errorMessages';
import { dateCheck } from '../../../utils/validate/method';
import { enumTypes } from '../../../utils/constants/formBuilder';

Yup.addMethod(Yup.mixed, 'dateCheck', dateCheck);

const validateField = {
  Paragraph: Yup.string()
    .min(1, errors.requiredParagraphMin1)
    .max(255, errors.requiredParagraphMax255),
  Text: Yup.string().min(10, errors.requiredDescriptionMin10),
  YesNo: Yup.string().oneOf(Object.keys(enums.yesno), errors.valueIncorrect),
  Number: Yup.mixed().when({
    is: value => value !== 0,
    then: shema => shema.string,
    otherwise: shema =>
      shema
        .number()
        .min(-maxIntNumber, errors.minNumberFieldValue)
        .max(maxIntNumber, errors.maxNumberFieldValue),
  }),
  Dropdown: Yup.string(),
  Date: Yup.mixed()
    .dateCheck(errors.invalidDate)
        .nullable(),
  File: Yup.string(),
};

const defaultSchema = Yup.object().shape({});

export default fields =>
  Object.keys(fields).reduce((acc, id) => {
    // if field type 'Title', 'Link' - dont need to validate
    if ([enumTypes.Title, enumTypes.Link].includes(fields[id].type)) return acc;

    return acc.concat(
      Yup.object().shape({
        [id]: validateField[fields[id].type].test({
          name: id,
          message: errors.required,
          test: function(value) {
            const formValues = this.parent;

            const hasParent = fields[id].parentId;

            // if field is not required
            if (!fields[id].isRequired) {
              return true;
            }

            // check if field has parent
            if (!hasParent) {
              // check is value not empty
              return value && value !== '';
            } else {
              const parentId = fields[id].parentId;
              const isParentSection = fields[id].parentId && fields[parentId].type === enumTypes.Title;

              // if field hide - not required
              if (fields[id].showOnCondition && formValues[parentId] !== fields[id].parentAnswerCondition) {
                return true;
              }

              // if field parent is type 'Section' and it hide - not required
              if (
                isParentSection &&
                fields[parentId].showOnCondition &&
                formValues[fields[parentId].parentId] !== fields[parentId].parentAnswerCondition
              ) {
                return true;
              }

              // if field parent is type 'Section' and it not hide
              if (isParentSection && !fields[parentId].showOnCondition) {
                return value && value !== '';
              }

              // check is field valid and parent answers are equal with current
              return isParentSection
                ? formValues[fields[parentId].parent] === fields[parentId].parentAnswerCondition &&
                    value &&
                    value !== ''
                : formValues[parentId] === fields[id].parentAnswerCondition && value && value !== '';
            }
          },
        }),
      }),
    );
  }, defaultSchema);
