import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paperContent: {
    minHeight: '100%',
    display: 'grid',
    gridTemplateAreas: `"nav page"`,
    gridTemplateColumns: '380px 1fr',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing(2),
  },
  containerPage: {
    gridArea: 'page',
  },
  sidebarContainer: {
    gridArea: 'nav',
  },
}));

export default useStyles;
