import merge from 'lodash.merge';

import { pages } from '../../actionTypes';

const initialState = {
  loading: true,
  generalInfo: {},
  notification: {
    isGenerationStarted: false,
    sections: [],
    conditions: {},
    creation: false,
    success: false,
    error: false,
  },
  documents: {
    list: [],
    page: 1,
    total: 0,
    loading: false,
  },
  uploadDocuments: {
    list: [],
  },
  filePqf: [],
  fileDocument: [],
  organizationInfo: {},
  services: {},
  addendum: {
    owner: [],
    contractor: [],
  },
  grade: {
    loading: false,
    list: [],
    mark: null,
  },
  listLoadingScorecard: [],
  listOwnerGrading: [],
  ownerGrading: {
    loading: false,
    loadingScorecard: false,
    mark: null,
    list: [],
  },
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case pages.dashboard.get.start:
      return initialState;
    case pages.dashboard.get.end:
      return {
        ...merge({}, state, action.payload),
        loading: false,
      };
    case pages.dashboard.get.error:
      return {
        ...state,
        loading: false,
      };

    case pages.dashboard.submitDocument.start:
      return { ...state, notification: { ...state.notification, creation: true } };
    case pages.dashboard.submitDocument.end:
      return {
        ...state,
        notification: { ...state.notification, creation: false, success: true, isGenerationStarted: false },
      };
    case pages.dashboard.submitDocument.error:
      return { ...state, notification: { ...state.notification, creation: false, success: true, error: true } };

    case pages.dashboard.documents.start:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: true,
        },
      };
    case pages.dashboard.documents.end:
      return {
        ...state,
        documents: {
          ...state.documents,
          ...action.payload,
          loading: false,
        },
      };
    case pages.dashboard.documents.error:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
        },
      };

    case pages.dashboard.loadDocument.start:
      return {
        ...state,
        filePqf: [...state.filePqf, action.payload.fileId],
      };
    case pages.dashboard.loadDocument.end:
      return {
        ...state,
        filePqf: state.filePqf.filter(item => item !== action.payload.fileId),
      };
    case pages.dashboard.loadDocument.error:
      return {
        ...state,
        filePqf: state.filePqf.filter(item => item !== action.payload.fileId),
      };

    case pages.dashboard.loadAllUploadDocument.start:
      return {
        ...state,
        fileDocument: [...state.fileDocument, action.payload.pqfRelatedDocumentId],
      };
    case pages.dashboard.loadAllUploadDocument.end:
      return {
        ...state,
        fileDocument: state.fileDocument.filter(item => item !== action.payload.documentId),
      };
    case pages.dashboard.loadAllUploadDocument.error:
      return {
        ...state,
        fileDocument: state.fileDocument.filter(item => item !== action.payload.documentId),
      };

    case pages.dashboard.gradeDownload.start:
      return {
        ...state,
        grade: {
          ...state.grade,
          loading: true,
        },
      };
    case pages.dashboard.gradeDownload.end:
      return {
        ...state,
        grade: {
          ...state.grade,
          loading: false,
        },
      };
    case pages.dashboard.gradeDownload.error:
      return {
        ...state,
        grade: {
          ...state.grade,
          loading: false,
        },
      };

    case pages.dashboard.updateCondition.update:
      return {
        ...state,
        notification: {
          ...state.notification,
          conditions: action.payload.conditions,
        },
      };

    case pages.dashboard.downloadPQFScorecard.start:
      return {
        ...state,
        listLoadingScorecard: [...state.listLoadingScorecard, action.payload.pqfDocumentId],
      };
    case pages.dashboard.downloadPQFScorecard.end:
      return {
        ...state,
        listLoadingScorecard: state.listLoadingScorecard.filter(id => id !== action.payload.pqfDocumentId),
      };
    case pages.dashboard.downloadPQFScorecard.error:
      return {
        ...state,
        listLoadingScorecard: state.listLoadingScorecard.filter(id => id !== action.payload.pqfDocumentId),
      };

    case pages.dashboard.getOwnerGrading.start:
      return {
        ...state,
        ownerGrading: {
          loading: true,
          list: [],
        },
      };

    case pages.dashboard.getOwnerGrading.end:
      return {
        ...state,
        ownerGrading: {
          ...state.ownerGrading,
          loading: false,
          list: action.payload.ownerGradingList,
        },
      };

    case pages.dashboard.getOwnerGrading.error:
      return {
        ...state,
        ownerGrading: {
          ...state.ownerGrading,
          loading: false,
          list: [],
        },
      };

    case pages.dashboard.downloadOwnerScorecard.start:
      return {
        ...state,
        ownerGrading: {
          ...state.ownerGrading,
          loadingScorecard: true,
        },
      };
    case pages.dashboard.downloadOwnerScorecard.end:
      return {
        ...state,
        ownerGrading: {
          ...state.ownerGrading,
          loadingScorecard: false,
        },
      };
    case pages.dashboard.downloadOwnerScorecard.error:
      return {
        ...state,
        currentOwnerGrade: {
          ...state.ownerGrading,
          loadingScorecard: false,
        },
      };

    default:
      return state;
  }
}
