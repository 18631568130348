import { put, call, takeLatest } from 'redux-saga/effects';

import dataProvider from '../../../store/dataProvider';
import headerNotification from '../../../actionTypes/headerNotification';
import { notification } from '../../../actionTypes';

export function* getUploadExpiration({ payload: { companyId, MailingLogId } }) {
  try {
    const response = yield call(dataProvider.getOnly, `Companies/${companyId}/emails/${MailingLogId}`);
    yield put({
      type: headerNotification.uploadExpiration.end,
      payload: response.data,
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
    yield put({
      type: headerNotification.uploadExpiration.end,
      payload: [],
    });
  }
}

export default function* getUploadExpirationSaga() {
  yield takeLatest(headerNotification.uploadExpiration.start, getUploadExpiration);
}
