import createDataProvider from '../utils/providerHelpers/createDataProvider';
import { API_URL, API_AUTHORIZATION_URL } from '../config/api';
import { saveTokens, clearTokens } from '../utils/providerHelpers/tokensHelper';

const dataProvider = createDataProvider({
  apiBaseUrl: API_URL,
  apiAuthUrl: API_AUTHORIZATION_URL,
  saveTokens,
  clearTokens,
});

export default dataProvider;
