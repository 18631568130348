import { currentCompany } from '../../actionTypes';

const initialState = {
  requestInProgress: false,
  loading: true,
  success: false,
  error: false,
};

export default function info(state = initialState, action) {
  switch (action.type) {
    case currentCompany.info.get.start:
      return {
        ...initialState,
        loading: true,
        success: false,
        error: false,
      };

    case currentCompany.info.get.end:
      return {
        ...state,
        loading: false,
      };

    case currentCompany.info.get.error:
      return {
        ...initialState,
        loading: false,
      };

    case currentCompany.info.update.start:
      return { ...state, requestInProgress: true };

    case currentCompany.info.update.end:
      return {
        ...state,
        requestInProgress: false,
        loading: false,
        success: true,
        error: false,
      };

    case currentCompany.info.update.error:
      return {
        ...state,
        requestInProgress: false,
        loading: false,
        success: false,
        error: true,
      };

    case currentCompany.info.updateImage.start:
      return { ...state, requestInProgress: true };

    case currentCompany.info.updateImage.end:
      return {
        ...state,
        requestInProgress: false,
        loading: false,
        success: true,
        error: false,
      };

    case currentCompany.info.updateImage.error:
      return {
        ...state,
        requestInProgress: false,
        loading: false,
        success: false,
        error: true,
      };

    case currentCompany.info.deleteImage.start:
      return { ...state, requestInProgress: true };

    case currentCompany.info.deleteImage.end:
      return {
        ...state,
        requestInProgress: false,
        loading: false,
        success: true,
        error: false,
      };

    case currentCompany.info.deleteImage.error:
      return {
        ...state,
        requestInProgress: false,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
