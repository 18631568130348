import React from 'react';
import { Grid, Typography, Link as MaterialLink, Button } from '@material-ui/core';

import formatPath from '../../../../utils/textFormaters/formatPath';
import routes from '../../../routes/path';
import useStyles from './styles';

// Notification for Owner

export const SharingRequestRejected = ({ item, companyId, dismissHandler }) => {
  const classes = useStyles();

  const { title: senderCompanyName, id: senderCompanyId } = item.notificationEntities.RecipientCompany[0];
  const senderCompanyLink = formatPath(routes.private.company.id, { id: senderCompanyId });

  return (
    <>
      {!item.userAction && (
        <Grid>
          <Typography variant="h5" gutterBottom>
            Sharing Request
          </Typography>
          <MaterialLink href={senderCompanyLink}>{senderCompanyName}</MaterialLink>
          <span> rejected follow request</span>
          <Grid container justify="center" alignItems="flex-end" className={classes.buttonsWrapper}>
            <Button variant="contained" size="small" onClick={() => dismissHandler(companyId, item.id)}>
              Dismiss
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
