import React from 'react';
import { Formik } from 'formik';

import createValidationSchema from './validate';
import FieldComponent from './fields/fieldComponent';

const YesNoFormConstructor = ({
  initialValues,
  validateSchema,
  fields,
  onSubmit,
  children,
  enableReinitialize = true,
  disabler,
}) => {
  const _children = children;

  const normalizeFields = fields.reduce((acc, item) => {
    return {
      ...acc,
      [item.key]: item,
    };
  }, {});

  const initialFields = fields.reduce((acc, item) => {
    return {
      ...acc,
      [item.key]: item.answer,
    };
  }, {});

  const validation = createValidationSchema(normalizeFields, validateSchema);

  const handleQuestionsSubmit = values =>
    onSubmit({
      ...values,
      ...Object.keys(normalizeFields).reduce(
        (acc, item) => ({
          ...acc,
          [item]: {
            ...normalizeFields[item],
            answer: values[item],
          },
        }),
        {},
      ),
    });

  return (
    <Formik
      initialValues={{ ...initialValues, ...initialFields }}
      validationSchema={validation}
      onSubmit={handleQuestionsSubmit}
      validateOnMount={false}
      validateOnBlur={false}
      enableReinitialize={enableReinitialize}
    >
      {props => {
        const fieldStack = Object.keys(initialFields).reduce(
          (acc, key) => ({
            ...acc,
            [key]: <FieldComponent key={key} name={key} detail={normalizeFields[key]} disabler={disabler} formikValues={props.values} />,
          }),
          {},
        );

        return _children({
          fields: fieldStack,
          handleSubmit: props.handleSubmit,
          submitCount: props.submitCount,
          errors: props.errors,
          touched: props.touched,
          setFieldTouched: props.setFieldTouched,
          setFieldValue: props.setFieldValue,
          dirty: props.dirty,
          isValid: props.isValid,
          values: props.values,
        });
      }}
    </Formik>
  );
};

export default YesNoFormConstructor;
