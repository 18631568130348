import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import TextEllipsis from 'react-text-ellipsis';

import TooltipWrapper from '../TooltipWrapper';
import LinkWrapper from '../LinkWrapper';

const UNICODE_SPACE = '\xa0';

const replaceSpaceToUnicode = value => value.replace(/ /g, UNICODE_SPACE);

const Ellipsis = ({
  title,
  to,
  href,
  target,
  showLink,
  companyName = false,
  typographyVariant = 'body1',
  gutterBottom,
  lines = 1,
  classes,
}) => {
  const [tooltip, setTooltip] = useState(false);

  return (
    <TooltipWrapper title={title} tooltip={tooltip}>
      <Typography variant={typographyVariant} gutterBottom={gutterBottom} className={classes}>
        <LinkWrapper showLink={showLink} to={to} href={href} target={target}>
          <TextEllipsis
            lines={lines}
            tag={'span'}
            ellipsisChars={'...'}
            onResult={result => {
              setTooltip(result === TextEllipsis.RESULT.TRUNCATED);
            }}
          >
            {companyName ? replaceSpaceToUnicode(title) : title}
          </TextEllipsis>
        </LinkWrapper>
      </Typography>
    </TooltipWrapper>
  );
};

export default Ellipsis;
