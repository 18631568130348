import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button, Link as MaterialLink } from '@material-ui/core';

import useStyles from './styles';
import routes from '../../../routes/path';
import formatPath from '../../../../utils/textFormaters/formatPath';

export const ReviewRequest = ({ item, companyId, dismissHandler }) => {
  const classes = useStyles();

  const PqfDocument = () => (
    <MaterialLink
      component={Link}
      to={formatPath(routes.private.review, {
        id: item.notificationEntities.Company[0].id,
        pqfDocumentId: item.notificationEntities.PqfDocument[0].id,
      })}
    >
      {item.notificationEntities.PqfDocument[0].title}
    </MaterialLink>
  );

  const templateMessage = item.name
    .replace('{Company}', item.notificationEntities.Company[0].title)
    .replace('{PqfDocument}', '');

  return (
    <>
      <Typography variant="body1" gutterBottom>
        {templateMessage}
        <PqfDocument />
      </Typography>
      <Grid container justify="center" alignItems="flex-end" className={classes.buttonsWrapper}>
        <Button variant="contained" size="small" onClick={() => dismissHandler(companyId, item.id)}>
          Dismiss
        </Button>
      </Grid>
    </>
  );
};
