import React, { useState } from 'react';

import Grade from '../../../components/Grade';
import GradeModal from '../GradeModal';
import { useUserPermissions } from '../../../../utils/effects/userPermissions';

const SingleOwnerGrade = ({ ownerGrading, handleDownloadOwnerGrade }) => {
  const [openGradingModal, setOpenGradingModal] = useState(false);
  const { userCompanyId } = useUserPermissions();

  const { list, mark, loadingScorecard } = ownerGrading;

  return (
    <>
      <Grade grade={mark} ownerGrade handleClick={() => setOpenGradingModal(true)} />
      <GradeModal
        open={openGradingModal}
        handleClose={() => setOpenGradingModal(false)}
        loadingData={false}
        list={list}
        loading={loadingScorecard}
        handleDownloadGrade={score => handleDownloadOwnerGrade(userCompanyId, score)}
      />
    </>
  );
};

export default SingleOwnerGrade;
