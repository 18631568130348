import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    textDecoration: 'none',
    width: '100%',
    height: theme.spacing(15),
    lineHeight: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default useStyles;
