import { put, takeLatest, call, all } from 'redux-saga/effects';

import { currentCompany } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const companyAdminInfoObject = yield all([
      call(dataProvider.getOnly, `Companies/${companyId}/companyAdminInfo`)
    ]);

      yield put({
          type: currentCompany.companyAdminInfo.get.end,
          payload: { ...companyAdminInfoObject },
    });

  } catch (e) {
      yield put({ type: currentCompany.companyAdminInfo.get.error });
  }
}

export default function* getCompanyAdminInfo() {
  yield takeLatest(currentCompany.companyAdminInfo.get.start, get);
}
