import React from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import { referencesFormSchema } from '../../../../utils/validate/shemas';
import FormInput from '../../../components/FormInput';
import FormNumberInput from '../../../components/FormNumberInput';
import usaStates from '../../../../utils/constants/usaStates';
import useStyles from './styles';
import PromptNotification from '../../../components/PromptComponent';
import FormAutocomplete from '../../../components/FormAutocomplete';

const ReferencesSubForm = ({ handleSubmit, setValueToShowForm }) => {
  const classes = useStyles();
  const initialValues = {
    id: 0,
    customerName: '',
    typeOfWork: '',
    jobSize: '',
    contactInfo: {
      contactPersonFirstName: '',
      contactPersonLastName: '',
      phoneNumber: '',
      streetAddress: { city: '', state: '', zip: '' },
    },
  };

  return (
    <Formik initialValues={initialValues} validationSchema={referencesFormSchema} onSubmit={handleSubmit}>
      {props => {
        return (
          <>
            <PromptNotification formStatus={props.dirty} />
            <Box borderColor="grey.300" borderTop={1} className={classes.formWrapper}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item lg={2} sm={3}>
                  <FastField
                    className={classes.inputRow}
                    label="Customer Name"
                    name="customerName"
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={2} sm={3}>
                  <FastField
                    className={classes.inputRow}
                    label="City"
                    name="contactInfo.streetAddress.city"
                    component={FormInput}
                  />
                  <FastField
                    className={classes.inputRow}
                    label="State"
                    name="contactInfo.streetAddress.state"
                    id="state"
                    options={usaStates}
                    component={FormAutocomplete}
                  />
                  <FastField
                    className={classes.inputRow}
                    label="Zip"
                    name="contactInfo.streetAddress.zip"
                    maskType="zip"
                    component={FormNumberInput}
                  />
                </Grid>
                <Grid item lg={3} sm={3}>
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item lg={7} sm={6}>
                      <FastField
                        className={classes.inputRow}
                        label="Type Of Work"
                        name="typeOfWork"
                        component={FormInput}
                      />
                    </Grid>
                    <Grid item lg={5} sm={6}>
                      <FastField
                        className={classes.inputRow}
                        label="Job Size"
                        name="jobSize"
                        maskType="dollarMaskTenNumbers"
                        component={FormNumberInput}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={2} sm={3}>
                  <FastField
                    className={classes.inputRow}
                    textOnly
                    label="First Name"
                    name="contactInfo.contactPersonFirstName"
                    component={FormInput}
                  />
                  <FastField
                    className={classes.inputRow}
                    textOnly
                    label="Last Name"
                    name="contactInfo.contactPersonLastName"
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={2} sm={3}>
                  <FastField
                    className={classes.inputRow}
                    label="Phone Number"
                    type="tel"
                    name="contactInfo.phoneNumber"
                    component={FormInput}
                  />
                </Grid>
                <Grid item lg={1} sm={3}>
                  <Grid container spacing={1} direction="column" alignItems="center">
                    <Grid item lg={12} sm={6}>
                      <Button size="small" fullWidth variant="contained" onClick={() => setValueToShowForm(false)}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item lg={12} sm={6}>
                      <Button
                        size="small"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleSubmit()}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default ReferencesSubForm;
