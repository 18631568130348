import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';

import CreateNewUserForm from './NewUserForm';

const UsersListData = ({ handleCreateUser, createSuccess }) => {
  const [showUserForm, setShowUserForm] = useState(false);

  useEffect(() => {
    if (createSuccess) {
      setShowUserForm(false);
    }
  }, [createSuccess, setShowUserForm]);

  return (
    <Grid container>
      <Grid container justify="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setShowUserForm(true)}>
            New
          </Button>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        {showUserForm && <CreateNewUserForm setShowUserForm={setShowUserForm} handleSubmit={handleCreateUser} />}
      </Grid>
    </Grid>
  );
};

export default UsersListData;
