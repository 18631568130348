import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { FastField } from 'formik';

import FormCheckbox from '../../../components/FormCheckbox';
import FormInput from '../../../components/FormInput';
import useStyles from './styles';

const CraftsmanTableRow = React.memo(({ item, categoryKey, rowIndex }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell align="left" className={classes.tableCell}>
        <Typography variant="subtitle1">{item.name}</Typography>
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField
          name={`${categoryKey}[${rowIndex}].isNccer`}
          component={FormCheckbox}
          labelClassName={classes.checkboxContainer}
        />
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField
          name={`${categoryKey}[${rowIndex}].isNccco`}
          component={FormCheckbox}
          labelClassName={classes.checkboxContainer}
        />
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField
          name={`${categoryKey}[${rowIndex}].isNicet`}
          component={FormCheckbox}
          labelClassName={classes.checkboxContainer}
        />
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField
          name={`${categoryKey}[${rowIndex}].isAppCode`}
          component={FormCheckbox}
          labelClassName={classes.checkboxContainer}
        />
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField
          name={`${categoryKey}[${rowIndex}].isOlbat`}
          component={FormCheckbox}
          labelClassName={classes.checkboxContainer}
        />
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField name={`${categoryKey}[${rowIndex}].specialty`} component={FormInput} className={classes.inputRow} />
      </TableCell>

      <TableCell align="center" className={classes.tableCell}>
        <FastField name={`${categoryKey}[${rowIndex}].other`} component={FormInput} className={classes.inputRow} />
      </TableCell>
    </TableRow>
  );
});

export default CraftsmanTableRow;
