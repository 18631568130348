import HttpError from './HttpError';
import * as fetchUtils from './fetchUtils';
import { getHeaders, reauthenticateIfNeeded } from './tokensHelper';

const fetchWrapper = async (...params) => {
  const result = await fetch(...params);

  const { status } = result;

  if (status < 200 || status >= 300) {
    const { body, statusText } = result;
    throw new HttpError(body || statusText, status, body);
  }

  return result;
};

export const httpClient = ({ apiAuthUrl, permissionsUrl, clearTokens, saveTokens }) => async (
  url,
  options = {},
  fetchJson = true,
) => {
  const fetchMethod = fetchJson ? fetchUtils.fetchJson : fetchWrapper;

  try {
    return await fetchMethod(url, {
      ...options,
      headers: getHeaders(true),
    });
  } catch (error) {
    if (
      error.status === 401 &&
      (await reauthenticateIfNeeded({ apiAuthUrl, permissionsUrl }, error.status, {
        clearTokensData: clearTokens,
        saveTokensData: saveTokens,
      }))
    ) {
      return fetchMethod(url, { ...options, headers: getHeaders(true) });
    }

    throw error;
  }
};

// this method use only for auth provider
export const sendData = async (
  method,
  url,
  authorizationUrl,
  permissionsUrl,
  params,
  saveTokens,
  clearTokens,
  errorMessage,
) => {
  try {
    const response = await fetchUtils.fetchJson(url, {
      method,
      body: JSON.stringify(params),
      headers: getHeaders(false),
    });
    return response.json;
  } catch (e) {
    if (e.status === 400) {
      // eslint-disable-next-line no-throw-literal
      throw new Error(errorMessage);
    }

    if (e.status < 200 || e.status >= 300) {
      throw e;
    }
  }
};

// this method use only for login
export const authLogin = async (url, params, saveTokens, loginErrorMessage) => {
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(false),
  });
  let json;

  const response = await fetch(request);

  if (response.status === 401) {
    // eslint-disable-next-line no-throw-literal
    throw new Error(loginErrorMessage);
  }

  if (response.status < 200 || response.status >= 300) {
    const body = await response.text();
    try {
      json = JSON.parse(body);
    } catch (e) {
      json = body;
    }
    throw new HttpError(json && (json.message || json.messages), response.status, json);
  }

  const data = await response.json();
  saveTokens(data);
};
