import { addendumFile } from '../../actionTypes';

export default function addendumFileList(state = {}, action) {
  switch (action.type) {
    case addendumFile.downloadByFormBuilderFieldId.start:
    case addendumFile.downloadByFormBuilderFieldAnswerId.start:
      return {
        ...state,
        loading: true,
      };
    case addendumFile.downloadByFormBuilderFieldId.end:
    case addendumFile.downloadByFormBuilderFieldAnswerId.end:
      return {
        ...state,
        loading: false,
      };
    case addendumFile.downloadByFormBuilderFieldId.error:
    case addendumFile.downloadByFormBuilderFieldAnswerId.error:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
