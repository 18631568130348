import { call, put, takeLatest } from 'redux-saga/effects';

import { workingHours } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* update({ payload: { values, contractorId, ownerId } }) {
  try {
    const { data } = yield call(dataProvider.createList, `companies/${contractorId}/working-hours/${ownerId}`, values);

    yield put({
      type: workingHours.update.end,
      payload: data,
    });
  } catch (e) {
    yield put({ type: workingHours.update.error });
  }
}

export default function* updateWorkingHours() {
  yield takeLatest(workingHours.update.start, update);
}
