import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { updateFiles } from '../../file/updateFiles';
import { uploadSelector } from '../../../utils/pageHelpers';

export function* update({ payload: { companyId, sectionKey, values, updateStack, disabler } }) {
  try {
    const { questions, files } = values.reduce(
      (acc, item) => ({
        questions:
          (typeof item.answer === 'string' || item.key === disabler?.fieldName) ? [...acc.questions, item] : [...acc.questions, { ...item, answer: '' }],
        files: typeof item.answer === 'object' ? [...acc.files, item] : acc.files,
      }),
      { questions: [], files: [] },
    );

    if (files.length) {
      yield all(
        files.map(item =>
          call(updateFiles, {
            companyId,
            deleteList: item.answer.deleteList,
            createList: item.answer.createList,
            sectionKey: item.key,
          }),
        ),
      );
    }

    const fileObj = yield select(store =>
      uploadSelector(
        store,
        files.map(item => item.key),
      ),
    );

    yield call(dataProvider.createList, `companies/${companyId}/yes-no-questions`, questions);

    yield put({
      type: currentCompany.yesNoQuestions.update.end,
      payload: {
        stack: updateStack.map(item => ({
          ...item,
          answer: Object.keys(fileObj).includes(item.key) ? fileObj[item.key] : item.answer,
        })),
        sectionKey,
        disabler: disabler,
      },
    });

    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.yesNoQuestions.update.error, payload: { sectionKey } });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateYesNoQuestionsSaga() {
  yield takeLatest(currentCompany.yesNoQuestions.update.start, update);
}
