import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { currentCompany } from '../../../actionTypes';
import CategoriesForm from '../../containers/CategoriesForm';
import { title, sectionKey } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const WorkCategories = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const categoriesEntity = useSelector(state => state.entity.workCategory);
  const groups = useSelector(state =>
    state.currentCompany.workCategory.groups.map(id => state.entity.workCategoryGroup[id]),
  );
  const { success, error, loading, categories } = useSelector(state => state.currentCompany.workCategory);

  useEffect(() => {
    dispatch({ type: currentCompany.workCategory.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleSubmit = values =>
    dispatch({
      type: currentCompany.workCategory.update.start,
      payload: { companyId, values, sectionKey: sectionKey.private.company.workCategories },
    });

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.workCategories, { companyName })}</title>
      </Helmet>
      <CategoriesForm
        groups={groups}
        allowedCategories={categories}
        categories={categoriesEntity}
        handleSubmit={handleSubmit}
        loading={loading}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default WorkCategories;
