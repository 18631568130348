import { put, call, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { company as companySchema } from '../../schema';
import { company, entity, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* addToFavoriteCompany({ payload: { id, companyId, state } }) {
  try {
    const { data } = yield call(dataProvider.createOne, `companies/${companyId}/favourite/${id}`, {
      IsFavourite: state,
    });
    const normalizeData = normalize([{ id, isFavourite: data.isFavourite }], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });
    yield put({ type: company.addToFavorite.end, payload: { id } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: company.addToFavorite.error, payload: { id } });
  }
}

export default function* addToFavoriteCompanySaga() {
  yield takeEvery(company.addToFavorite.start, addToFavoriteCompany);
}
