import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ payload: { companyId } }) {
  try {
    const { data } = yield call(dataProvider.getList, `companies/${companyId}/insurance-policies`);

    const { uploads, ...insurance } = data;
    const { uploadKeys, uploadValues } = uploadHelpers(uploads);

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.insurancePolicy.get.end,
      payload: { ...insurance, ...uploadValues, uploadKeys },
    });
  } catch (e) {
    yield put({ type: currentCompany.insurancePolicy.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getInsurancePolicySaga() {
  yield takeLatest(currentCompany.insurancePolicy.get.start, get);
}
