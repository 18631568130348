import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { FieldArray } from 'formik';

import useStyles from './styles';
import CraftsmanTableRow from './CraftsmanTableRow';

const CraftsmanCertificationSubForm = ({ data, categoryKey }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Table size="small" className={classes.tableWrapper}>
        <TableHead>
          <TableRow className={classes.columnTitle}>
            <TableCell align="left"> Craft </TableCell>
            <TableCell align="center"> NCCER </TableCell>
            <TableCell align="center"> NCCOO </TableCell>
            <TableCell align="center"> NICET </TableCell>
            <TableCell align="center"> App Code </TableCell>
            <TableCell align="center"> OLBAT </TableCell>
            <TableCell align="center"> Speciality </TableCell>
            <TableCell align="center"> Other </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name={categoryKey}
            render={() => {
              return data.map((item, index) => (
                <CraftsmanTableRow key={index} item={item} categoryKey={categoryKey} rowIndex={index} />
              ));
            }}
          />
        </TableBody>
      </Table>
    </Grid>
  );
};

export default CraftsmanCertificationSubForm;
