import React from 'react';
import { Grid } from '@material-ui/core';

import UserTable from './UserTable';
import PaginationWithCustomRows from '../../components/PaginationWithCustomRows';
import FilterForm from '../ArchiveUser/FilterForm';

const ArchiveUser = ({ filter, users, pagination }) => {
  const { handleFilter, filterValues } = filter;
  const { total, currentPage, itemsPerPage, handleChangePage, handleRowAmountChange } = pagination;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterForm handleOnChange={handleFilter} values={filterValues} />
        </Grid>
        <Grid item xs={12}>
          <UserTable {...users} />
        </Grid>
        <Grid item xs={12}>
          <PaginationWithCustomRows
            total={total}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handleChangePage}
            onRowAmountChange={handleRowAmountChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArchiveUser;
