import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    border: `2px solid ${theme.palette.grey['400']}`,
    padding: theme.spacing(0.25),
    borderRadius: theme.spacing(1),
  },
  determinate: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.transparent,
  },
  barColor: ({ isError }) => ({
    backgroundColor: isError ? theme.palette.error.main : theme.palette.success.main,
    transition: 'all 0.3s',
  }),
}));

export default useStyles;
