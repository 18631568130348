import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { title } from '../../routes/path';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { formatPageTitle } from '../../../utils/textFormaters';
import { currentCompany } from '../../../actionTypes';
import UsersListData from '../../containers/UsersListData';

const CompanyUserList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);

  const { users, createSuccess, ids, loading } = useSelector(state => state.currentCompany.user);

  useEffect(() => {
    dispatch({ type: currentCompany.user.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleCreateUser = values => {
    dispatch({ type: currentCompany.user.create.start, payload: { values, companyId } });
  };

  const handleResendInvitation = id => {
    dispatch({ type: currentCompany.user.resend.start, payload: { id } });
  };

  const handleUpdateUser = values => {
    dispatch({ type: currentCompany.user.update.start, payload: { values, companyId } });
  };

  const handleDeleteUser = userId => {
    dispatch({ type: currentCompany.user.remove.start, payload: { companyId, userId } });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.users, { companyName })} </title>
      </Helmet>
      <UsersListData
        loading={loading}
        updatingUserIds={ids}
        userListData={users}
        createSuccess={createSuccess}
        handleCreateUser={handleCreateUser}
        handleResendInvitation={handleResendInvitation}
        handleUpdateUser={handleUpdateUser}
        handleDeleteUser={handleDeleteUser}
      />
    </>
  );
};

export default CompanyUserList;
