import React from 'react';
import { LinearProgress } from '@material-ui/core';

import useStyles from './styles';

const ProgressBar = ({ value = 0, isError }) => {
  const classes = useStyles({ isError });
  return (
    <div className={classes.container}>
      <LinearProgress
        value={value}
        variant="determinate"
        classes={{
          determinate: classes.determinate,
          bar1Determinate: classes.barColor,
        }}
      />
    </div>
  );
};

export default ProgressBar;
