import { createEnum } from '../actionTypesHelpers';

const options = {
  A: null,
  B: null,
  C: null,
  F: null,
  'N/A': null,
  TBD: null,
};

const typesEnum = createEnum(options);
export default Object.keys(typesEnum).map(key => ({
  label: key,
  value: typesEnum[key],
}));
