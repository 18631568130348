import React from 'react';
import Grid from '@material-ui/core/Grid';

import CollapseCard from '../../../components/Cards/CollapseCard';
import DocumentsTable from './DocumentsTable';

const UploadDocuments = ({ uploadDocuments }) => {
  const { list, handleLoadAllDocuments, fileDocument } = uploadDocuments;
  return (
    <CollapseCard title="Uploaded Documents">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DocumentsTable list={list} handleLoad={handleLoadAllDocuments} fileDocument={fileDocument} />
        </Grid>
      </Grid>
    </CollapseCard>
  );
};

export default UploadDocuments;
