import React, { useState, useEffect } from 'react';
import { Grid, Collapse } from '@material-ui/core';

import useStyles from './styles';
import Table from '../Table';
import SubsidiaryInputForm from '../SubsidiaryInputForm';

const SubsidiaryContainer = ({ loading, tableData, setTableData, allowUpdate, initialTableValues }) => {
  const classes = useStyles();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading) {
      updateData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const updateData = val => {
    setData(val);
    setTableData(val);
  };

  const addNewSubsidiary = values => {
    updateData([
      ...data,
      {
        id: Date.now(),
        name: values.name,
      },
    ]);
  };

  const onDeleteConfirm = () => {
    updateData(data.filter(item => item.id !== itemToDelete));
    setItemToDelete(null);
  };

  const onEditConfirm = values => {
    const newData = data.map(item => (item.id === itemToEdit ? values : item));
    setItemToEdit(null);
    updateData(newData);
  };

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={5}>
        <>
          <SubsidiaryInputForm
            allowUpdate={allowUpdate}
            initialTableValues={initialTableValues}
            handleSubmit={addNewSubsidiary}
            tableData={data}
          />
          <Collapse in={!!data.length}>
            <Grid item xs={10} className={classes.tableContainer}>
              <Table
                tableData={data}
                allowUpdate={allowUpdate}
                onDeleteConfirm={onDeleteConfirm}
                setItemToDelete={setItemToDelete}
                onEditConfirm={onEditConfirm}
                loading={loading}
                itemToEdit={itemToEdit}
                itemToDelete={itemToDelete}
                setItemToEdit={setItemToEdit}
              />
            </Grid>
          </Collapse>
        </>
      </Grid>
    </Grid>
  );
};

export default SubsidiaryContainer;
