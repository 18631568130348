import { auth } from '../../actionTypes';

const initialState = {
  loading: false,
};

export default function acceptInvitation(state = initialState, action) {
  switch (action.type) {
    case auth.acceptInvitation.start:
      return { ...state, loading: true };

    case auth.acceptInvitation.end:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
