import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import { getQuestionTitle } from '../../../../utils/constants/pqfValidationQuestions';
import useStyles from './styles';

const MenuTooltip = ({ validationKey, childrenPage }) => {
  const keys = useSelector(store => store.currentCompany.companyInfo.validationFields[validationKey]);
  const classes = useStyles();

  return (
    <div>
      <Typography variant="body2" className={classes.title}>
        Missing values
      </Typography>
      <ul className={classes.list}>
        {keys && keys.map(item => <li key={item}>{getQuestionTitle(item)}</li>)}
        {childrenPage && childrenPage.map(item => <li key={item}>{item}</li>)}
      </ul>
    </div>
  );
};

export default MenuTooltip;
