/*
 Function getTimezoneOffsetWithoutDST calculates timezoneOffset for current user's
 timezone and eliminates the impact of DST (Daylight Saving Time).
 To achieve this we measure getTimezoneOffset at different time of year (january and july) and calculate
 the max of these.
 */

const getTimezoneOffsetWithoutDST = dateTimestamp => {
  const dateYear = new Date(dateTimestamp).getFullYear();
  const janDate = new Date(dateYear, 0, 1);
  const julDate = new Date(dateYear, 6, 1);
  const timezoneOffset = Math.max(janDate.getTimezoneOffset(), julDate.getTimezoneOffset());
  return timezoneOffset * 60 * 1000;
};

/*
DatePicker considers the datetime string that we supply as ISO time string.
That's why it converts it internally to Date object with timezone.
To fix this we should compensate added timezone hours with getTimezoneOffsetWithoutDST function.
 */

export const compensateLocalTimeShifting = dateStr => {
  const dateTimestamp = Date.parse(dateStr);
  return new Date(dateTimestamp + getTimezoneOffsetWithoutDST(dateTimestamp)).toISOString();
};
