import React, { useState } from 'react';
import { Card, CardActionArea, CardMedia, CircularProgress, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import plus from '../../../../images/plusPlaceholder.png';
import useStyles from './styles';
import ModalDialog from '../../../../components/ModalConfirmation';

const ImageUpload = ({ imageLink, allowUpdate, loading, onChange, onDelete }) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  let isPlus = false;

  const handleChange = e => {
    const file = e.target.files[0];
    onChange(file);
  };

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const onConfirmDelete = () => {
    onDelete();
    setIsModalOpen(false);
  };

  let imageSrc;
  if (!imageLink) {
    imageSrc = plus;
    isPlus = true;
  } else if (typeof imageLink === 'object') {
    imageSrc = URL.createObjectURL(imageLink);
  } else {
    imageSrc = imageLink;
  }

  return (
    <>
      <input
        id="fileInput"
        type="file"
        accept=".png, .jpeg, .jpg, .bmp, .gif, .svg"
        onChange={handleChange}
        disabled={!allowUpdate}
        className={classes.inputInvisible}
      />
      <Card className={classes.cardWrapper}>
        {loading
          ? <CircularProgress fontSize="large" />
          : (
            <>
              <CardActionArea className={`${classes.cardActionAreaWrapper} ${isPlus ? classes.plus : ''}`}>
                <label htmlFor={isPlus ? 'fileInput' : ''} className={classes.labelImageWrapper}>
                  <CardMedia image={imageSrc} className={classes.cardMediaWrapper} />
                </label>
              </CardActionArea>
              {!isPlus && allowUpdate && (
                <div className={classes.actions}>
                  <IconButton
                    aria-label="edit"
                    size="large"
                  >
                    <label htmlFor="fileInput" className={classes.actionLabel}>
                      <EditIcon fontSize="large" style={{ color: 'white' }} />
                    </label>
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={handleDelete}
                  >
                    <DeleteIcon fontSize="large" color="error" />
                  </IconButton>
                </div>
              )}
            </>
          )
        }
      </Card>
      <ModalDialog
        title="Are you sure you want to delete the company image?"
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default ImageUpload;
