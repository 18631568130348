import React from 'react';
import { FastField } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import FormRadio from '../FormRadio';
import useStyles from './styles';

const YesNoQuestion = ({ title, name, options }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container direction="row" alignItems="center">
        <Typography variant="subtitle2" className={classes.typography}>
          {title}
        </Typography>
        <FastField name={name} component={FormRadio} options={options} />
      </Grid>
    </Grid>
  );
};

export default YesNoQuestion;
