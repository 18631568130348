import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

import ModalConfirmation from '../../../components/ModalConfirmation';

const dialogTitle = `Are you sure you want to delete this item from this list?
Permanent changes will be applied after the page is saved`;
const dialogPsmTitle = `Are you sure you want to delete this item from this list?`;

const DeleteFile = ({ handleDelete, isDocumentList, popupTitle }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleDialogClose = () => {
    setOpenPopup(false);
    handleDelete();
  };

  return (
    <>
      <IconButton onClick={() => setOpenPopup(true)}>
        <DeleteIcon color="error" />
      </IconButton>
      <ModalConfirmation
        isModalOpen={openPopup}
        title={(popupTitle && popupTitle) || (isDocumentList ? dialogPsmTitle : dialogTitle)}
        onCancel={() => setOpenPopup(false)}
        onConfirm={handleDialogClose}
      />
    </>
  );
};

export default DeleteFile;
