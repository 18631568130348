import { call, put, takeLatest } from 'redux-saga/effects';

import { notification, pages } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* download({ payload: { companyId, pqfDocumentId } }) {
  try {
    yield call(dataProvider.getOnly, `companies/${companyId}/grade/report/${pqfDocumentId}/owner`, {});

    yield put({ type: pages.dashboard.downloadPQFScorecard.end, payload: { pqfDocumentId } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: pages.dashboard.downloadPQFScorecard.error, payload: { pqfDocumentId } });
  }
}

export default function* downloadPQFScorecardSaga() {
  yield takeLatest(pages.dashboard.downloadPQFScorecard.start, download);
}
