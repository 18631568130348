import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    maxWidth: '360px',
    width: '100%',
  },
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
    color: theme.palette.primary,
  },
}));

export default useStyles;
