import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  typography: {
    marginRight: theme.spacing(2.5),
    paddingTop: theme.spacing(2.1),
  },
  dash: {
    margin: theme.spacing(0, 2.5),
    paddingTop: theme.spacing(2.1),
  },
}));

export default useStyles;
