import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { title } from '../../routes/path';
import { company } from '../../../actionTypes';
import CreateCompanyForm from '../../containers/CreateCompanyForm';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import { userTypes } from '../../../utils/constants';

const CreateNewCompany = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(state => state.company.create);
  const [showStatus, setShowStatus] = useState(false);

  const { type } = useUserPermissions();
  const { allowUpdate } = useUserPermissions();

  const isSafetyCouncilUserType = type === userTypes.SafetyCouncil;

  const handleSubmit = values => dispatch({ type: company.create.start, payload: values });

  useEffect(() => {
    setShowStatus(true);
  }, [loading]);

  useEffect(() => {
    setShowStatus(false);
    return () => setShowStatus(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{title.private.company.new}</title>
      </Helmet>
      <CreateCompanyForm
        allowUpdate={allowUpdate}
        loading={loading}
        success={showStatus && success}
        error={showStatus && error}
        isSafetyCouncilUserType={isSafetyCouncilUserType}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateNewCompany;
