import { call, put, takeLatest } from 'redux-saga/effects';

import dataProvider from '../../store/dataProvider';
import { company } from '../../actionTypes';

export function* get({ payload: { contractorId } }) {
  try {
    const { data } = yield call(dataProvider.getOnly, `companies/${contractorId}/owner-favourite`);

    yield put({
      type: company.getFavoriteOwnerList.end,
      payload: data,
    });
  } catch (e) {
    yield put({ type: company.getFavoriteOwnerList.error });
  }
}

export default function* getFavoriteList() {
  yield takeLatest(company.getFavoriteOwnerList.start, get);
}
