import React from 'react';
import { Grid } from '@material-ui/core';

import Pagination from '../Pagination';
import SelectRow from './SelectRow';
import { rows } from '../../../utils/constants/pagination';

const PaginationWithCustomRows = ({
  total,
  currentPage,
  itemsPerPage,
  onPageChange,
  onRowAmountChange,
  rowOptions = rows,
}) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <SelectRow defaultValue={itemsPerPage} options={rowOptions} onChange={onRowAmountChange} />
      </Grid>
      {total > itemsPerPage && (
        <Pagination
          current={currentPage}
          itemsPerPage={itemsPerPage}
          total={total}
          handleClick={(e, offset) => onPageChange(offset)}
        />
      )}
    </Grid>
  );
};

export default PaginationWithCustomRows;
