import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';

import useStyles from './styles';

const successStatusKey = 'Completed';

const CellName = ({ id, status, name, companyId, filePqf, handleLoad }) => {
  const classes = useStyles({ status });

  if (filePqf.includes(id)) {
    return <CircularProgress size={24} />;
  }

  return (
    <>
      {status === successStatusKey ? (
        <Typography display="inline" onClick={() => handleLoad(companyId, id)} className={classes.load} variant="body1">
          {name}
        </Typography>
      ) : (
        <Typography display="inline" variant="body1">
          {name}
        </Typography>
      )}
    </>
  );
};

export default CellName;
