import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import CollapseCard from '../../../components/Cards/CollapseCard';
import useStyles from './styles';
import dashboardStyles from '../styles';
import createEnum from '../../../../utils/actionTypesHelpers/createEnum';

const workCategoryType = createEnum({
  isEmployee: 'Employee',
  isSubContractor: 'SubContractor',
});

const WorkCategories = ({ categories, allowedCategories, groups, workCategoriesValues }) => {
  const classes = useStyles();
  const dashboardClasses = dashboardStyles();

  return (
    <CollapseCard title="Work Categories">
      <Grid item xs={12} className={dashboardClasses.cardWrapper}>
        {!allowedCategories.length ? (
          <Typography variant="body1" align="center" gutterBottom>
            There are no work categories yet
          </Typography>
        ) : (
          groups.map(group => {
            const groupIndex = group.workCategories.findIndex(item => allowedCategories.includes(item));
            if (groupIndex === -1) return null;
            return (
              <Grid key={group.id} className={classes.wrapper}>
                <Typography variant="h6" gutterBottom>
                  {group.name}
                </Typography>
                {group.workCategories.map(category => {
                  const isDisplayed = Object.keys(workCategoriesValues[category]).reduce(
                    (result, item) => workCategoriesValues[category][item] || result,
                    false,
                  );
                  if (!isDisplayed) return null;
                  const workCategoriesNames = Object.keys(workCategoriesValues[category])
                    .map(key => (workCategoriesValues[category][key] ? workCategoryType[key] : null))
                    .filter(key => key != null);
                  return (
                    <Grid key={category}>
                      <Typography variant="subtitle2"> {categories[category].name}: </Typography>
                      <span>{workCategoriesNames.join(', ')}</span>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })
        )}
      </Grid>
    </CollapseCard>
  );
};

export default WorkCategories;
