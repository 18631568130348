import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ companyId }) {
  try {
    const { data } = yield call(dataProvider.getOnly, `companies/${companyId}/officers`);

    const { uploads, ...officers } = data;
    const { uploadKeys, uploadValues } = uploadHelpers(uploads);

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.officer.get.end,
      payload: { officers: { ...officers, ...uploadValues, uploadKeys } },
    });
  } catch (e) {
    yield put({ type: currentCompany.officer.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getOfficerSaga() {
  yield takeLatest(currentCompany.officer.get.start, get);
}
