import optionsCompanyRoleTypes from '../constants/companyRoleTypes';
import optionsUsaStates from '../constants/usaStates';
import optionsBusinessFormTypes from '../constants/businessFormTypes';
import optionsEeoCategoryTypes from '../constants/eeoCategoryTypes';
import optionsSicCodes from '../constants/sicCodes';
import { frequencyOptions, fleetIndividualInsurance } from '../constants/formConstats';

const dropdownOptions = {
  None: [],
  Type: optionsCompanyRoleTypes,
  State: optionsUsaStates,
  FormOfBusiness: optionsBusinessFormTypes,
  EeoCategory: optionsEeoCategoryTypes,
  Sic: optionsSicCodes,
  VehicleType: fleetIndividualInsurance,
  Frequency: frequencyOptions,
};

export default dropdownOptions;
