import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { companyId, value } }) {
    try {
        yield call(dataProvider.updateOnly, `Companies/${companyId}/companyAdminInfo`, value);

        yield put({
            type: currentCompany.companyAdminInfo.update.end,
            payload: value,
        });
    } catch (e) {
        yield put({ type: currentCompany.companyAdminInfo.update.error });
        yield put({ type: notification.add, payload: { error: e.message } });
    }
}

export default function* updateCompanyAdminInfo() {
    yield takeLatest(currentCompany.companyAdminInfo.update.start, update);
}
