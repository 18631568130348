import {
  getValueFromToken,
  clearTokens as defaultClearTokens,
  saveTokens as defaultSaveTokens,
  hasTokens as defaultHasTokens,
} from './tokensHelper';
import { sendData, authLogin } from './httpHelper';

export default (
  { saveTokens = defaultSaveTokens, clearTokens = defaultClearTokens, hasTokens = defaultHasTokens, getPermissions },
  urls,
  errorMessages,
  methods,
) => ({
  login: async params => {
    await authLogin(urls.authorization, params, saveTokens, errorMessages.login);
    return false;
  },
  logout: async () => {
    await clearTokens();
    return false;
  },
  checkAuth: async () => {
    if (hasTokens()) {
      return getValueFromToken();
    }
    // eslint-disable-next-line no-throw-literal
    throw null;
  },
  checkError: async () => {},
  saveTokensLogin: data => defaultSaveTokens(data),
  getPermissions: async () => getPermissions(),
  changePassword: async params => {
    const urlToSendData = `${urls.base}/${urls.changePassword}`;

    return await sendData(
      methods.change,
      urlToSendData,
      urls.authorization,
      urls.permissions,
      params,
      saveTokens,
      clearTokens,
      errorMessages.change,
    );
  },
  forgotPassword: async params => {
    const { email, redirectPath } = params;

    const paramsToSend = {
      email,
      redirectPrefix: `${window.location.origin}${redirectPath}`,
    };

    const urlToSendData = `${urls.base}/${urls.forgotPassword}`;
    return await sendData(
      methods.forgot,
      urlToSendData,
      urls.authorization,
      urls.permissions,
      paramsToSend,
      saveTokens,
      clearTokens,
      errorMessages.forgot,
    );
  },
  signUp: async params => {
    const urlToSendData = `${urls.base}/${urls.signUp}`;
    return await sendData(
      methods.signUp,
      urlToSendData,
      urls.authorization,
      urls.permissions,
      params,
      saveTokens,
      clearTokens,
      errorMessages.signUp,
    );
  },
  resetPassword: async params => {
    const { newPassword, token } = params;
    const urlToSendData = `${urls.base}/${urls.reset}`;
    const newParamsToSend = {
      password: newPassword,
      token,
    };

    return await sendData(
      methods.reset,
      urlToSendData,
      urls.authorization,
      urls.permissions,
      newParamsToSend,
      saveTokens,
      clearTokens,
      errorMessages.reset,
    );
  },
  acceptInvitation: async params => {
    const { newPassword, token } = params;
    const urlToSendData = `${urls.base}/${urls.acceptInvitation}`;
    const newParamsToSend = {
      password: newPassword,
      token,
    };

    return await sendData(
      methods.acceptInvitation,
      urlToSendData,
      urls.authorization,
      urls.permissions,
      newParamsToSend,
      saveTokens,
      clearTokens,
      errorMessages.acceptInvitation,
    );
  },
});
