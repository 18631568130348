import React from 'react';
import { Grid } from '@material-ui/core';
import { FastField } from 'formik';

import FormInput from '../../../components/FormInput';
import useStyles from './styles';

const TextType = ({ label, name, level, readOnly }) => {
  const classes = useStyles({ level });
  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={12}>
        <div className={classes.innerWrapper}>
          <FastField name={name} component={FormInput} label={label} rows={4} multiline disabled={readOnly} />
        </div>
      </Grid>
    </Grid>
  );
};

export default TextType;
