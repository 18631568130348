import React from 'react';
import Grid from '@material-ui/core/Grid';

import AdditionalNotes from './AdditionalNotes';
import QuestionTable from './QuestionTable';
import PaperWrapper from '../../components/PaperWrapper';
import PageLoader from '../../components/PageLoader';
import { reviewStatuses, pqfStatuses } from '../../../utils/constants';

const groupBySectionKey = list =>
  list.reduce(
    (acc, item) =>
      Object.keys(acc).includes(item.sectionKey)
        ? {
            ...acc,
            [item.sectionKey]: {
              ...acc[item.sectionKey],
              questions: [...acc[item.sectionKey].questions, item],
            },
          }
        : {
            ...acc,
            [item.sectionKey]: {
              sectionKey: item.sectionKey,
              sectionName: item.sectionName,
              questions: [item],
            },
          },
    {},
  );

const Review = ({
  list,
  loading,
  pqfDocumentStatus,
  updateList,
  downloadList,
  handleUpdate,
  handleSave,
  handleDownload,
  completerName,
  completedDate,
  rejectReason,
}) => {
  const canApprove = list.reduce((acc, item) => acc && item.status === reviewStatuses.approve, true);

  const editable = pqfDocumentStatus === pqfStatuses.PendingReview.key;

  if (list.length === 0) return <PageLoader />;

  const normalizeList = groupBySectionKey(list);
  const rejected = list.filter(item => item.status === reviewStatuses.reject);
  return (
    <PaperWrapper>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <QuestionTable
              editable={editable || loading}
              list={normalizeList}
              updateList={updateList}
              downloadList={downloadList}
              handleUpdate={handleUpdate}
              handleDownload={handleDownload}
            />
          </Grid>
          <Grid item xs={12}>
            <AdditionalNotes
              editable={editable || loading}
              loading={loading}
              rejectReason={rejectReason}
              completerName={completerName}
              completedDate={completedDate}
              handleSave={handleSave}
              canApprove={canApprove}
              canReject={!rejected.length}
              approvedStatus={pqfDocumentStatus === pqfStatuses.Completed.key}
            />
          </Grid>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};

export default Review;
