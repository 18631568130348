import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: true,
  success: false,
  error: false,
  questions: [],
  answers: [],
};

export default function contractorAddendum(state = initialState, action) {
  switch (action.type) {
    case currentCompany.contractorAddendum.get.start:
      return initialState;

    case currentCompany.contractorAddendum.get.end:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        questions: action.payload.questions,
        answers: action.payload.answers,
      };

    case currentCompany.contractorAddendum.get.error:
      return {
        ...state,
        loading: false,
        error: true,
        questions: [],
        answers: [],
      };

    case currentCompany.contractorAddendum.create.start:
      return {
        ...state,
        loading: true,
      };

    case currentCompany.contractorAddendum.create.end:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        answers: action.payload.answers,
      };

    case currentCompany.contractorAddendum.create.error:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
