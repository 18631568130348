import { all, call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { entity, notification, review } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';
import { company as companySchema } from '../../schema';

export function* get({ payload: { companyId, pqfDocumentId } }) {
  try {
    const [company, { data }] = yield all([
      call(dataProvider.getOnly, `Companies/${companyId}/companyshortinfo`),
      call(dataProvider.getOnly, `companies/${companyId}/pqf/${pqfDocumentId}/review`, {}),
    ]);

    const normalizeData = normalize([company.data], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    const { questionAnswers, completerName, completedDate, pqfDocumentStatus, rejectReason } = data;
    yield put({
      type: review.get.end,
      payload: {
        list: questionAnswers,
        completerName,
        completedDate,
        rejectReason,
        pqfDocumentStatus,
      },
    });
  } catch (e) {
    yield put({
      type: review.get.error,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getReviewSaga() {
  yield takeLatest(review.get.start, get);
}
