import React, { useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';

import ModalDialog from '../ModalConfirmation';
import TooltipWrapper from '../../components/TooltipWrapper';

const IconWithPopUp = ({ handler, title, children, iconClassName, tooltipTitle, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onConfirmDeleteReference = () => {
    handler();
    setIsModalOpen(false);
  };
  const handleClick = () => {
    if (!disabled) {
      setIsModalOpen(true);
    }
  };
  return (
    <Grid item>
      <TooltipWrapper tooltip title={tooltipTitle}>
        <IconButton className={iconClassName} size="small" onClick={handleClick}>
          {children}
        </IconButton>
      </TooltipWrapper>
      <ModalDialog
        title={title}
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={onConfirmDeleteReference}
      />
    </Grid>
  );
};

export default IconWithPopUp;
