import React from 'react';
import Radio from '@material-ui/core/Radio';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import get from 'lodash.get';

import useStyles from './styles';

const FormRadio = ({
  labelPlacement = 'start',
  disabled = false,
  labelClassName,
  size = 'small',
  name,
  field,
  options,
  ...props
}) => {
  const isError = get(props.form.errors, field.name, false);
  const itemTouched = get(props.form.touched, field.name, false);
  const classes = useStyles(!!isError && itemTouched);

  return (
    <RadioGroup {...props} {...field} row>
      {options.map(option => (
        <FormControlLabel
          disabled={disabled}
          key={option.value}
          control={<Radio className={classes.radio} color="default" />}
          label={option.label}
          value={option.value}
          labelPlacement="end"
          className={classes.label}
        />
      ))}
    </RadioGroup>
  );
};

export default FormRadio;
