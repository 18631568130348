import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableBody: {
    '& .MuiTableRow-root:nth-child(1)': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiTableRow-root:nth-child(3)': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiTableCell-root:nth-child(n+2)': {
      borderLeft: '1px solid #ccc',
      minWidth: theme.spacing(17),
      padding: theme.spacing(1),
    },
  },
}));

export default useStyles;
