import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Grid, Link as MaterialLink, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import useStyles from './styles';
import headerNotification from '../../../../actionTypes/headerNotification';
import { userActionStatus } from './headerNotificationTypes';

// Notification for Contractor

export const SharingRequest = ({ item, companyId, loading, dismissHandler }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showAddendumWarning, setShowAddendumWarning] = useState(false);

  const { id: notificationId } = item;
  const { OwnerAddendum, SenderCompany } = item.notificationEntities;
  const { title: senderCompanyName, id: senderCompanyId } = SenderCompany[0];

  const handleAccept = (companyId, senderCompanyId, notificationId) => {
    if (!OwnerAddendum.length && !loading) {
      dispatch({
        type: headerNotification.acceptFollow.start,
        payload: { companyId, senderCompanyId, notificationId },
      });
    } else {
      setShowAddendumWarning(true);
    }
  };

  const handleReject = (companyId, senderCompanyId, notificationId) => {
    dispatch({
      type: headerNotification.rejectFollow.start,
      payload: { companyId, senderCompanyId, notificationId },
    });
  };

  const getUserAction = ({ userAction }) => userAction in userActionStatus && userActionStatus[userAction];

  return (
    <>
      {item.userAction ? (
        <Grid>
          <Typography variant="body1" gutterBottom>
            Sharing Request was {getUserAction(item)}
          </Typography>
          <Grid container justify="center" alignItems="flex-end" className={classes.buttonsWrapper}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                dismissHandler(companyId, notificationId);
              }}
            >
              Dismiss
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid>
            <Typography variant="h5" gutterBottom>
              Sharing Request
            </Typography>
            <span>Company {senderCompanyName} wants to follow you.</span>
            {OwnerAddendum.length > 0 && <span> Upload Addendum before accepting: </span>}
            {OwnerAddendum &&
              OwnerAddendum.map(item => (
                <MaterialLink key={item.ownerAddendumId} href={`/settings/addendum/${senderCompanyId}`}>
                  {item.title + ' '}
                </MaterialLink>
              ))}
            <Grid container justify="space-around" alignItems="center" className={classes.buttonsWrapper}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => handleAccept(companyId, senderCompanyId, notificationId)}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.rejectButton}
                onClick={() => handleReject(companyId, senderCompanyId, notificationId)}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
          <Dialog open={showAddendumWarning} onClose={() => setShowAddendumWarning(false)}>
            <DialogTitle disableTypography>
              <Typography variant="subtitle1" className={classes.warningTitle}>
                You need to upload the addendum first
              </Typography>
            </DialogTitle>
            <DialogActions>
              <Button variant="contained" size="small" color="primary" onClick={() => setShowAddendumWarning(false)}>
                ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
