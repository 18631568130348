import { Switch } from 'react-router-dom';
import React from 'react';

import LoginPage from '../pages/Login';
import ResetPasswordPage from '../pages/ResetPassword';
import SetNewPasswordPage from '../pages/SetNewPassword';
import MainLayout from '../layouts/MainLayout';
import { useCheckAuth } from '../../utils/effects/checkAuth';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import routes from './path';
import AcceptInvitationPage from '../pages/AcceptInvitation';

const Routes = () => {
  const isLoggedIn = useCheckAuth();
  if (typeof isLoggedIn !== 'boolean') {
    return 'loading';
  }

  return (
    <Switch>
      <PublicRoute path={routes.public.resetPassword} exact component={ResetPasswordPage} />
      <PublicRoute path={routes.public.invitationAccept} exact component={AcceptInvitationPage} />
      <PublicRoute path={routes.public.setNewPassword} exact component={SetNewPasswordPage} />
      <PublicRoute path={routes.public.login} exact component={LoginPage} />

      <PrivateRoute path={routes.private.index} component={MainLayout} />
    </Switch>
  );
};

export default Routes;
