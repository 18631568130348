import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tabs: {
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
  },
  tab: {
    padding: theme.spacing(0.5, 2),
    minWidth: '0',
    color: theme.palette.text.primary,
    opacity: '1',
  },
}));

export default useStyles;
