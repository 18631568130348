import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: ({ error, editable }) => ({
    color: (error && theme.palette.error.main) || (editable && theme.palette.success.main) || theme.palette.grey['500'],
    verticalAlign: 'middle',
    fontSize: theme.typography.h1.fontSize,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  }),
  text: ({ error }) => ({
    color: error ? theme.palette.error.main : 'inherit',
    cursor: 'pointer',
    '& span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  }),
  disabled: {
    cursor: 'default !important',
  },
}));

export default useStyles;
