import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import FormNumberInput from '../../../components/FormNumberInput';
import FormInput from '../../../components/FormInput';
import useStyles from './styles';
import { financialInfoSchema } from '../../../../utils/validate/shemas';
import FileUploaderInput from '../../../components/FileUploaderInput';

const WatchEffect = ({ dirty, isValid, values, formUpdate }) => {
  useEffect(() => {
    formUpdate(values, dirty, isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, values, isValid]);
  return null;
};

const FinInfoSubForm = ({ handleSubmit, initialValues, formUpdate, companyId }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={financialInfoSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {props => (
        <>
          <WatchEffect formUpdate={formUpdate} dirty={props.dirty} isValid={props.isValid} values={props.values} />
          <Grid container>
            <Grid item lg={12}>
              <Grid container>
                <Grid item lg={6} sm={8}>
                  <Grid container>
                    <Grid item lg={12} sm={12}>
                      <Typography variant="h5">Your Firm Desired Project Size</Typography>
                    </Grid>
                    <Grid item lg={8}>
                      <FastField
                        className={classes.inputRow}
                        label="Minimum"
                        name="minProjectSize"
                        maskType="dollarMaskTenNumbers"
                        component={FormNumberInput}
                      />
                      <FastField
                        className={classes.inputRow}
                        label="Maximum"
                        name="maxProjectSize"
                        maskType="dollarMaskTenNumbers"
                        component={FormNumberInput}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12}>
              <Grid container>
                <Grid item lg={6} sm={8}>
                  <Grid container>
                    <Grid item lg={12} sm={12}>
                      <Typography variant="h5">Bank Information</Typography>
                    </Grid>
                    <Grid item lg={8}>
                      <FastField
                        className={classes.inputRow}
                        label="Bank Line Of Credit"
                        name="bankLineOfCredit"
                        maskType="dollarMaskTenNumbers"
                        component={FormNumberInput}
                      />
                      <FastField
                        className={classes.inputRow}
                        label="Bonding Capacity"
                        name="bondingCapacity"
                        maskType="dollarMaskTenNumbers"
                        component={FormNumberInput}
                      />
                      <FastField
                        className={classes.inputRow}
                        label="Bank References"
                        name="bankReference"
                        component={FormInput}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} sm={8}>
                  <Grid container>
                    <Grid item lg={12} sm={12}>
                      <Typography variant="h5">Dun & Bradstreet Scores and Ratings</Typography>
                    </Grid>
                    <Grid item lg={8}>
                      <FastField
                        className={classes.inputRow}
                        label="DUNS Number"
                        name="dunsNumber"
                        component={FormNumberInput}
                        maskType="fromZero"
                      />
                      <FastField
                        className={classes.inputRow}
                        label="D&B Financial Rating"
                        maxLength={4}
                        name="finRating"
                        component={FormInput}
                      />
                      <FastField
                        className={classes.inputRow}
                        label="Net Worth"
                        name="netWorth"
                        maskType="dollarNumber"
                        component={FormNumberInput}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} className={classes.uploadWrapper}>
              <FastField
                name="finaudit"
                component={FileUploaderInput}
                label="Upload Audited Financial Statements (Last 2 Fiscal Years)"
                companyId={companyId}
                expirationDate
              />
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default FinInfoSubForm;
