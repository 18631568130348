import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import useStyles from './styles';

const BackButton = ({ handleClick, title, back }) => {
  const classes = useStyles({ back });
  const menu = title !== 'Menu' && classes.subMenu;
  return (
    <Grid item onClick={() => back && handleClick()} className={`${classes.wrapper} ${menu}`}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item className={classes.menuIcon}>
          <Grid container alignItems="center" justify="center" alignContent="center" className={classes.containerIcon}>
            <KeyboardArrowLeftIcon color="action" />
          </Grid>
        </Grid>
        <Grid item className={classes.menuItemWidth}>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BackButton;
