import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonsWrapper: {
    margin: theme.spacing(1, 0),
  },
  rejectButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  warningTitle: {
    color: theme.palette.error.main,
  },
  linkTitle: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
