import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  filterSubContainer: {
    marginRight: theme.spacing(3),
  },
  gutterBottom: {
    padding: theme.spacing(0, 0, 1),
  },
}));

export default useStyles;
