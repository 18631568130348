import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  typography: {
    marginRight: theme.spacing(1),
  },
  root: {
    fontSize: theme.typography.body1.fontSize,
  },
  select: {
    paddingLeft: theme.spacing(1),
  },
}));

export default useStyles;
