import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    textAlign: 'start',
    margin: theme.spacing(0.5, 0),
  },
  buttons: {
    margin: theme.spacing(1, 0),
  },
}));

export default useStyles;
