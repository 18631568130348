import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { yesNoQuestionsAll } from '../../../actionTypes';
import YesNoQuestionsRenderer from '../../containers/YesNoQuestionsRenderer';

// todo: remove this component after YesNoQuestion.json will be finished
const YesNoQuestionsAll = () => {
  const dispatch = useDispatch();

  const { questions, loading } = useSelector(state => state.pages.yesNoQuestionsAll);
  useEffect(() => {
    dispatch({ type: yesNoQuestionsAll.get.start });
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> Test page for all yes no questions </title>
      </Helmet>
      <YesNoQuestionsRenderer questions={questions} loading={loading} handleSubmit={val => console.log(val)} />
    </>
  );
};

export default YesNoQuestionsAll;
