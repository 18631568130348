import React from 'react';
import { Typography } from '@material-ui/core';

const ValidationErrorNotification = () => {
  return (
    <Typography variant="h5">
      You have started the PQF generation process, but it cannot be completed since not all fields are passed validation
    </Typography>
  );
};

export default ValidationErrorNotification;
