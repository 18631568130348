import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReferencesForm from '../../containers/ReferencesForm';
import currentCompany from '../../../actionTypes/currentCompany';
import { title, sectionKey } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const ReferencesPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { references, loadingOnTable, loading, largestJobInfo, error, success } = useSelector(
    state => state.currentCompany.reference,
  );

  useEffect(() => {
    dispatch({ type: currentCompany.reference.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleSubmit = values => {
    dispatch({
      type: currentCompany.reference.create.start,
      payload: { companyId, values, sectionKey: sectionKey.private.company.references },
    });
  };

  const handleUpdate = (values, itemId) => {
    dispatch({
      type: currentCompany.reference.update.start,
      payload: { companyId, values, itemId, sectionKey: sectionKey.private.company.references },
    });
  };

  const handleDelete = itemId => {
    dispatch({
      type: currentCompany.reference.delete.start,
      payload: { companyId, itemId, sectionKey: sectionKey.private.company.references },
    });
  };

  const handleUpdateLargestJob = values => {
    dispatch({
      type: currentCompany.reference.updateJobInfo.start,
      payload: { companyId, values, sectionKey: sectionKey.private.company.references },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.references, { companyName })}</title>
      </Helmet>
      <ReferencesForm
        mapedReferences={references}
        largestJobData={largestJobInfo}
        loadingOnTable={loadingOnTable}
        loading={loading}
        success={success}
        error={error}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        handleUpdateLargestJob={handleUpdateLargestJob}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default ReferencesPage;
