import get from './get';
import submitDocument from './submitDocument';
import documents from './documents';
import loadDocument from './loadDocument';
import gradeDownload from './gradeDownload';
import unofficialGrade from './unofficialGrade';
import loadAllUploadDocument from './loadAllUploadDocument';
import downloadPQFScorecard from './downloadPQFScorecard';
import getOwnerGrading from './getOwnerGrading';
import downloadOwnerScorecard from './downloadOwnerScorecard';

export default [
  get,
  submitDocument,
  documents,
  loadDocument,
  gradeDownload,
  unofficialGrade,
  loadAllUploadDocument,
  downloadPQFScorecard,
  getOwnerGrading,
  downloadOwnerScorecard,
];
