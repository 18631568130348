import React from 'react';
import { Container, Grid } from '@material-ui/core';

import useStyles from './styles';

export const CenteredContainer = ({ children }) => {
  const classes = useStyles();
  return (
    <Container disableGutters>
      <Grid className={classes.container} container justify="center" alignItems="center">
        {children}
      </Grid>
    </Container>
  );
};

export default CenteredContainer;
