import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* updateService({ payload: { companyId, values, other, sectionKey } }) {
  try {
    const { data } = yield call(dataProvider.updateList, `Companies/${companyId}/services`, {
      services: values,
      information: {
        additionalServices: other,
      },
    });

    yield put({
      type: currentCompany.service.update.end,
      payload: { companyServices: data.services.map(item => item.id), others: data.information.additionalServices },
    });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.service.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateserviceaga() {
  yield takeLatest(currentCompany.service.update.start, updateService);
}
