import { takeEvery, call, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { notification } from '../../actionTypes';

export function* addNotification({ payload }) {
  yield all(
    Object.keys(payload)
      .map(key => {
        return Array.isArray(payload[key])
          ? payload[key].filter(item => item).map(item => call(toast[key], item))
          : payload[key] !== '' && call(toast[key], payload[key]);
      })
      .flat(),
  );
}

export default function* notificationSaga() {
  yield takeEvery(notification.add, addNotification);
}
