import React from 'react';

import FileList from '../../../FileUploader/FileList';

const FileQuestions = ({ files, downloadList, handleDownload }) => {
  const handleDownloadFile = id => handleDownload({ id, sectionKey: files.filter(item => item.id === id)[0].key });

  return (
    <FileList
      files={files}
      downloadList={downloadList}
      expirationDate
      deleteList={[]}
      handleDownload={handleDownloadFile}
      handleDelete={() => {}}
    />
  );
};

export default FileQuestions;
