export const skipFalsyValues = values =>
  Object.keys(values)
    .filter(key => values[key].length > 0)
    .reduce((acc, current) => ({ ...acc, [current]: values[current] }), {});

export const uploadHelpers = obj => ({
  uploadKeys: Object.keys(obj),
  uploadValues: Object.keys(obj).reduce(
    (acc, item) => ({
      ...acc,
      [item]: createFileValue(obj[item]),
    }),
    {},
  ),
});

export const createFileValue = (list, clear = false) => ({
  count: clear ? 0 : list.length,
  createList: [],
  deleteList: clear ? list.map(file => file.id) : [],
  list,
});

export const uploadSelector = (store, uploadKeys) =>
  uploadKeys.reduce(
    (acc, item) => ({
      ...acc,
      [item]: createFileValue(store.file.file[item].list),
    }),
    {},
  );
