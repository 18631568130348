import React, { useEffect, useState } from 'react';
import { FastField } from 'formik';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import BLSIndustry from '../../../../utils/constants/BLSIndustry';
import FormInput from '../../../components/FormInput';
import useStyles from './styles';

const BLSTable = ({ values, loading, currentYear, lastYearsData }) => {
  const [edit, setEdit] = useState(false);
  const hasPrevYears = !!Object.keys(lastYearsData).length;
  const classes = useStyles({ hasPrevYears });

  useEffect(() => {
    if (!loading) setEdit(false);
  }, [loading]);

  return (
    <Grid container component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={hasPrevYears ? 2 : 1}> NAICS </TableCell>
            <TableCell rowSpan={hasPrevYears ? 2 : 1}> Industry </TableCell>
            <TableCell
              colSpan={Object.keys(lastYearsData).length + 1}
              align="center"
              className={classes.titleYears}
            >
              <Grid item xs={12}>
                <Grid container alignItems="center" justify="center" spacing={1}>
                  <Grid item> BLS values </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      color="primary"
                      onClick={() => setEdit(prevState => !prevState)}
                    >
                      {edit ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          {hasPrevYears && (
            <TableRow>
              {Object.keys(lastYearsData).map(year => (
                <TableCell key={year} align="center">
                  {year}
                </TableCell>
              ))}
              <TableCell key={currentYear} align="center">
                {currentYear}
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody className={classes.tableBody}>
          {Object.keys(values).length &&
            Object.keys(values).map(item => (
              <TableRow key={item}>
                <TableCell> {item} </TableCell>
                <TableCell> {BLSIndustry[item]} </TableCell>
                {Object.keys(lastYearsData).map(year => (
                  <TableCell align="center" key={year}>
                    {lastYearsData[year][item]}
                  </TableCell>
                ))}
                <TableCell align="center">
                  {edit ? (
                    <FastField name={`${item}.value`} min={0} max={9999999999} number="float" component={FormInput} />
                  ) : (
                    values[item].value
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default BLSTable;
