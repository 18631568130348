import React from 'react';
import get from 'lodash.get';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { getCurrentRequiredYear } from '../../../../utils/constants/yearHelper';

const TableActions = ({ props, year, index, arrayHelpers }) => {
  const currentRequiredYear = getCurrentRequiredYear();
  const yearValue = parseInt(year.year);
  const canDelete = yearValue > currentRequiredYear || yearValue < currentRequiredYear - 2;
  const errorColumn = get(props, `errors.years[${index}]`, {});
  const canSave = typeof errorColumn === 'string' || Object.keys(errorColumn).length === 0;

  const setTouched = Object.keys(get(props, `values.years[${index}]`, {})).reduce(
    (acc, item) => ({ ...acc, [`years[${index}].${item}`]: true }),
    {},
  );
  const editClick = () =>
    canSave ? props.setFieldValue(`years[${index}].edit`, false) : props.setTouched(setTouched, true);

  return (
    <TableCell>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item>
            {year.edit ? (
              <IconButton color="primary" onClick={editClick} size="small">
                <SaveIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={() => props.setFieldValue(`years[${index}].edit`, true)}
                size="small"
              >
                <EditIcon />
              </IconButton>
            )}
          </Grid>
          {canDelete && (
            <Grid item>
              <IconButton color="secondary" onClick={() => arrayHelpers.remove(index)} size="small">
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default TableActions;
