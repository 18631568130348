import { makeStyles } from '@material-ui/core/styles';

import { iconSpacing } from '../styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(0.25, 1, 0.25, 0),
    borderTop: `2px solid ${theme.palette.grey['500']}`,
    borderBottom: `2px solid ${theme.palette.grey['500']}`,
    backgroundColor: 'transparent',
    transition: 'all 0.3s',
    cursor: 'pointer',
  },
  subMenu: {
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
  menuIcon: ({ back }) => ({
    width: theme.spacing(iconSpacing),
    transition: 'all 0.3s',
    opacity: back ? '1' : '0',
  }),
  containerIcon: {
    minHeight: `calc(${theme.typography.subtitle2.lineHeight} + ${theme.typography.subtitle2.lineHeight})`,
  },
  menuItemWidth: {
    width: `calc(100% - ${theme.spacing(iconSpacing)}px)`,
  },
}));

export default useStyles;
