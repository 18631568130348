import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    configuration: {
      get: createEnum(requestState, 'settings.configuration.get'),
      update: createEnum(requestState, 'settings.configuration.update'),
    },
    BLSData: {
      get: createEnum(requestState, 'settings.BLSData.get'),
      update: createEnum(requestState, 'settings.BLSData.update'),
    },
  },
  'settings',
);
