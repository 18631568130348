import React from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import useStyles from './styles';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import PromptNotification from '../../components/PromptComponent';
import AutocompleteDropdown from '../../components/AutocompleteDropdown';
import { validateCompanySettings } from '../../../utils/validate/shemas';
import { companyTypes, userTypes } from '../../../utils/constants';

const CompanySettingsForm = ({
  loading,
  users,
  updating,
  data,
  handleUpdate,
  success,
  error,
  companyType,
  userType,
}) => {
  const classes = useStyles();

  if (loading) return <PageLoader />;

  const userOptions = users.map(user => user.emailAddress);

  return (
    <Formik
      initialValues={{
        notificationEmails: data.notificationEmails,
        notificationEmailsExpiredDocument: data.notificationEmailsExpiredDocument,
        allowExportingWorkingHours: data.allowExportingWorkingHours,
      }}
      validationSchema={validateCompanySettings}
      enableReinitialize
      onSubmit={handleUpdate}
    >
      {props => {
        return (
          <>
            <PromptNotification formStatus={props.dirty} />
            <FormBody loading={updating}>
              <Grid item xs={12}>
                <Grid container justify="center" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12}>
                    <Grid container className={classes.bottomContainer}>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Send notification for email:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          label="Notification emails"
                          name="notificationEmails"
                          options={userOptions}
                          component={AutocompleteDropdown}
                          multiple
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.bottomContainer}>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Send notification email about expired document to:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          label="Notification emails"
                          name="notificationEmailsExpiredDocument"
                          options={userOptions}
                          component={AutocompleteDropdown}
                          multiple
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {companyType === companyTypes.Owner && userType === userTypes.SafetyCouncil && (
                    <Grid item xs={12}>
                      <Grid container className={classes.bottomContainer}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={props.values.allowExportingWorkingHours}
                                onChange={props.handleChange}
                                color="primary"
                              />
                            }
                            label="Allow exporting working hours"
                            name="allowExportingWorkingHours"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <LoadingButton
                skipCheckPQF
                disabled={!props.dirty || !props.isValid}
                text="save"
                loading={updating}
                success={success}
                error={error}
                onClick={props.handleSubmit}
              />
            </FormBody>
          </>
        );
      }}
    </Formik>
  );
};

export default CompanySettingsForm;
