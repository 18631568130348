import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Grid } from '@material-ui/core';
import { Formik, FastField } from 'formik';

import { columnEnum, disabled } from '../columns';
import FormCheckbox from '../../../../components/FormCheckbox';
import { userTypes } from '../../../../../utils/constants';

const ConfigureModal = ({ open, type, setOpenModal, visibleColumns, setVisibleColumns }) => {
  const initialValues = Object.keys(columnEnum)
    .filter(
      column =>
        !(type === userTypes.SafetyCouncil && [columnEnum.favorite.key, columnEnum.ownerGrade.key].includes(column)),
    )
    .reduce(
      (acc, item) => ({
        ...acc,
        [columnEnum[item].key]: visibleColumns.includes(columnEnum[item].key),
      }),
      {},
    );

  const handleSave = values => {
    setVisibleColumns(Object.keys(values).filter(item => values[item]));
    setOpenModal(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSave}>
      {({ values, handleSubmit, resetForm }) => (
        <Dialog
          open={open}
          onClose={() => {
            setOpenModal(false);
            resetForm();
          }}
        >
          <DialogTitle> Choose visible columns </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Grid container justify="flex-start" alignItems="flex-start">
                {Object.keys(values).map(key => (
                  <Grid key={key} item xs={4}>
                    <FastField
                      name={key}
                      component={FormCheckbox}
                      labelPlacement="end"
                      label={columnEnum[key].title}
                      disabled={disabled.includes(key)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleSubmit} color="primary" variant="contained" size="small">
              Save
            </Button>
            <Button
              onClick={() => {
                setOpenModal(false);
                resetForm();
              }}
              variant="contained"
              size="small"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default ConfigureModal;
