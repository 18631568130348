import React, { useState } from 'react';
import { Card, CardActionArea, CardMedia, Typography } from '@material-ui/core';

import logo from '../../../images/logoPlaceholder.png';
import useStyles from './styles';
import { logoMaxSizeBytes } from '../../../../utils/constants/fileSizes';

const LogoUploadForm = ({ field, title, allowUpdate, form: { values, setFieldValue } }) => {
  const classes = useStyles();
  const { logoLink } = values;

  const [validSize, setValidSize] = useState(true);

  const handleChange = e => {
    const file = e.target.files[0];
    const isValidSize = file && file.size <= logoMaxSizeBytes;
    setValidSize(isValidSize);
    if (isValidSize) setFieldValue(field.name, file);
  };

  let imageLink;
  if (!logoLink) {
    imageLink = logo;
  } else if (typeof logoLink === 'object') {
    imageLink = URL.createObjectURL(logoLink);
  } else {
    imageLink = logoLink;
  }

  return (
    <>
      <input
        id="fileInput"
        type="file"
        accept=".png, .jpeg, .jpg, .bmp, .gif, .svg"
        onChange={handleChange}
        disabled={!allowUpdate}
        className={classes.inputInvisible}
      />
      <Card className={classes.cardWrapper}>
        <CardActionArea className={classes.cardActionAreaWrapper}>
          <label htmlFor="fileInput" className={classes.labelImageWrapper}>
            <CardMedia image={imageLink} className={classes.cardMediaWrapper} title={title} />
          </label>
        </CardActionArea>
      </Card>
      {!validSize && <Typography className={classes.errorTitle}>The maximum file size is 2 MB</Typography>}
    </>
  );
};

export default LogoUploadForm;
