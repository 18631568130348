import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, params } }) {
  try {
    const { data } = yield call(dataProvider.getList, `Companies/${companyId}/Users`, params);
    yield put({
      type: currentCompany.user.get.end,
      payload: data,
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getUserListSaga() {
  yield takeLatest(currentCompany.user.get.start, get);
}
