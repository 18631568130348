import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';

import routes from '../../routes/path';
import image404 from '../../images/404.png';
import PaperWrapper from '../../components/PaperWrapper';
import useStyles from './styles';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const buttonText = {
  Contractor: 'dashboard',
  Owner: 'marketplace',
  SafetyCouncil: 'marketplace',
};

const NotFound = () => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  return (
    <PaperWrapper>
      <Grid
        container
        justify="center"
        alignItems="center"
        alignContent="center"
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item className={classes.imageContainer}>
              <img src={image404} alt="page not found" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Button to={routes.private.index} component={Link} size="small" variant="contained" color="primary">
                Back to {buttonText[type]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};

export default NotFound;
