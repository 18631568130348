import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';

import routes, { routeCreator } from '../../routes/path';
import useStyles from './styles';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const MainMenu = () => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  const privateRoutes = routeCreator(routes.private.settings);

  const { pathname } = useLocation();

  const menuType = {
    SafetyCouncil: [
      {
        label: 'Marketplace',
        to: routes.private.index,
        key: routes.private.index,
      },
      // temporary hide Reports tab
      // {
      //   label: 'Reports',
      //   to: routes.private.report,
      //   key: routes.private.report,
      // },
      {
        label: 'Settings',
        to: privateRoutes.private.company.users,
        key: routes.private.settings,
      },
    ],
    Owner: [
      {
        label: 'Marketplace',
        to: routes.private.index,
        key: routes.private.index,
      },
      // {
      //   label: 'Reports',
      //   to: routes.private.report,
      //   key: routes.private.report,
      // },
      {
        label: 'Settings',
        to: privateRoutes.private.company.settingsInfo,
        key: routes.private.settings,
      },
    ],
    Contractor: [
      {
        label: 'Dashboard',
        to: routes.private.index,
        key: routes.private.index,
      },
      // {
      //   label: 'Reports',
      //   to: routes.private.report,
      //   key: routes.private.report,
      // },
      {
        label: 'Settings',
        to: privateRoutes.private.company.settingsInfo,
        key: routes.private.settings,
      },
    ],
  };

  const menu = menuType[type];

  const value = menu.reduce((acc, { to, key }, index) => {
    return pathname.startsWith(key) ? index : acc;
  }, 0);

  return (
    <Tabs indicatorColor="primary" value={value} className={classes.tabs}>
      {menu.map(({ label, to }) => (
        <Tab key={label} to={to} component={Link} label={label} className={classes.tab} />
      ))}
    </Tabs>
  );
};
export default MainMenu;
