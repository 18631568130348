import React from 'react';
import get from 'lodash.get';

import FileUploaderController from '../../layouts/FileUploaderController';

const FileUploaderInput = ({
  form,
  field,
  label,
  hasPermissions,
  expirationDate,
  defaultExpiration,
  completedBy,
  listType,
  handlePsmSubmit,
  cardTitle,
  completedOn,
  companyId,
  disabled,
}) => {
  const handleUpdate = ids => {
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, ids, true);
  };

  const error = get(form, `errors[${field.name}]`, undefined);
  const touched = get(form, `touched[${field.name}]`, undefined);

  return (
    <FileUploaderController
      value={field.value}
      error={touched && error}
      companyId={companyId}
      hasPermissions={hasPermissions}
      sectionKey={field.name}
      expirationDate={expirationDate}
      defaultExpiration={defaultExpiration}
      completedBy={completedBy}
      completedOn={completedOn}
      listType={listType}
      handlePsmSubmit={handlePsmSubmit}
      cardTitle={cardTitle}
      uploaderTitle={label}
      handleUpdate={handleUpdate}
      disabled={disabled}
    />
  );
};

export default FileUploaderInput;
