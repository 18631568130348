import React from 'react';

import Certification from '../../images/Certification.png';
import useStyles from './styles';

const CertificationIcon = ({ size }) => {
  const classes = useStyles({ size });
  return (
    <div className={classes.container}>
      <img src={Certification} alt="Certification icon" />
    </div>
  );
};

export default CertificationIcon;
