import { makeStyles } from '@material-ui/core/styles';

import { pqfStatusesEnum } from '../../../../utils/constants';

const useStyles = makeStyles(theme => ({
  status: ({ status }) => ({
    color:
      (status === pqfStatusesEnum.Completed && theme.palette.success.main) ||
      (status === pqfStatusesEnum.InProgress && theme.palette.warning.main) ||
      (status === pqfStatusesEnum.PendingReview && theme.palette.grey['500']) ||
      (status === pqfStatusesEnum.Rejected && theme.palette.error.main) ||
      theme.palette.primary.main,
  }),
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey['500']}`,
  },
  tooltipArrow: {
    color: theme.palette.grey['500'],
  },
}));

export default useStyles;
