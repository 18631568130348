import { maxIntNumber } from '../constants/formConstats';

export default {
  email: 'Invalid email',
  required: 'Field is required',
  requiredMin8: 'Password must be at least 8 characters.',
  requiredMax50: 'Password must be no longer than 50 characters!',
  confirmPassword: 'Password confirmation should be the same as password',
  incorrectPassword: 'Password must be at least 8 characters long, be of mixed case and also contain a number',
  requiredCompanyNameMax100: 'Company name must be no longer than 100 characters',
  phoneIncorrect: 'Invalid phone number',
  faxIncorrect: 'Invalid fax',
  urlIncorrect: 'Invalid URL',
  requiredUrlMax255: 'Web Site must be no longer than 255 characters',
  requiredNameMax255: 'Name must be no longer than 255 characters',
  requiredCustomerNameMax255: 'Customer Name must be no longer than 255 characters',
  requiredTaxIdNineDigits: 'Tax ID must contain 9 numbers',
  requiredNumberNineDigits: 'DUNS Number must contain 9 numbers only',
  requiredStreetNameMax255: 'Street name must be no longer than 255 characters',
  requiredCityMax255: 'City must be no longer than 255 characters',
  requiredZipMin5: 'Zip must contain 5 numbers',
  requiredStateContractorNumberMax255: 'State contractor number must be no longer than 255 characters',
  requiredAverageNumEmployeesMin1: 'Employees number must be greater than 0',
  requiredNumEmployeesMin1: 'Must be greater than 0',
  requiredUserFirstNameMax100: 'First name must be no longer than 100 characters',
  requiredUserLastNameMax100: 'Last Name must be no longer than 100 characters',
  requiredUserSurnameMax100: 'Last Name must be no longer than 100 characters',
  requiredUserEmailMax255: 'Email must be no longer than 255 characters',
  valueIncorrect: 'Value is not correct',
  requiredTitleMax100: 'Title must be no longer than 100 characters',
  requiredCertificationsMax255: 'Certifications must be no longer than 255 characters',
  requiredReportsToMax201: 'Reports to Name must be no longer than 201 characters',
  requiredDescriptionMax255: 'Description must be no longer than 255 characters',
  requiredBankReferencesMax100: 'Bank References must be no longer than 100 characters',
  requiredFinRatingMax4: 'D&B Financial Rating must be no longer than 4 characters',
  minProjectSizeComparison: 'Must be no longer than maximum project size',
  maxProjectSizeComparison: 'Must be not less than minimum project size',
  file: {
    bigFileSize: 'The maximum file size is 5 MB',
    maxFileSize: 'The maximum file size is 20 MB',
    required: 'File is not selected',
  },
  requiredParentCompanyNameMax255: 'Parent Company Name must be no longer than 255 characters',
  requiredCompanyNameMin3: 'Company Name must be at least 3 characters',
  requiredMinLessThanMax: 'Must be no longer than Max',
  requiredMaxMoreThanMin: 'Must be not less than Min',
  maxNumberValue: 'Max value 999999',
  minCreatedYears: 'Must be not less 3 years',
  cascadeYearList: 'Last three year must be described',
  requiredCraftsmanMax100: 'Max 100 chars',
  subsidiaryCount: 'At least one Subsidiary is required',
  requiredTypeOfWorkMax255: 'Type of work must be no longer than 255 characters',
  maxDate: 'You exceeded the maximum allowed date',
  minDate: 'You exceeded the minimum allowed date',
  invalidDate: 'Date is not valid',
  requiredTitleMax20: 'Title must be no longer than 255 characters',
  requiredFirstNameMin3: 'First Name must be at least 3 characters',
  requiredLastNameMin3: 'Last Name must be at least 3 characters',
  requiredNameMin3: 'Name must be at least 3 characters',
  requiredCustomerNameMin3: 'Customer Name must be at least 3 characters',
  requiredCityMin3: 'City must be at least 3 characters',
  requiredStreetMin3: 'Street must be at least 3 characters',
  requiredAddressMin3: 'Address must be at least 3 characters',
  requiredDescriptionMin10: 'Description must be at least 10 characters',
  requiredMinChars3: 'Must be at least 3 characters',
  requiredMinChars5: 'Must be at least 5 characters',
  requiredMinChars10: 'Must be at least 10 characters',
  requiredQuestionTitleMax100: 'Question title must be no longer than 100 characters',
  requiredQuestionTitleMax255: 'Question title must be no longer than 255 characters',
  requiredQuestionMin3: 'Question title must be at least 3 characters',
  requiredQuestionOptionMin3: 'Option name must be at least 3 characters',
  requiredQuestionOptionMax255: 'Option name must be no longer than 255 characters',
  requiredValueMax100: 'Value must be no longer than 100 characters',
  requiredRejectReasonMin10: ' Reject Reason must be at least 10 characters',
  requiredRejectReasonMax255: ' Reject Reason must be no longer than 255 characters',
  maxNumberFieldValue: `Max value ${maxIntNumber}`,
  minNumberFieldValue: `Min value ${-maxIntNumber}`,
  requiredParagraphMin1: 'Answer must be at least 1 character',
  requiredParagraphMax255: 'Answer must be no longer than 255 characters',
  requiredServicesMax3: 'Services description must be at least 3 characters.',
};
