import { call, put, takeLatest } from 'redux-saga/effects';

import { file, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* remove({ payload: { companyId, sectionKey, files } }) {
  try {
    yield call(dataProvider.deleteList, `companies/${companyId}/related-document/${sectionKey}`, files);

    yield put({ type: file.remove.end, payload: { files } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: file.remove.error, payload: { files } });
  }
}

export default function* removeFileSaga() {
  yield takeLatest(file.remove.start, remove);
}
