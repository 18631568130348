import { createEnum } from '../actionTypesHelpers';

export const enumTypes = createEnum({
  Title: null,
  Text: null,
  YesNo: null,
  Number: null,
  Dropdown: null,
  Date: null,
  Paragraph: null,
  Link: null,
  File: null,
});

const labelEnums = {
  YesNo: 'Yes/No',
};

function createOptionsEnum(obj) {
  return Object.keys(obj).reduce((acc, key) => [...acc, { value: key, label: labelEnums[key] || obj[key] }], []);
}

export const typeOptions = createOptionsEnum(enumTypes);
