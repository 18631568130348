import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import routes from '../../ui/routes/path';
import { auth, notification } from '../../actionTypes';
import authProvider from '../../store/authProvider';

export function* login({ payload: { email, password } }) {
  try {
    yield call(authProvider.login, {
      username: email,
      password,
    });
    yield put({ type: auth.login.end, payload: { state: true } });
    yield put({ type: auth.check.start });
    yield put(push(routes.private.index));
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: auth.login.end });
  }
}

export default function* loginSaga() {
  yield takeLatest(auth.login.start, login);
}
