import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.grey['500'],
  },
  section: {
    backgroundColor: theme.palette.grey['300'],
  },
  question: {
    backgroundColor: theme.palette.grey['50'],
  },
  tableStyles: {
    '& > tbody > tr > td:nth-child(1)': {
      width: '90%',
    },
    '& > tbody > tr > td:nth-child(2)': {
      width: '10%',
    },
  },
}));

export default useStyles;
