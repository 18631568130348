import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.labels.success,
  },
  error: {
    color: theme.palette.labels.error,
  },
}));

export default useStyles;
