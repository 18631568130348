import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import Review from '../../containers/Review';
import { title } from '../../routes/path';
import { review } from '../../../actionTypes';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const ReviewPage = () => {
  const dispatch = useDispatch();
  const { id, pqfDocumentId } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);

  const {
    list,
    loading,
    pqfDocumentStatus,
    completerName,
    completedDate,
    rejectReason,
    updateList,
    downloadList,
  } = useSelector(store => store.review);

  useEffect(() => {
    dispatch({
      type: review.get.start,
      payload: {
        companyId,
        pqfDocumentId,
      },
    });
  }, [companyId, pqfDocumentId, dispatch]);

  const handleUpdate = params =>
    dispatch({
      type: review.update.start,
      payload: {
        companyId,
        pqfDocumentId,
        params,
      },
    });

  const handleSave = params =>
    dispatch({
      type: review.save.start,
      payload: {
        companyId,
        pqfDocumentId,
        params,
      },
    });

  const handleDownload = ({ id, sectionKey }) =>
    dispatch({
      type: review.downloadFile.start,
      payload: {
        companyId,
        fileId: id,
        sectionKey,
      },
    });

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.review, { companyName })}</title>
      </Helmet>
      <Review
        loading={loading}
        list={list}
        pqfDocumentStatus={pqfDocumentStatus}
        completerName={completerName}
        completedDate={completedDate}
        rejectReason={rejectReason}
        updateList={updateList}
        downloadList={downloadList}
        handleSave={handleSave}
        handleUpdate={handleUpdate}
        handleDownload={handleDownload}
      />
    </>
  );
};

export default ReviewPage;
