import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Grid } from '@material-ui/core';

import { title } from '../../routes/path';
import CenteredContainer from '../../components/CenteredContainer';
import { auth } from '../../../actionTypes';
import LoginForm from '../../containers/LoginForm';
import BrowserSupportMessage from '../../containers/BrowserSupportMessage';
import useStyles from './styles';

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auth.login);

  const onSubmit = values => {
    dispatch({ type: auth.login.start, payload: values });
  };

  return (
    <>
      <Helmet>
        <title>{title.public.login}</title>
      </Helmet>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container justify="center">
          <BrowserSupportMessage />
          <CenteredContainer>
            <LoginForm handleSubmit={onSubmit} loading={loading} />
          </CenteredContainer>
        </Grid>
      </Container>
    </>
  );
};

export default LoginPage;
