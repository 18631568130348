import { call, put, takeLatest } from 'redux-saga/effects';

import { notification, review } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* download({ payload: { companyId, sectionKey, fileId } }) {
  try {
    yield call(dataProvider.getOnly, `companies/${companyId}/related-document/${sectionKey}/download/${fileId}`, {});

    yield put({ type: review.downloadFile.end, payload: { fileId } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: review.downloadFile.error, payload: { fileId } });
  }
}

export default function* downloadFileSaga() {
  yield takeLatest(review.downloadFile.start, download);
}
