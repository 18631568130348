import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    get: createEnum(requestState, 'filePsmAudit.get'),
    remove: createEnum(requestState, 'filePsmAudit.remove'),
    add: createEnum(requestState, 'filePsmAudit.add'),
    download: createEnum(requestState, 'filePsmAudit.download'),
  },
  'filePsmAudit',
);
