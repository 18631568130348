import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'white',
  },
  list: {
    padding: theme.spacing(1, 0, 1, 1),
    margin: theme.spacing(0, 1),
  },
}));

export default useStyles;
