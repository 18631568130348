import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import { useParams, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import get from 'lodash.get';

import routes, { routeCreator } from '../../routes/path';
import CompanyNavigationBar from '../../containers/CompanyNavigationBar';
import Settings from './Settings';
import Company from './Company';
import PaperWrapper from '../../components/PaperWrapper';
import PageLoader from '../../components/PageLoader';
import { currentCompany } from '../../../actionTypes';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import useStyles from './styles';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const CompanyLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName, isBasicContractor } = useCompanyInfo(id);

  const userId = useSelector(state => state.auth.check.userId);
  const userCompanyId = useSelector(state => state.entity.user[userId].companyId);

  const { type } = useUserPermissions();
  const companyType = useSelector(state => get(state, `entity.company[${companyId}].typeName`, ''));

  const { keys, loading, loadingKeys, ownerAddendumList, pqfStatus } = useSelector(
    state => state.currentCompany.companyInfo,
  );

  useEffect(() => {
    dispatch({
      type: currentCompany.companyInfo.get.start,
      payload: { companyId },
    });
  }, [companyId, dispatch]);

  // check is own company
  const userCompany = `/company/${userCompanyId}`;

  const match = useRouteMatch(userCompany);
  const { pathname } = useLocation();
  const prefix = routes.private.settings;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  // redirect to settings if needed
  if (match) {
    return <Redirect to={pathname.replace(userCompany, prefix)} />;
  }

  const newRoutes = id ? routeCreator() : routeCreator(prefix);
  const {
    private: { company },
  } = newRoutes;

  if (loading || !companyType) return <PageLoader />;

  return (
    <Grid container className={classes.paperContent}>
      <Grid item className={classes.sidebarContainer}>
        <Paper>
          <CompanyNavigationBar
            ownerAddendum={ownerAddendumList}
            companyType={companyType}
            companyName={companyName}
            companyId={companyId}
            validKeys={keys}
            loadingKeys={loadingKeys}
            pqfStatus={pqfStatus && pqfStatus.currentPqfDocumentStatus}
          />
        </Paper>
      </Grid>
      <Grid item className={classes.containerPage}>
        <PaperWrapper>
          {id ? (
            <Company company={company} isBasicContractor={isBasicContractor} companyType={companyType} role={type} />
          ) : (
            <Settings company={company} isBasicContractor={isBasicContractor} role={type} />
          )}
        </PaperWrapper>
      </Grid>
    </Grid>
  );
};

export default CompanyLayout;
