import { useEffect } from 'react';

const Effect = ({ submitForm, values }) => {
  const { questionTitle, section, page, questionType, withScores, visibility } = values;

  useEffect(() => {
    submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTitle, section, page, questionType, withScores, visibility]);

  return '';
};

export default Effect;
