import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableTitle: {
    backgroundColor: theme.palette.grey[500],
  },
  columnTitle: {
    backgroundColor: theme.palette.grey[300],
  },
  tableBody: {
    backgroundColor: theme.palette.grey[50],
  },
  checkboxContainer: {
    margin: theme.spacing(0),
  },
  titleOffset: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
