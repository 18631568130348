import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FastField } from 'formik';

import { addendumFile } from '../../../../actionTypes';
import SingleFileUploaderInput from '../../../components/SingleFileUploaderInput';
import { fileAddendumUploadUrl } from '../../../../utils/constants';

const FileType = ({ name, label, readOnly, companyId, formBuilderFieldAnswerId }) => {
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch({
      type: addendumFile.downloadByFormBuilderFieldAnswerId.start,
      payload: {
        companyId,
        fileId: formBuilderFieldAnswerId,
      },
    });
  };

  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item>
        <FastField
          name={`${name}.fileId`}
          fileNameFieldName={`${name}.fileName`}
          component={SingleFileUploaderInput}
          label={label}
          companyId={companyId}
          handleDownload={handleDownload}
          uploadUrl={fileAddendumUploadUrl}
          disabled={readOnly}
          editable={!readOnly}
        />
      </Grid>
    </Grid>
  );
};

export default FileType;
