import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';

import { company as companySchema } from '../../schema';
import { company, entity, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';
import { formatPath } from '../../utils/textFormaters';
import routes from '../../ui/routes/path';
import { successMessages } from '../../store/constants';

export function* create({ payload }) {
  try {
    const { data } = yield call(dataProvider.createOne, 'Companies', payload);
    yield put({ type: company.create.end, payload: { state: true } });
    const normalizeData = normalize([{ ...data, name: data.companyName }], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });
    yield put({ type: notification.add, payload: { success: successMessages.companyCreate } });
    yield put(push(formatPath(routes.private.company.users, { id: data.id })));
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: company.create.error });
  }
}

export default function* createCompanySaga() {
  yield takeLatest(company.create.start, create);
}
