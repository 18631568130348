import { headerNotification } from '../../../actionTypes';
import { notificationStatus } from '../../../ui/containers/Header/Notifications/headerNotificationTypes';

const initialState = {
  items: [],
  uploadExpirationItems: [],
  sharingLoading: false,
  loading: false,
  loadingNotificationId: null,
  error: null,
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case headerNotification.list.start:
      return { ...state, loading: true };

    case headerNotification.list.end:
      return {
        ...state,
        loading: false,
        items: action.payload || [],
      };

    case headerNotification.list.error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    case headerNotification.createFollow.start:
      return {
        ...state,
        sharingLoading: true,
      };

    case headerNotification.createFollow.end:
      return {
        ...state,
        sharingLoading: false,
      };

    case headerNotification.createFollow.error:
      return {
        ...state,
        sharingLoading: false,
      };

    case headerNotification.createUnfollow.start:
      return {
        ...state,
        sharingLoading: true,
      };

    case headerNotification.createUnfollow.end:
      return {
        ...state,
        sharingLoading: false,
      };

    case headerNotification.createUnfollow.error:
      return {
        ...state,
        sharingLoading: false,
      };

    case headerNotification.acceptFollow.start:
      return {
        ...state,
        loading: true,
      };

    case headerNotification.acceptFollow.end: {
      return {
        ...state,
        loading: false,
        items: state.items.map(item =>
          item.id === action.payload.notificationId
            ? { ...item, userAction: notificationStatus.acceptFollowSuccess }
            : item,
        ),
      };
    }

    case headerNotification.rejectFollow.start:
      return {
        ...state,
        loading: true,
      };

    case headerNotification.rejectFollow.end: {
      return {
        ...state,
        loading: false,
        items: state.items.map(item =>
          item.id === action.payload.notificationId
            ? { ...item, userAction: notificationStatus.rejectFollowSuccess }
            : item,
        ),
      };
    }

    case headerNotification.uploadExpiration.start:
      return { ...state, loading: true };

    case headerNotification.uploadExpiration.end:
      return {
        ...state,
        loading: false,
        uploadExpirationItems: action.payload || [],
      };

    case headerNotification.uploadExpiration.error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        uploadExpirationItems: [],
      };

    case headerNotification.delete.start:
      return { ...state, loading: true, loadingNotificationId: action.payload.notificationId };

    case headerNotification.delete.end: {
      return {
        ...state,
        loading: false,
        loadingNotificationId: null,
        items: state.items.map(item =>
          item.id === action.payload.notificationId
            ? { ...item, userAction: notificationStatus.dismissedSuccess }
            : item,
        ),
      };
    }

    default:
      return state;
  }
}
