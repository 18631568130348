const isObject = (obj, key) => typeof obj[key] === 'object' && obj[key] !== null && Object.keys(obj[key]).length > 0;

export const sanitizePhoneNumbers = obj =>
  Object.keys(obj).reduce((acc, key) => {
    return isObject(obj, key)
      ? { ...acc, [key]: sanitizePhoneNumbers(obj[key]) }
      : {
          ...acc,
          [key]:
            ['phone', 'fax', 'phoneNumber'].includes(key) && obj[key] !== null ? obj[key].replace(/-/g, '') : obj[key],
        };
  }, {});
