import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

const ModalDialog = ({ isModalOpen, onConfirm, onCancel, title }) => {
  return (
    <Dialog open={isModalOpen} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={onConfirm} color="primary" variant="contained" size="small">
          Yes
        </Button>
        <Button onClick={onCancel} variant="contained" size="small" autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
