import { filePsmAudit } from '../../actionTypes';

const initialState = {
  list: [],
  downloadList: [],
  loading: false,
};

export default function fileList(state = initialState, action) {
  switch (action.type) {
    case filePsmAudit.get.start:
      return {
        ...state,
        list: [],
        loading: true,
      };
    case filePsmAudit.get.end:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case filePsmAudit.get.error:
      return {
        ...state,
        loading: false,
        list: [],
      };

    case filePsmAudit.download.start:
      return {
        ...state,
        downloadList: [...state.downloadList, action.payload.id],
      };

    case filePsmAudit.download.end:
      return {
        ...state,
        downloadList: state.downloadList.filter(id => id !== action.payload.id),
      };

    case filePsmAudit.download.error:
      return {
        ...state,
        downloadList: state.downloadList.filter(id => id !== action.payload.id),
      };

    case filePsmAudit.remove.end:
      return {
        ...state,
        loading: false,
        list: state.list.filter(file => action.payload.id !== file.id),
      };

    case filePsmAudit.add.end:
      return {
        ...state,
        loading: false,
        list: [...state.list, ...action.payload],
      };

    default:
      return state;
  }
}
