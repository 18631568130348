import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import Table from './TableContainer';
import FieldForm from './FieldFormContainer';

const TableContainer = ({
  loading,
  tableData,
  label,
  initialTableValues,
  validationSchema,
  fieldName,
  fieldNumber,
  maskType,
  fieldComponent,
  setTableData,
  allowUpdate,
  specialFilter,
  getYearsOptions,
}) => {
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (!loading) {
      setData(tableData);
      setTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const updateData = val => {
    setData(val);
    setTableData(val);
  };

  const onAddToTable = values => {
    updateData([
      ...data,
      {
        ...values,
        id: values.year,
      },
    ]);
  };

  const onDeleteConfirm = () => {
    updateData(data.filter(item => item.id !== itemToDelete));
    setItemToDelete(null);
  };

  const onEditConfirm = values => {
    const newData = data.map(item => (item.id === itemToEdit ? values : item));
    setItemToEdit(null);
    updateData(newData);
  };

  return (
    <Grid container direction="column">
      <Grid item lg={6} sm={8}>
        <FieldForm
          tableData={data}
          label={label}
          initialTableValues={initialTableValues}
          validationSchema={validationSchema}
          fieldName={fieldName}
          fieldNumber={fieldNumber}
          fieldMaskType={maskType}
          fieldComponent={fieldComponent}
          allowUpdate={allowUpdate}
          handleSubmit={onAddToTable}
          specialFilter={specialFilter}
          getYearsOptions={getYearsOptions}
        />
      </Grid>
      <Grid item lg={6} sm={8}>
        <Table
          tableData={data}
          allowUpdate={allowUpdate}
          onDeleteConfirm={onDeleteConfirm}
          setItemToDelete={setItemToDelete}
          onEditConfirm={onEditConfirm}
          loading={loading}
          itemToEdit={itemToEdit}
          itemToDelete={itemToDelete}
          setItemToEdit={setItemToEdit}
          label={label}
          validationSchema={validationSchema}
          amountFieldName={fieldName}
          amountFieldNumber={fieldNumber}
          amountFieldMaskType={maskType}
          amountFieldComponent={fieldComponent}
        />
      </Grid>
    </Grid>
  );
};

export default TableContainer;
