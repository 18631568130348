import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  formRadioContainer: {
    marginRight: theme.spacing(1),
    '& > h6': {
      marginRight: theme.spacing(4),
    },
  },
}));

export default useStyles;
