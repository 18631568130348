import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import { title } from '../../routes/path';
import WorkingHoursForm from '../../containers/WorkingHoursForm';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { currentCompany, workingHours } from '../../../actionTypes';
import { formatPageTitle } from '../../../utils/textFormaters';
import ContractorAddendumForm from '../../containers/ContractorAddendumForm';
import PaperWrapper from '../../components/PaperWrapper';
import { userTypes } from '../../../utils/constants';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const ContractorAddendum = () => {
  const { id, ownerId } = useParams();
  const { type } = useUserPermissions();

  const dispatch = useDispatch();
  const { companyId, companyName, level } = useCompanyInfo(id);
  const { loading, questions, answers, success, error } = useSelector(store => store.currentCompany.contractorAddendum);
  const { workingHoursSubmitEnabled } = useSelector(store => store.workingHours.list);

  useEffect(() => {
    dispatch({
      type: currentCompany.contractorAddendum.get.start,
      payload: { companyId, ownerId, contractorLevel: level },
    });

    dispatch({
      type: workingHours.list.start,
      payload: { contractorId: companyId, ownerId: ownerId },
    });
  }, [companyId, ownerId, dispatch, level]);

  const handleSave = values =>
    dispatch({
      type: currentCompany.contractorAddendum.create.start,
      payload: { companyId, ownerId, values },
    });

  const handleSaveWorkingHours = values => {
    dispatch({
      type: workingHours.update.start,
      payload: { contractorId: companyId, ownerId, values },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.ownerAddendum, { companyName })}</title>
      </Helmet>
      {workingHoursSubmitEnabled && type === userTypes.Contractor ? (
        <Box display="flex" gridGap={16} flexDirection="column" width="100%">
          {questions.length > 0 && (
            <PaperWrapper>
              <ContractorAddendumForm
                questions={questions}
                answers={answers}
                loading={loading}
                success={success}
                error={error}
                handleSave={handleSave}
                companyId={companyId}
              />
            </PaperWrapper>
          )}
          <PaperWrapper>
            <WorkingHoursForm contractorId={companyId} ownerId={ownerId} handleSave={handleSaveWorkingHours} />
          </PaperWrapper>
        </Box>
      ) : (
        <ContractorAddendumForm
          questions={questions}
          answers={answers}
          handleSave={handleSave}
          loading={loading}
          success={success}
          error={error}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default ContractorAddendum;
