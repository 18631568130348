import { push } from 'connected-react-router';
import { put, call, takeLatest } from 'redux-saga/effects';

import routes from '../../ui/routes/path';
import { auth, notification } from '../../actionTypes';
import authProvider from '../../store/authProvider';
import { successMessages } from '../../store/constants';

export function* setNewPassword({ payload: { values, token } }) {
  try {
    const loginToken = yield call(authProvider.resetPassword, {
      newPassword: values.password,
      token,
    });
    yield call(authProvider.saveTokensLogin, loginToken);
    yield put({ type: auth.setNewPassword.end });
    yield put(push(routes.private.index));
    yield put({ type: notification.add, payload: { success: successMessages.setNewPassword } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: auth.setNewPassword.end });
  }
}

export default function* setNewPasswordSaga() {
  yield takeLatest(auth.setNewPassword.start, setNewPassword);
}
