import { currentCompany } from '../../actionTypes';
import initialState from '../../utils/constants/yesNoQuestions';

export default function yesNoQuestions(state = initialState, action) {
  switch (action.type) {
    case currentCompany.yesNoQuestions.get.start:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          stack: [],
          disabler: null,
          loading: true,
          success: false,
          error: false,
        },
      };

    case currentCompany.yesNoQuestions.get.end:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          loading: false,
          stack: action.payload.stack,
          disabler: action.payload.disabler,
        },
      };

    case currentCompany.yesNoQuestions.update.start:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          loading: true,
        },
      };

    case currentCompany.yesNoQuestions.update.end:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          loading: false,
          stack: action.payload.stack,
          disabler: action.payload.disabler,
          success: true,
          error: false,
        },
      };

    case currentCompany.yesNoQuestions.update.error:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          loading: false,
          success: false,
          error: true,
        },
      };

    default:
      return state;
  }
}
