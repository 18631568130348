import { currentCompany } from '../../actionTypes';

const initialState = {
  total: 0,
  list: [],
  options: [],
  filterDefinitions: {},
  updateList: [],
  fullInfoLoading: false,
  loading: false,
  updating: false,
  success: false,
  error: false,
};

export default function questions(state = initialState, action) {
  switch (action.type) {
    case currentCompany.questions.get.start:
      return {
        ...state,
        loading: true,
        fullInfoLoading: action.payload.fullInfo,
        options: [],
      };
    case currentCompany.questions.get.end:
      return {
        ...state,
        loading: false,
        fullInfoLoading: false,
        total: action.payload.total,
        list: action.payload.list,
        filterDefinitions: action.payload.definitions || state.filterDefinitions,
        options: [...state.options, ...action.payload.options],
      };
    case currentCompany.questions.get.error:
      return {
        ...state,
        loading: false,
        filterLoading: false,
        total: 0,
        list: [],
        filterDefinitions: {},
      };

    case currentCompany.questions.update.start:
      return {
        ...state,
        updating: true,
        updateList: [...state.updateList, action.payload.questionId],
      };
    case currentCompany.questions.update.end:
      return {
        ...state,
        updating: false,
        list: state.list.map(item => (item.id === action.payload.questionId ? action.payload.question : item)),
        updateList: state.updateList.filter(id => id !== action.payload.questionId),
        success: true,
        error: false,
      };
    case currentCompany.questions.update.error:
      return {
        ...state,
        updateList: state.updateList.filter(id => id !== action.payload.questionId),
        updating: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
