import React from 'react';
import get from 'lodash.get';
import { SortableContainer } from 'react-sortable-hoc';
import { FieldArray, useFormikContext } from 'formik';
import { Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import arrayMove from 'array-move';

import OptionItem from '../OptionItem';

const SortableList = SortableContainer(({ arrayVal, name, arrayHelpers, handleRemove }) => (
  <Grid item xs={12}>
    <Grid container spacing={1}>
      {arrayVal.map((item, index) => (
        <OptionItem
          key={`option-${index}`}
          index={index}
          name={`${name}[${index}].text`}
          handleRemove={() => handleRemove(arrayHelpers, index)}
        />
      ))}
    </Grid>
  </Grid>
));

const OptionList = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();
  const arrayVal = get(values, name, {});

  const handleAdd = ({ push }) => {
    push({ text: '' });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updateListOptions = arrayMove(arrayVal, oldIndex, newIndex);
    setFieldValue(name, updateListOptions, false);
  };

  const handleRemove = ({ remove }, index) => remove(index);
  return (
    <Grid item xs={8}>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <Grid container spacing={2}>
            <SortableList
              useDragHandle
              arrayVal={arrayVal}
              name={name}
              onSortEnd={onSortEnd}
              arrayHelpers={arrayHelpers}
              handleRemove={handleRemove}
            />
            <Grid item xs={12}>
              <Fab color="primary" size="small" aria-label="Add question" onClick={() => handleAdd(arrayHelpers)}>
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
};

export default OptionList;
