// For uploading progress use XMLHttpRequest
// Fetch API cant add watchers for uploading status progress
import { API_AUTHORIZATION_URL } from '../../config/api';
import { reauthenticateIfNeeded } from './tokensHelper';
import { httpStatusCodes } from '../constants';

async function controlGetData(url, formData, id, statusUpdate, onError) {
  let res = await getData(url, formData, id, statusUpdate, onError);
  if (res.status === 401) {
    await reauthenticateIfNeeded({ apiAuthUrl: API_AUTHORIZATION_URL, permissionsUrl: '' }, res.status, {});
    res = await getData(url, formData, id, statusUpdate, onError, true);
  }

  if (res.status < httpStatusCodes.minValidCode || res.status >= httpStatusCodes.maxValidCode) {
    statusUpdate({ id, percent: 100, error: true });
    onError(res.error);
  }
  return res;
}

function getData(url, formData, id, statusUpdate, onError) {
  return new Promise(resolve => {
    let xhr = new XMLHttpRequest();
    const token = `Bearer ${localStorage.accessToken}`;

    // handle upload progress
    xhr.upload.onprogress = function(event) {
      const percent = Math.ceil((100 * event.loaded) / event.total);
      statusUpdate({ id, percent, error: false });
    };

    // handle upload error
    xhr.upload.onerror = function() {
      onError(xhr.statusText);
    };

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', token);
    xhr.send(formData);

    // handle response status
    xhr.onload = function() {
      if (this.status >= httpStatusCodes.minValidCode && this.status < httpStatusCodes.maxValidCode) {
        resolve({
          id,
          status: this.status,
          data: JSON.parse(xhr.response),
        });
      } else {
        resolve({
          id,
          data: {},
          status: this.status,
          error: xhr.statusText,
        });
      }
    };
  });
}

export const fileUploader = async (stackFiles, url, progressCallback, onError) => {
  const stackResults = {};

  for (let i = 0; i < stackFiles.length; i++) {
    const formData = new FormData();
    formData.append('file', stackFiles[i].file);
    formData.append('fileId', stackFiles[i].id);
    formData.append('fileName', stackFiles[i].file.name);

    stackResults[stackFiles[i].id] = await controlGetData(url, formData, stackFiles[i].id, progressCallback, onError);
  }

  return stackResults;
};
