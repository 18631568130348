import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: true,
  keys: [],
  validationFields: {},
  loadingKeys: [],
  ownerAddendumList: [],
  pqfStatus: {},
};

export default function companyInfo(state = initialState, action) {
  switch (action.type) {
    case currentCompany.companyInfo.get.start:
      return { ...state, loading: true };

    case currentCompany.companyInfo.get.end:
      return {
        ...state,
        loading: false,
        keys: action.payload.keys,
        ownerAddendumList: action.payload.addendum,
        pqfStatus: action.payload.status,
        validationFields: action.payload.validationFields,
      };

    case currentCompany.companyInfo.validation.start:
      return {
        ...state,
        loadingKeys: [...state.loadingKeys, action.payload.validationKey],
      };

    case currentCompany.companyInfo.validation.end:
      return {
        ...state,
        keys: action.payload.valid
          ? state.keys.filter(item => item !== action.payload.validationKey)
          : [...state.keys, action.payload.validationKey],
        loadingKeys: state.loadingKeys.filter(item => item !== action.payload.validationKey),
        validationFields: {
          ...state.validationFields,
          ...action.payload.validationFields,
        },
      };

    case currentCompany.companyInfo.updateConditions.update:
      return { ...state, pqfStatus: action.payload.status };

    default:
      return state;
  }
}
