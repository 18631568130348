import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    padding: theme.spacing(3),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey['300']}`,
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
