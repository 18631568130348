import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { auth, notification } from '../../actionTypes';
import authProvider from '../../store/authProvider';
import { formatPath } from '../../utils/textFormaters';
import routes from '../../ui/routes/path';
import { successMessages } from '../../store/constants';

export function* resetPassword({ payload }) {
  try {
    yield call(authProvider.forgotPassword, {
      ...payload,
      redirectPath: formatPath(routes.public.setNewPassword, { token: '' }),
    });
    yield put({ type: auth.resetPassword.end, payload: { state: true } });
    yield put(push(routes.public.login));
    yield put({ type: notification.add, payload: { success: successMessages.resetPassword } });
  } catch (e) {
    yield put({ type: auth.resetPassword.end, payload: { state: false } });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* resetPasswordSaga() {
  yield takeLatest(auth.resetPassword.start, resetPassword);
}
