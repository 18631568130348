import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField } from 'formik';

import ProgressBar from '../../../components/ProgressBar';
import FormInput from '../../../components/FormInput';
import DeleteFile from '../DeleteFile';
import useStyles from './styles';
import FormDatePicker from '../../../components/FormDataPicker';

const UploadItem = ({
  name,
  isError,
  expirationDate,
  defaultExpiration,
  completedOn,
  completedBy,
  formPrefix,
  status,
  handleRemove,
}) => {
  const classes = useStyles();

  const todaysDate = new Date();
  const day = todaysDate.getDate();
  const month = todaysDate.getMonth();
  const year = todaysDate.getUTCFullYear() + 1;
  const expiration = defaultExpiration ?? new Date(year, month, day);
  let useDefaultDate = defaultExpiration ? true : false;

  return (
    <div className={classes.fileForm}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography variant="subtitle1" noWrap>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ProgressBar value={status} isError={isError} />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <Typography variant="subtitle1">File description</Typography>
            </Grid>
            <Grid item xs={9}>
              <FastField name={`${formPrefix}.description`} component={FormInput} />
            </Grid>
          </Grid>
          {expirationDate && (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography variant="subtitle1">Expiration date</Typography>
              </Grid>
              <Grid item xs={9}>
                              <FastField name={`${formPrefix}.expirationDate`} component={FormDatePicker} defaultDate={expiration} useDefaultDate={useDefaultDate} />
              </Grid>
            </Grid>
          )}
          {completedBy && (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography variant="subtitle1">Completed by</Typography>
              </Grid>
              <Grid item xs={9}>
                <FastField name={`${formPrefix}.completedBy`} component={FormInput} />
              </Grid>
            </Grid>
          )}
          {completedOn && (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography variant="subtitle1">Completed on</Typography>
              </Grid>
              <Grid item xs={9}>
                <FastField name={`${formPrefix}.completedOn`} component={FormDatePicker} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={1}>
          <DeleteFile handleDelete={handleRemove} popupTitle="Are you sure you want to delete this item?" />
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadItem;
