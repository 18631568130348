import * as Yup from 'yup';

import messages from '../../../../utils/validate/errorMessages';
import { options, emptyValueFieldKeys } from '../../../../utils/enums/questions';
import optionTypes from '../../../../utils/enums/options';
import { dateCheck } from '../../../../utils/validate/method';

const loadedOptionsKey = 'FormBuilderField';

Yup.addMethod(Yup.mixed, 'dateCheck', dateCheck);

const types = {
  Text: () =>
    Yup.string().when('conditionType', {
      is: key => emptyValueFieldKeys.includes(key),
      then: schema => schema.notRequired(),
      otherwise: schema => schema.max(100, messages.requiredValueMax100).required(messages.required),
    }),
  Paragraph: () =>
    Yup.string().when('conditionType', {
      is: key => emptyValueFieldKeys.includes(key),
      then: schema => schema.notRequired(),
      otherwise: schema => schema.max(100, messages.requiredValueMax100).required(messages.required),
    }),
  YesNo: () => null,
  Checkbox: () => null,
  Number: () =>
    Yup.number().when('conditionType', {
      is: key => emptyValueFieldKeys.includes(key),
      then: schema => schema.notRequired(),
      otherwise: schema => schema.required(messages.required),
    }),
  Date: () =>
    Yup.mixed()
      .nullable()
      .when('conditionType', {
        is: key => emptyValueFieldKeys.includes(key),
        then: schema => schema.nullable().notRequired(),
        otherwise: schema => schema.dateCheck(messages.invalidDate).required(messages.required),
      }),
  Upload: () =>
    Yup.number().when('conditionType', {
      is: key => emptyValueFieldKeys.includes(key),
      then: schema => schema.notRequired(),
      otherwise: schema => schema.required(messages.required),
    }),
  Table: () =>
    Yup.number().when('conditionType', {
      is: key => emptyValueFieldKeys.includes(key),
      then: schema => schema.notRequired(),
      otherwise: schema => schema.required(messages.required),
    }),
  Dropdown: (dropdownType, loadedOptions) =>
    Yup.string().when('conditionType', {
      is: key => emptyValueFieldKeys.includes(key),
      then: schema => schema.notRequired(),
      otherwise: schema => {
        const optionList = dropdownType === loadedOptionsKey ? loadedOptions : optionTypes[dropdownType];
        return schema
          .oneOf(
            optionList.map(opt => `${opt.value}`),
            messages.valueIncorrect,
          )
          .required(messages.required);
      },
    }),
};

export default function(type, dropdownType, loadedOptions) {
  return Yup.object().shape({
    visibility: Yup.bool(),
    description: Yup.string()
      .nullable()
      .max(255, messages.requiredDescriptionMax255),
    companyRules: Yup.array().of(
      Yup.object().shape({
        conditionType: Yup.string()
          .oneOf(options[type].map(opt => opt.value))
          .required(messages.required),
        score: Yup.string().oneOf(options.Mark.map(opt => opt.value)),
        conditionValue: types[type](dropdownType, loadedOptions),
      }),
    ),
  });
}
