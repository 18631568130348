import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';

import { company as companySchema } from '../../schema';
import { company, entity, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';
import { successMessages } from '../../store/constants';

export function* deleteCompany({ payload: { companyId, payload } }) {
  try {
    yield call(dataProvider.deleteOne, `companies`, { id: companyId });
    const response = yield call(dataProvider.getList, 'Companies', payload);

    const { total, data } = response;
    // normalize data
    const normalizeData = normalize(data, [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    yield put({
      type: company.delete.end,
      payload: { list: normalizeData.result, total },
    });
    yield put({ type: notification.add, payload: { success: successMessages.companyDelete } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
  }
}

export default function* deleteCompanySaga() {
  yield takeLatest(company.delete.start, deleteCompany);
}
