import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialLink from '@material-ui/core/Link';

import PopupLink from './PopupLink';
import UploaderModal from './UploaderModal';
import FileList from './FileList';
import DocumentList from './DocumentList';
import Pagination from '../../components/Pagination';
import useStyles from './styles.js';

const FileUploader = ({
  uploaderTitle,
  url,
  error,
  hasPermissions,
  expirationDate,
  defaultExpiration,
  completedBy,
  completedOn,
  listType,
  cardTitle,
  maxFileSize,
  files,
  loading,
  handleDelete,
  handleDownload,
  deleteList,
  downloadList,
  handleSaveFiles,
  editable,
  single,
  disabled,
}) => {
  const classes = useStyles();

  const [popupOpen, setPopupOpen] = useState(false);
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1,
    perPage: 5,
  });

  const isDocumentList = listType === 'documentList';
  const List = isDocumentList ? DocumentList : FileList;
  const filesFiltered =
    listType === 'documentList' ? files.slice(pagination.offset, pagination.page * pagination.perPage) : files;

  const handleRemove = () => {
    handleSaveFiles([]);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {!single && (
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            {cardTitle && (
              <Typography align="center" variant="h6" gutterBottom>
                {cardTitle}
              </Typography>
            )}
            {(!isDocumentList || (isDocumentList && hasPermissions)) && (
              <PopupLink
                error={error}
                loading={loading}
                title={uploaderTitle}
                handleClick={() => setPopupOpen(true)}
                editable={editable}
                single={single}
                disabled={disabled}
              />
            )}
          </Grid>
        </Grid>
      )}

      {single && (
        <>
          <Grid item>
            <PopupLink
              error={error}
              title={uploaderTitle}
              handleClick={() => setPopupOpen(true)}
              editable={editable}
              single={single}
              disabled={disabled}
            />
          </Grid>
          {files.length > 0 && (
            <Grid item>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <MaterialLink className={classes.downloadText} onClick={handleDownload}>
                  {files[0].fileName}
                </MaterialLink>
              )}
              {editable && <IconButton color="secondary" onClick={handleRemove} size="small">
                <DeleteIcon color="error" fontSize="small" />
              </IconButton>}
            </Grid>
          )}
        </>
      )}

      {!single && files.length > 0 && (
        <Grid item xs={12}>
          <List
            files={filesFiltered}
            expirationDate={expirationDate}
            completedBy={completedBy}
            completedOn={completedOn}
            downloadList={downloadList}
            deleteList={deleteList}
            handleDownload={handleDownload}
            handleDelete={handleDelete}
            hasPermissions={hasPermissions}
            isDocumentList={isDocumentList}
            editable={editable}
          />
          {listType === 'documentList' && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item>
                  <Pagination
                    current={pagination.page}
                    itemsPerPage={pagination.perPage}
                    total={files.length}
                    handleClick={(e, offset) =>
                      setPagination(prevPagination => ({
                        ...prevPagination,
                        offset,
                        page: offset / prevPagination.perPage + 1,
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <UploaderModal
        url={url}
        maxFileSize={maxFileSize}
        state={popupOpen}
        isDocumentList={isDocumentList}
        expirationDate={expirationDate}
        defaultExpiration={defaultExpiration}
        completedBy={completedBy}
        completedOn={completedOn}
        single={single}
        handleClose={resetForm => {
          resetForm();
          setPopupOpen(false);
        }}
        handleSave={val => {
          handleSaveFiles(val);
          setPopupOpen(false);
        }}
      />
    </Grid>
  );
};

export default FileUploader;
