import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    create: createEnum(requestState, 'company.create'),
    list: createEnum(requestState, 'company.list'),
    delete: createEnum(requestState, 'company.delete'),
    addToFavorite: createEnum(requestState, 'company.addToFavorite'),
    export: createEnum(requestState, 'company.export'),
    getFavoriteOwnerList: createEnum(requestState, 'company.getFavoriteOwnerList'),
  },
  'company',
);
