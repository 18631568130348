import { auth } from '../../actionTypes';

const initialState = {
  state: false,
  loading: false,
};

export default function resetPassword(state = initialState, action) {
  switch (action.type) {
    case auth.resetPassword.start:
      return { ...state, loading: true };

    case auth.resetPassword.end:
      return {
        ...state,
        loading: false,
        state: action.payload ? action.payload.state : null,
      };

    default:
      return state;
  }
}
