import React from 'react';
import Grid from '@material-ui/core/Grid';

import CollapseCard from '../../../components/Cards/CollapseCard';
import DocumentList from '../../DocumentList';
import Pagination from '../../../components/Pagination';

const Documents = ({
  documents,
  filePqf,
  handleLoad,
  handleChangePage,
  handleDownloadScoreCard,
  listLoadingScorecard,
}) => {
  const { list, total, page, perPage, loading } = documents;
  return (
    <CollapseCard title="PQF">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DocumentList
            list={list}
            filePqf={filePqf}
            loading={loading}
            handleLoad={handleLoad}
            handleDownloadScoreCard={handleDownloadScoreCard}
            listLoadingScorecard={listLoadingScorecard}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <Pagination
                current={page}
                itemsPerPage={perPage}
                total={total}
                handleClick={(e, offset) => handleChangePage(offset)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CollapseCard>
  );
};

export default Documents;
