import { UsaStates } from 'usa-states';
import get from 'lodash.get';

const states = new UsaStates().states.map(state => {
  return { label: state.name, value: state.abbreviation };
});

export default states;

export const interStates = [{ label: 'Interstate', value: 'IS' }, ...states];

export const getStateNameByAbbreviation = abbreviation =>
  get(
    new UsaStates().states.find(state => state.abbreviation === abbreviation),
    'name',
    '',
  );
