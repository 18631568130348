import { company } from '../../actionTypes';

const initialState = {
  items: [],
  services: [],
  workCategories: [],
  total: 0,
  loading: false,
  exporting: false,
  fullInfoLoading: false,
  error: null,
  loadingListAddToFavorite: [],
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case company.list.start:
      return { ...state, loading: true, fullInfoLoading: action.payload.fullInfo };

    case company.list.end:
      return {
        ...state,
        loading: false,
        fullInfoLoading: false,
        total: action.payload.total,
        items: action.payload.list,
        services: action.payload.services || state.services,
        workCategories: action.payload.workCategories || state.workCategories,
      };

    case company.list.error:
      return {
        ...state,
        error: action.payload.error,
      };

    case company.delete.start:
      return { ...state, loading: true };

    case company.delete.end:
      return {
        ...state,
        loading: false,
        total: action.payload.total,
        items: action.payload.list,
      };

    case company.delete.error:
      return {
        ...state,
        error: action.payload.error,
      };

    case company.addToFavorite.start:
      return {
        ...state,
        loadingListAddToFavorite: [...state.loadingListAddToFavorite, action.payload.id],
      };

    case company.addToFavorite.end:
      return {
        ...state,
        loadingListAddToFavorite: state.loadingListAddToFavorite.filter(item => item !== action.payload.id),
      };

    case company.addToFavorite.error:
      return {
        ...state,
        loadingListAddToFavorite: state.loadingListAddToFavorite.filter(item => item !== action.payload.id),
      };

    case company.export.start:
      return {
        ...state,
        exporting: true,
      };

    case company.export.end:
      return {
        ...state,
        exporting: false,
      };

    case company.export.error:
      return {
        ...state,
        exporting: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
