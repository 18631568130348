import React from 'react';
import { Grid } from '@material-ui/core';
// used for admin card
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CompanyCard from './CompanyCard';
import PageLoader from '../../components/PageLoader';
import Organization from './Organization';
import Notification from './Notification';
import Documents from './Documents';
import Services from './Services';
import Addendum from './Addendum';
import UploadDocuments from './UploadDocuments';
import WorkCategories from './WorkCategories';
import UploadPsmAuditForm from '../UploadPsmAuditForm';
// used for admin card
import AdminPanelCard from './AdminPanelCard';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const DashboardWrapper = ({
  loading,
  ownerGradingSystem,
  currentOwnerGrade,
  generalInfo,
  addendum,
  grade,
  filePqf,
  notification,
  handleSubmitDocument,
  handlePsmSubmit,
  handleAdminPanelCard,
  psmAuditList,
  company,
  hasPermissions,
  widgets,
  documents,
  handleLoad,
  loadingListAddToFavorite,
  handleAddToFavorite,
  handleChangePage,
  workCategoriesValues,
  allowedCategories,
  categories,
  groups,
  uploadDocuments,
  handleDownloadScoreCard,
  listLoadingScorecard,
  userCompanyId,

  // need to get the type for this to really work
  // type,
}) => {
  // Start of Admin Card logic
  const { id } = useParams();
  const { companyId } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { companyAdminInfo, adminLoading, updating, success, error } = useSelector(
    state => state.currentCompany.companyAdminInfo,
  );

  const { requestInProgress } = useSelector(store => store.currentCompany.info);

  // const handleSubmit = value =>
  //   dispatch({
  //     type: currentCompany.companyAdminInfo.update.start,
  //     payload: { value, companyId },
  //   });

  const { type } = useUserPermissions();

  //end admin card logic

  if (loading) return <PageLoader />;

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        {company && (
          <CompanyCard
            company={company}
            grade={grade}
            currentOwnerGrade={currentOwnerGrade}
            ownerGradingSystem={ownerGradingSystem}
            generalInfo={generalInfo}
            handleAddToFavorite={handleAddToFavorite}
            loadingListAddToFavorite={loadingListAddToFavorite}
            updatingImage={requestInProgress}
            userCompanyId={userCompanyId}
          />
        )}
      </Grid>

      <Notification
        {...notification}
        companyType={type}
        handleSubmitDocument={() => handleSubmitDocument(company.currentPqfDocumentId)}
      />

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={3}>
            {widgets.organizationInfo && <Organization info={widgets.organizationInfo} />}

            {(hasPermissions || type === 'Owner') && (
              <AdminPanelCard
                initialValues={companyAdminInfo}
                allowUpdate={allowUpdate}
                loading={adminLoading}
                updating={updating}
                error={error}
                success={success}
                handleSubmit={handleAdminPanelCard}
                isOwner={type === 'Owner'}
                isPSM={company.companyLevel === 'PsmContractor'}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Documents
              documents={documents}
              filePqf={filePqf}
              handleLoad={handleLoad}
              handleChangePage={handleChangePage}
              handleDownloadScoreCard={handleDownloadScoreCard}
              listLoadingScorecard={listLoadingScorecard}
            />
            <UploadPsmAuditForm
              psmAuditList={psmAuditList}
              handlePsmSubmit={handlePsmSubmit}
              hasPermissions={hasPermissions}
              companyId={company && company.id}
            />
            <UploadDocuments uploadDocuments={uploadDocuments} />
          </Grid>
          <Grid item xs={3}>
            {widgets.services && <Services services={widgets.services} />}
            <WorkCategories
              workCategoriesValues={workCategoriesValues}
              allowedCategories={allowedCategories}
              categories={categories}
              groups={groups}
            />
            <Addendum addendum={addendum} companyId={company && company.id} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardWrapper;
