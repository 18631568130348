import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ payload: { companyId, sectionKey, param } }) {
  try {
    const [questions, profile] = yield all([
      call(dataProvider.getList, `companies/${companyId}/yes-no-questions/${sectionKey}`, param),
      call(dataProvider.getOnly, `companies/${companyId}/health-prof`),
    ]);

    const { uploads, ...questionsData } = questions.data;
    const { uploadValues } = uploadHelpers(uploads);

    const updateYesNoAnswer = questionsData.data.map(item =>
      Object.keys(uploadValues).includes(item.key) ? { ...item, answer: uploadValues[item.key] } : item,
    );

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.healthProf.get.end,
      payload: { healthProfile: profile.data, questions: updateYesNoAnswer },
    });
  } catch (e) {
    yield put({ type: currentCompany.healthProf.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* gethealthProfSaga() {
  yield takeLatest(currentCompany.healthProf.get.start, get);
}
