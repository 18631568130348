import React, { useState } from 'react';
import { TableRow, TableCell, Grid, CircularProgress, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';

import IconWithPopUp from '../../../../components/IconWithPopUp';
import Ellipsis from '../../../../components/Ellipsis';
import { useUserPermissions } from '../../../../../utils/effects/userPermissions.js';
import formatDate from '../../../../../utils/textFormaters/date.js';
import { userTypes } from '../../../../../utils/constants';
import EditUserForm from './EditUserForm';
import TooltipWrapper from '../../../../components/TooltipWrapper';

const Row = ({ values, updatingUserIds, handleUpdateUser, handleResendInvitation, handleDeleteUser }) => {
  const { type } = useUserPermissions();
  const isSafetyCouncilUserType = type === userTypes.SafetyCouncil;

  const { id, firstName, lastName, emailAddress, createDate, lastLoginDate } = values;

  const [isUserToEdit, setUserToEdit] = useState(false);

  const onConfirmEditUser = values => {
    handleUpdateUser(values);
    setUserToEdit(false);
  };

  if (updatingUserIds.includes(id)) {
    return (
      <TableRow>
        <TableCell align="center" colSpan={12}>
          <CircularProgress size={26} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      {isUserToEdit ? (
        <EditUserForm values={values} setUserToEdit={setUserToEdit} onConfirmEditUser={onConfirmEditUser} />
      ) : (
        <>
          <TableCell component="th" scope="row" align="left">
            <Ellipsis title={firstName} />
          </TableCell>
          <TableCell align="left">
            <Ellipsis title={lastName} />
          </TableCell>
          <TableCell align="left">
            <Ellipsis title={emailAddress} />
          </TableCell>
          <TableCell align="left">{formatDate(createDate)}</TableCell>
          {isSafetyCouncilUserType && (
            <TableCell align="left">
              {lastLoginDate ? (
                formatDate(lastLoginDate)
              ) : (
                <Typography gutterBottom variant="body1">
                  Not logged in
                </Typography>
              )}
            </TableCell>
          )}
          {isSafetyCouncilUserType && (
            <TableCell align="center">
              <Grid container justify="space-between">
                <TooltipWrapper tooltip title="Edit">
                  <IconButton size="small" aria-label="edit" color="primary" onClick={() => setUserToEdit(true)}>
                    <EditIcon />
                  </IconButton>
                </TooltipWrapper>
                <IconWithPopUp
                  title="Are you sure you want to delete this user?"
                  handler={handleDeleteUser}
                  tooltipTitle="Delete"
                >
                  <DeleteIcon color="error" />
                </IconWithPopUp>
                <IconWithPopUp
                  title="Are you sure you want to resend invitation email?"
                  handler={handleResendInvitation}
                  tooltipTitle={lastLoginDate ? 'This user has already accepted the invitation' : 'Resend Invite'}
                  disabled={lastLoginDate}
                >
                  <SendIcon color={lastLoginDate ? 'disabled' : 'primary'} />
                </IconWithPopUp>
              </Grid>
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

export default Row;
