import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import useStyles from './styles';

const FavoriteButton = ({ id, isFavourite, loadingListAddToFavorite, handleAddToFavorite }) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  const onConfirm = () => {
    setModalOpen(false);
    handleAddToFavorite(id, !isFavourite);
  };

  const onCancel = () => setModalOpen(false);

  if (loadingListAddToFavorite) return <CircularProgress fontSize="large" />;

  return (
    <>
      <Tooltip title={isFavourite ? 'Remove from favorite' : 'Add to favorite'}>
        <IconButton size="small" onClick={() => setModalOpen(true)}>
          {isFavourite ? (
            <StarIcon className={classes.favoriteIcon} />
          ) : (
            <StarBorderIcon className={classes.notFavoriteIcon} />
          )}
        </IconButton>
      </Tooltip>
      <Dialog open={modalOpen} onClose={onCancel}>
        <DialogTitle>Do you really want to {isFavourite && 'un'}follow this company?</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={onConfirm} color="primary" variant="contained" size="small">
            Yes
          </Button>
          <Button onClick={onCancel} variant="contained" size="small" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FavoriteButton;
