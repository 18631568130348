import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { title, sectionKey } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import CraftsmanCertificationForm from '../../containers/CraftsmanCertificationForm';
import { currentCompany } from '../../../actionTypes';
import { modifyArrayFieldsEmptyStringToNull } from '../../containers/CraftsmanCertificationForm/modifyCraftsman';
import { changeNAtoNotAvailable } from '../../../utils/containersHelpers';

const CraftsmanCertification = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { state, loading, success, error } = useSelector(state => state.currentCompany.craftsman);

  useEffect(() => {
    dispatch({ type: currentCompany.craftsman.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleSubmit = values => {
    const formattedValues = changeNAtoNotAvailable(modifyArrayFieldsEmptyStringToNull(values));
    dispatch({
      type: currentCompany.craftsman.update.start,
      payload: {
        companyId,
        values: formattedValues,
        sectionKey: sectionKey.private.company.craftsmanCertification,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.craftsmanCertification, { companyName })} </title>
      </Helmet>
      <CraftsmanCertificationForm
        handleSubmit={handleSubmit}
        initialValues={state}
        loading={loading}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default CraftsmanCertification;
