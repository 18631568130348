import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    get: createEnum(requestState, 'review.get'),
    update: createEnum(requestState, 'review.update'),
    save: createEnum(requestState, 'review.save'),
    downloadFile: createEnum(requestState, 'review.downloadFile'),
  },
  'review',
);
