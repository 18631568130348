import { call, put, takeLatest } from 'redux-saga/effects';

import { pages, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, fileId } }) {
  try {
    yield call(dataProvider.getOnly, `companies/${companyId}/pqf/${fileId}/download`, {});
    yield put({
      type: pages.dashboard.loadDocument.end,
      payload: { fileId },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({
      type: pages.dashboard.loadDocument.error,
      payload: { fileId },
    });
  }
}

export default function* loadDocumentSaga() {
  yield takeLatest(pages.dashboard.loadDocument.start, get);
}
