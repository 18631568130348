import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { title } from '../../routes/path';
import { currentCompany } from '../../../actionTypes';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import FormBuilder from '../../containers/FormBuilder';
import { contractorLevelPathEnum, contractorLevels } from '../../../utils/constants';

const OwnerAddendum = () => {
  const { id, contractorLevel: contractorLevelPath } = useParams();
  const dispatch = useDispatch();
  const { companyId, companyName } = useCompanyInfo(id);

  const { loading, saving, success, error, list } = useSelector(store => store.currentCompany.ownerAddendum);
  const contractorLevel =
    Object.keys(contractorLevelPathEnum).filter(item => contractorLevelPathEnum[item] === contractorLevelPath)[0] ||
    null;

  useEffect(() => {
    dispatch({
      type: currentCompany.ownerAddendum.get.start,
      payload: { companyId, contractorLevel },
    });
  }, [companyId, contractorLevel, dispatch]);

  const handleSave = values =>
    dispatch({
      type: currentCompany.ownerAddendum.create.start,
      payload: { companyId, contractorLevel, values },
    });

  return (
    <>
      <Helmet>
        <title>
          {formatPageTitle(title.private.company.ownerAddendum, {
            companyName,
            contractorLevel: contractorLevels[contractorLevel],
          })}
        </title>
      </Helmet>
      <FormBuilder
        loading={loading}
        saving={saving}
        success={success}
        error={error}
        list={list}
        handleSave={handleSave}
        companyId={companyId}
      />
    </>
  );
};

export default OwnerAddendum;
