import { workingHours } from '../../actionTypes';

const initialState = {
  workingHoursSubmitEnabled: false,
  workingHours: [],
  loading: false,
  exporting: false,
  canExportWorkingHours: false,
  error: null,
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case workingHours.list.start:
      return { ...state, loading: true };

    case workingHours.list.end:
      return {
        ...state,

        loading: false,
        workingHours: action.payload.workingHours,
        workingHoursSubmitEnabled: action.payload.workingHoursSubmitEnabled,
      };

    case workingHours.list.error:
      return {
        ...state,
        error: action.payload.error,
      };

    case workingHours.update.start:
      return { ...state, loading: true };

    case workingHours.update.end:
      return {
        ...state,
        loading: false,
        workingHours: action.payload.workingHours,
        success: true,
        error: false,
      };

    case workingHours.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case workingHours.exportWorkingHours.start:
      return { ...state, exporting: true };

    case workingHours.exportWorkingHours.end:
      return {
        ...state,
        exporting: false,
      };

    case workingHours.exportWorkingHours.error:
      return {
        ...state,
        exporting: false,
        error: action.payload.error,
      };

    case workingHours.canExportWorkingHours.end:
      return {
        ...state,
        canExportWorkingHours: action.payload,
      };

    case workingHours.canExportWorkingHours.error:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
