import React, { useState } from 'react';
import { Typography, Box, IconButton, Popover } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const PopoverPopup = ({ content }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="secondary" onClick={handleOpenPopover}>
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography variant="body1">{content}</Typography>
        </Box>
      </Popover>
    </>
  );
};

export default PopoverPopup;
