import createEnum from '../actionTypesHelpers/createEnum';

function createOptionsEnum(obj) {
  return Object.keys(obj).reduce((acc, key) => [...acc, { value: key, label: obj[key] }], []);
}

export const mark = createEnum({
  '0': null,
  '1': null,
  '2': null,
  '3': null,
  '4': null,
  '5': null,
  '6': null,
  '7': null,
  '8': null,
  '9': null,
  '10': null,
  F: null,
});

export const text = createEnum({
  IsFilledOut: 'Is filled out',
  IsNotFilledOut: 'Is not filled out',
  IsEqualTo: 'Is equal to',
  IsNotEqualTo: 'Is not equal to',
});

export const checkbox = createEnum({
  IsFilledOut: 'Is Checked',
  IsNotFilledOut: 'Is not Checked',
});

export const yesNo = createEnum({
  IsYes: 'Is Yes',
  IsNo: 'Is No',
  IsNA: 'Is N/A',
  IsFilledOut: 'Is filled out',
  IsNotFilledOut: 'Is not filled out',
});

export const number = createEnum({
  IsFilledOut: 'Is filled out',
  IsNotFilledOut: 'Is not filled out',
  IsEqualTo: 'Is equal to',
  IsNotEqualTo: 'Is not equal to',
  IsGreaterThan: 'Is greater than',
  IsLessThan: 'Is less than',
});

export const date = createEnum({
  IsFilledOut: 'Is filled out',
  IsNotFilledOut: 'Is not filled out',
  IsEqualTo: 'Is equal to',
  IsNotEqualTo: 'Is not equal to',
  IsBefore: 'Is before',
  IsAfter: 'Is after',
  IsBeforeToday: 'Is before today (update every day)',
  IsAfterToday: 'Is after today (update every day)',
});

export const upload = createEnum({
  HasUploadedFiles: 'Has uploaded files',
  NoFilesUploaded: 'No files uploaded',
  NumberOfFilesIs: 'Number of files is',
});

export const table = createEnum({
  HasRecords: 'Has records',
  DoesNotHaveRecords: 'Does not have records',
  NumberOfRecordsIs: 'Number of records is',
});

export const dropdown = createEnum({
  IsFilledOut: 'Is filled out',
  IsNotFilledOut: 'Is not filled out',
  IsEqualTo: 'Is equal to',
  IsNotEqualTo: 'Is not equal to',
});

export const options = {
  None: [],
  Mark: createOptionsEnum(mark),
  Text: createOptionsEnum(text),
  Paragraph: createOptionsEnum(text),
  Checkbox: createOptionsEnum(checkbox),
  YesNo: createOptionsEnum(yesNo),
  Number: createOptionsEnum(number),
  Date: createOptionsEnum(date),
  Upload: createOptionsEnum(upload),
  Table: createOptionsEnum(table),
  Dropdown: createOptionsEnum(dropdown),
};

export const emptyValueFieldKeys = [
  'IsYes',
  'IsNo',
  'IsNA',
  'IsFilledOut',
  'IsNotFilledOut',
  'IsBeforeToday',
  'IsAfterToday',
  'HasRecords',
  'DoesNotHaveRecords',
  'HasUploadedFiles',
  'NoFilesUploaded',
];

export const QuestionType = createEnum({
  Text: 'Text field',
  Paragraph: 'Paragraph',
  YesNo: 'Yes/No',
  Checkbox: 'Checkbox',
  Number: 'Number field',
  Dropdown: 'Dropdown',
  Date: 'Date',
  Upload: 'Upload',
  Table: 'Table',
});

export const QuestionTypesObject = Object.keys(QuestionType).map(key => ({
  [key]: key,
}));

export const optionQuestionTypes = Object.keys(QuestionType).map(key => ({
  label: QuestionType[key],
  value: key,
}));

export const scores = createEnum({
  All: 'All',
  WithScores: 'With Score',
  WithoutScores: 'Without Score',
});

export const scoreTypes = Object.keys(scores).map(key => ({
  label: scores[key],
  value: key,
}));

export const visibilityTypes = createEnum({
  All: 'All',
  true: 'Visible',
  false: 'Hidden',
});

export const visibilityOptions = Object.keys(visibilityTypes).map(key => ({
  label: visibilityTypes[key],
  value: key,
}));
