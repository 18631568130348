import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    dashboard: {
      get: createEnum(requestState, 'pages.dashboard.get'),
      submitDocument: createEnum(requestState, 'pages.dashboard.submitDocument'),
      documents: createEnum(requestState, 'pages.dashboard.documents'),
      loadDocument: createEnum(requestState, 'pages.dashboard.loadDocument'),
      gradeDownload: createEnum(requestState, 'pages.dashboard.gradeDownload'),
      updateCondition: createEnum({ update: null }, 'pages.dashboard.updateCondition'),
      unofficialGrade: createEnum(requestState, 'pages.dashboard.unofficialGrade'),
      loadAllUploadDocument: createEnum(requestState, 'pages.dashboard.loadAllUploadDocument'),
      downloadPQFScorecard: createEnum(requestState, 'pages.dashboard.downloadPQFScorecard'),
      getOwnerGrading: createEnum(requestState, 'pages.dashboard.getOwnerGrading'),
      downloadOwnerScorecard: createEnum(requestState, 'pages.dashboard.downloadOwnerScorecard'),
    },
  },
  'pages',
);
