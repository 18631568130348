import React from 'react';
import { FastField } from 'formik';
import { Grid } from '@material-ui/core';

import FormSelect from '../../../components/FormSelect';

const FrequencyFieldType = ({ name, label, options }) => {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item lg={12}>
        <FastField name={name} label={label} component={FormSelect} options={options} />
      </Grid>
    </Grid>
  );
};

export default FrequencyFieldType;
