import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ payload: { companyId } }) {
  try {
    const [emrInfoData, emrData, naicsCodes] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/emr-info`),
      call(dataProvider.getList, `companies/${companyId}/emr`, {}),
      call(dataProvider.getList, 'definitions/naics'),
    ]);

    const { uploads, ...infoData } = emrInfoData.data;
    const { uploadKeys, uploadValues } = uploadHelpers(uploads);

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.emrate.get.end,
      payload: {
        emrInfo: { ...infoData, ...uploadValues, uploadKeys },
        emrArrays: emrData.data.data,
        naicsCodes: naicsCodes.data.data,
      },
    });
  } catch (e) {
    yield put({ type: currentCompany.emrate.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getEmrSaga() {
  yield takeLatest(currentCompany.emrate.get.start, get);
}
