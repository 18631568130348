import workCategory from './workCategory';
import service from './service';
import finInfo from './finInfo';
import user from './user';
import reference from './reference';
import parentCompany from './parentCompany';
import organizationInfo from './organizationInfo';
import officer from './officer';
import yesNoQuestions from './yesNoQuestions';
import healthProf from './healthProf';
import insurancePolicy from './insurancePolicy';
import companyInfo from './companyInfo';
import companyAdminInfo from './companyAdminInfo';
import info from './info';
import emrate from './emrate';
import ownerAddendum from './ownerAddendum';
import injuryIllness from './injuryIllness';
import craftsmanCertification from './craftsmanCertification';
import contractorAddendum from './contractorAddendum';
import questions from './questions';
import settings from './settings';

export default [
  ...workCategory,
  ...service,
  ...finInfo,
  ...user,
  ...reference,
  ...parentCompany,
  ...organizationInfo,
  ...officer,
  ...yesNoQuestions,
  ...healthProf,
  ...insurancePolicy,
  ...companyInfo,
  ...companyAdminInfo,
  ...info,
  ...emrate,
  ...ownerAddendum,
  ...contractorAddendum,
  ...injuryIllness,
  ...craftsmanCertification,
  ...questions,
  ...settings,
];
