import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  completed: {
    color: theme.palette.success.main,
  },
  inProgress: {
    color: theme.palette.warning.main,
  },
  rejected: {
    color: theme.palette.error.main,
    cursor: 'pointer',
    maxWidth: 'fit-content',
  },
  pendingReview: {
    color: theme.palette.info.light,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey['500']}`,
  },
  tooltipArrow: {
    color: theme.palette.grey['500'],
  },
  reviewPqf: {
    fontSize: theme.typography.caption.fontSize,
  },
  successPQF: {
    color: theme.palette.success.main,
  },
  rejectPQF: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
