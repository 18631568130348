import React, { useState } from 'react';
import { Formik, FastField, Field } from 'formik';
import { Grid, Collapse } from '@material-ui/core';
import debounce from 'lodash.debounce';

import FormInput from '../../../components/FormInput';
import FormAutocomplete from '../../../components/FormAutocomplete';
import ShowMoreLessFilters from '../../../components/ShowMoreLessFilters';
import { scoreTypes, visibilityOptions, optionQuestionTypes } from '../../../../utils/enums/questions';
import { clearFilterValues, anyFilters, isEmptyFilterForm } from '../../../../utils/containersHelpers';
import { filterQuestions } from '../../../../utils/validate/shemas';
import Effect from './Effect';
import useStyles from './styles';

const addSpaceBetweenWords = str => str.replace(/(([A-Z]+)|([0-9]+))/g, ' $1').trim();

const createList = values => values.map(value => ({ label: addSpaceBetweenWords(value), value }));

const getRelatedSections = (sections, page) => {
  return Object.keys(sections).filter(key => page === sections[key]);
};

const getAvailableSections = (selectedPage, sections) => {
  return selectedPage ? getRelatedSections(sections, selectedPage) : Object.keys(sections);
};

const getAvailablePages = (selectedSection, sections, pages) => {
  return selectedSection ? [sections[selectedSection]] : pages;
};

const FiltersForm = ({ initialFilterDefinitions, initialValues, handleOnChange, selectedPage, selectedSection }) => {
  const classes = useStyles();

  const { questionTitle, ...hiddenValues } = initialValues;

  const checkOpenMore = anyFilters(hiddenValues);
  const clearValues = clearFilterValues(initialValues);
  const isEmptyForm = isEmptyFilterForm(initialValues);

  const [moreFilter, setMoreFilter] = useState(checkOpenMore);

  const { pages, sections } = initialFilterDefinitions;

  const sectionsList = sections ? createList(getAvailableSections(selectedPage, sections)) : [];

  const pagesList = sections && pages ? createList(getAvailablePages(selectedSection, sections, pages)) : [];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={filterQuestions}
      onSubmit={debounce(handleOnChange, 1000)}
      validateOnChange={false}
      enableReinitialize
    >
      {({ submitForm, values, setValues }) => (
        <Grid container>
          <Effect values={values} submitForm={submitForm} />
          <Grid item xs={5} className={classes.gutterBottom}>
            <Grid container alignItems="center" justify="space-between" spacing={2}>
              <Grid item xs={7}>
                <FastField placeholder="Search question" name="questionTitle" type="search" component={FormInput} />
              </Grid>
              <Grid item xs={5}>
                <ShowMoreLessFilters
                  moreFilter={moreFilter}
                  setMoreFilter={setMoreFilter}
                  isEmptyForm={isEmptyForm}
                  clearFilterValues={() => setValues(clearValues)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Collapse in={moreFilter} component={Grid} item xs={12}>
            <Grid container>
              <Grid item xs={5} className={classes.filterSubContainer}>
                <FastField
                  className={classes.inputRow}
                  label="Question type"
                  name="questionType"
                  options={optionQuestionTypes}
                  component={FormAutocomplete}
                />
                <FastField
                  className={classes.inputRow}
                  label="With/without score"
                  name="withScores"
                  options={scoreTypes}
                  component={FormAutocomplete}
                />
                <FastField
                  className={classes.inputRow}
                  label="Visibility"
                  name="visibility"
                  options={visibilityOptions}
                  component={FormAutocomplete}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  className={classes.inputRow}
                  label="Page"
                  name="page"
                  options={pagesList}
                  component={FormAutocomplete}
                />
                <Field
                  className={classes.inputRow}
                  label="Section"
                  name="section"
                  options={sectionsList}
                  component={FormAutocomplete}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )}
    </Formik>
  );
};

export default FiltersForm;
