import { createEnum } from '../actionTypesHelpers';
import { contractorLevels } from './index';

export const optionTypes = createEnum({
  Owner: null,
  ...contractorLevels,
});

export const companyRolesSelection = createEnum({
  All: 'All',
  Owner: null,
  ...contractorLevels,
});

export const companyRolesSelectionOptions = Object.keys(companyRolesSelection).map(key => ({
  label: companyRolesSelection[key],
  value: key,
}));

const typesEnum = createEnum(optionTypes);
export default Object.keys(typesEnum).map(key => ({
  label: typesEnum[key],
  value: key,
}));
