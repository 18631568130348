import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import { Paper, Typography, Grid, Button, CircularProgress, Backdrop, Link as MaterialLink } from '@material-ui/core';

import { signInSchema } from '../../../utils/validate/shemas';
import FormInput from '../../components/FormInput';
import routes from '../../routes/path';
import useStyles from './styles';

const LoginForm = ({ loading, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Backdrop timeout={300} open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={signInSchema}
        onSubmit={handleSubmit}
      >
        {props => (
          <form onSubmit={props.handleSubmit} noValidate>
            <Typography align="center" variant="h3" gutterBottom>
              Sign In
            </Typography>
            <FastField className={classes.inputRow} label="Email" name="email" type="email" component={FormInput} />
            <FastField
              className={classes.inputRow}
              type="password"
              label="Password"
              name="password"
              component={FormInput}
            />
            <div className={classes.inputRow}>
              <Grid container alignItems="center">
                <Grid item sm={8}>
                  <MaterialLink component={Link} to={routes.public.resetPassword}>
                    Forgot your password?
                  </MaterialLink>
                </Grid>
                <Grid item sm={4}>
                  <Button size="large" fullWidth variant="contained" color="primary" type="submit">
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default LoginForm;
