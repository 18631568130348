import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ payload: { companyId } }) {
  try {
    const { data } = yield call(dataProvider.getList, `companies/${companyId}/injuries`, {});

    const { uploads, ...injury } = data;
    const { uploadKeys, uploadValues } = uploadHelpers(uploads);

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.injuryIllness.get.end,
      payload: { stack: { injury: injury.list, ...uploadValues, uploadKeys } },
    });
  } catch (e) {
    yield put({
      type: currentCompany.injuryIllness.get.end,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getInjuryIllnessSaga() {
  yield takeLatest(currentCompany.injuryIllness.get.start, get);
}
