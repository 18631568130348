import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, payload, fullInfo } }) {
  try {
    const [questions, definitions, options] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/grade/fields-with-rules`, payload),
      fullInfo && call(dataProvider.getList, `Definitions/grading`, {}),
      call(dataProvider.getList, `companies/${companyId}/grade/dropdown-options`, {}),
    ]);

    yield put({
      type: currentCompany.questions.get.end,
      payload: {
        list: questions.data.data,
        definitions: definitions && definitions.data,
        total: questions.data.totalCount,
        options: options.data,
      },
    });
  } catch (e) {
    yield put({ type: currentCompany.questions.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getQuestionSaga() {
  yield takeLatest(currentCompany.questions.get.start, get);
}
