import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormAutocomplete = ({
  size = 'small',
  label,
  options,
  handleChange,
  field: { ...fieldProps },
  form: { setFieldValue },
  withDescription,
  ...props
}) => {
  const { name, value } = fieldProps;
  const defaultValue = options.find(item => item.value === value) || null;

  return (
    <Autocomplete
      {...props}
      clearOnEscape
      options={options}
      value={defaultValue}
      getOptionLabel={option => (withDescription ? `${option.value} - ${option.label}` : option.label)}
      renderInput={params => <TextField {...params} variant="outlined" size={size} fullWidth label={label} />}
      onChange={(e, newValue) => {
        const value = newValue ? newValue.value : '';
        handleChange && handleChange(value, fieldProps);
        setFieldValue(name, value);
      }}
    />
  );
};

export default FormAutocomplete;
