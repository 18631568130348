import { API_URL, API_AUTHORIZATION_URL } from '../config/api';

export const methods = {
  change: 'PATCH',
  forgot: 'PATCH',
  signUp: 'POST',
  acceptInvitation: 'PATCH',
  reset: 'PATCH',
};

export const urls = {
  authorization: API_AUTHORIZATION_URL,
  base: API_URL,
  forgotPassword: 'Account/forgot-password',
  changePassword: 'Account/change-password',
  signUp: 'Account/register',
  reset: 'Account/reset-password',
  acceptInvitation: 'Account/reset-password',
};

export const errorMessages = {
  login: 'Invalid login credentials',
  change: 'Your current password is incorrect, if you don’t remember your credentials - contact your admin',
  forgot: 'There is no user registered with email provided',
  signUp: 'Your data is incorrect, please contact your admin',
  reset: 'Your password reset link appears to be invalid. Please request a new link.',
  acceptInvitation: 'Your invitation is invalid. Please request a new link.',
};

export const successMessages = {
  setNewPassword: 'Your password has been changed successfully',
  acceptInvitation: 'Your invitation has been approved successfully',
  resendUserInvitation: 'An invitation has been sent to the user',
  resetPassword:
    'Please check your email for further instructions to reset your password. Please make sure to check your "spam" folder if you did not receive this email.',
  companyCreate: 'New company has been successfully created',
  companyUserInvite: 'An invitation has been sent to the user',
  companyDelete: 'Company has been successfully deleted',
  companyRestore: 'Company has been successfully restore',
  companyUserDelete: 'User has been successfully deleted',
  companyUserRestore: 'User has been successfully restored',
  companyFollow: 'Follow request has been successfully sent to the company',
  companyUnfollow: 'You unfollowed the company successfully',
  pqfApproved: 'PQF has been successfully approved',
  pqfRejected: 'PQF has been successfully rejected',
};
