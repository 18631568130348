import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: false,
  categories: [],
  groups: [],
  success: false,
  error: false,
};

export default function workCategory(state = initialState, action) {
  switch (action.type) {
    case currentCompany.workCategory.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.workCategory.get.end:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        groups: action.payload.groups,
      };

    case currentCompany.workCategory.update.start:
      return { ...state, loading: true };

    case currentCompany.workCategory.update.end:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
        success: true,
        error: false,
      };

    case currentCompany.workCategory.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
