import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { companyId, values, sectionKey } }) {
  try {
    const { data } = yield call(dataProvider.createList, `companies/${companyId}/craftsmen-certification`, values);
    yield put({ type: currentCompany.craftsman.update.end, payload: { state: data } });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.craftsman.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateCraftsman() {
  yield takeLatest(currentCompany.craftsman.update.start, update);
}
