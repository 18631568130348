import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, ownerId, contractorLevel } }) {
  try {
    const [questions, answers] = yield all([
      call(
        dataProvider.getList,
        `companies/${companyId}/contractor-addendum/${ownerId}/addendum?contractorLevel=${contractorLevel}`,
        {},
      ),
      call(dataProvider.getList, `companies/${companyId}/contractor-addendum/${ownerId}`, {}),
    ]);

    yield put({
      type: currentCompany.contractorAddendum.get.end,
      payload: { questions: questions.data, answers: answers.data },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.contractorAddendum.get.error });
  }
}

export default function* getAddendumSaga() {
  yield takeLatest(currentCompany.contractorAddendum.get.start, get);
}
