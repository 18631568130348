import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';

import currentCompany from '../../../../actionTypes/currentCompany';
import useStyles from './styles';
import ContractorAddendumForm from '../../ContractorAddendumForm';
import { useCompanyInfo } from '../../../../utils/effects/useCompanyInfo';

const AddendumModal = ({ open, ownerId, companyId, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { level } = useCompanyInfo(companyId);

  const { loading, questions, answers } = useSelector(store => store.currentCompany.contractorAddendum);

  useEffect(() => {
    if (open) {
      dispatch({
        type: currentCompany.contractorAddendum.get.start,
        payload: { companyId, ownerId, contractorLevel: level },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, companyId, ownerId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogModal,
      }}
    >
      <Collapse in={loading && questions.length === 0}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center" className={classes.loadingContainer}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Collapse>

      <Collapse in={!loading && questions.length !== 0}>
        <DialogTitle disableTypography classes={{ root: classes.titleWrapper }}>
          Addendum
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.contentWrapper,
          }}
        >
          <ContractorAddendumForm
            readOnly
            questions={questions}
            answers={answers}
            handleSave={() => {}}
            loading={loading}
            companyId={companyId}
          />
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.contentWrapper,
          }}
        >
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Button variant="contained" size="small" onClick={handleClose}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

export default AddendumModal;
