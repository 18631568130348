import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { title } from '../../routes/path';
import CenteredContainer from '../../components/CenteredContainer';
import SetNewPasswordForm from '../../containers/SetNewPasswordForm';
import { auth } from '../../../actionTypes';

const SetNewPasswordPage = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { loading } = useSelector(state => state.auth.setNewPassword);

  const onSubmit = values => {
    dispatch({ type: auth.setNewPassword.start, payload: { values, token } });
  };

  return (
    <>
      <Helmet>
        <title>{title.public.setNewPassword}</title>
      </Helmet>
      <CenteredContainer>
        <SetNewPasswordForm handleSubmit={onSubmit} title="Set New Password" loading={loading} />
      </CenteredContainer>
    </>
  );
};

export default SetNewPasswordPage;
