import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  uploadWrapper: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
