import React from 'react';
import { Formik, FastField } from 'formik';
import { Grid, CircularProgress, Button, Typography } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import { formatDate } from '../../../../utils/textFormaters';
import useStyles from './styles';

const AdditionalNotes = ({
  loading,
  canApprove,
  approvedStatus,
  editable,
  rejectReason,
  completerName,
  completedDate,
  handleSave,
  canReject,
}) => {
  const classes = useStyles();
  const handleApprove = () => handleSave();
  const handleReject = ({ rejectReason }) => handleSave({ reason: rejectReason });
  return (
    <Formik initialValues={{ rejectReason: rejectReason || '' }} onSubmit={handleReject}>
      {({ handleSubmit }) => (
        <>
          <Grid item xs={12}>
            <Grid container justify="flex-start">
              <Grid item xs={6}>
                <FastField
                  disabled={!editable}
                  name="rejectReason"
                  component={FormInput}
                  type="textarea"
                  rows={4}
                  multiline
                  label="Additional notes"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end" alignItems="center" spacing={1}>
              {editable ? (
                <>
                  {loading ? (
                    <Grid item>
                      <CircularProgress size={24} />
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <Button
                          onClick={handleApprove}
                          className={classes.approve}
                          disabled={!canApprove}
                          variant="contained"
                          size="small"
                        >
                          Approve
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          className={classes.reject}
                          variant="contained"
                          size="small"
                          disabled={canReject}
                        >
                          Reject
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <Grid>
                  {approvedStatus && (
                    <Typography>
                      Submitted by {completerName} on {formatDate(completedDate)}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default AdditionalNotes;
