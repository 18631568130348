import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { currentCompany } from '../../../actionTypes';
import OrganizationInfoForm from '../../containers/CreateOrganizationInfoForm';
import { title, sectionKey } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { changeEmptyStringToNull } from '../../../utils/containersHelpers';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const OrganizationInfo = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { organizationInfo, organizationArrays, loading, requestInProgress, success, error } = useSelector(
    store => store.currentCompany.organization,
  );

  useEffect(() => {
    dispatch({ type: currentCompany.organizationInfo.get.start, companyId });
  }, [companyId, dispatch]);

  const handleSubmit = values => {
    const { organizationInfo, organizationArrays } = values;
    const formattedValues = changeEmptyStringToNull(organizationInfo);

    dispatch({
      type: currentCompany.organizationInfo.update.start,
      payload: {
        companyId,
        organizationInfo: formattedValues,
        organizationArrays,
        sectionKey: sectionKey.private.company.organizationInformation,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.organizationInformation, { companyName })}</title>
      </Helmet>
      <OrganizationInfoForm
        organizationInfo={organizationInfo}
        organizationArrays={organizationArrays}
        handleSubmit={handleSubmit}
        loading={loading}
        requestInProgress={requestInProgress}
        companyId={companyId}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default OrganizationInfo;
