import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { title, sectionKey } from '../../routes/path';
import { currentCompany } from '../../../actionTypes';
import CompanyInfoForm from '../../containers/CompanyInfoForm';
import { formatPageTitle } from '../../../utils/textFormaters';
import { changeEmptyStringToNull } from '../../../utils/containersHelpers';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { requestInProgress, loading, success, error } = useSelector(state => state.currentCompany.info);
  const companyInfo = useSelector(state => state.entity.company[companyId]);

  useEffect(() => {
    dispatch({ type: currentCompany.info.get.start, payload: { id: companyId } });
  }, [companyId, dispatch]);

  const handleSubmit = values =>
    dispatch({
      type: currentCompany.info.update.start,
      payload: {
        values: changeEmptyStringToNull(values),
        id: companyId,
        sectionKey: sectionKey.private.company.settingsInfo,
      },
    });

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.settingsInfo, { companyName })} </title>
      </Helmet>
      <CompanyInfoForm
        companyId={companyId}
        initialValues={companyInfo}
        requestInProgress={requestInProgress}
        loading={loading}
        success={success}
        error={error}
        handleSubmit={handleSubmit}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default CompanyInfo;
