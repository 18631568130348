import React from 'react';
import { FastField } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import FormSelect from '../../../components/FormSelect';
import useStyles from './styles';

const DropdownType = ({ label, name, options, level, readOnly }) => {
  const classes = useStyles({ level });
  const createOptions = options.map(item => ({ label: item.text, value: item.id }));
  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={8}>
        <Typography variant="subtitle2" gutterBottom className={classes.innerWrapper}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <FastField name={name} label={label} component={FormSelect} options={createOptions} disabled={readOnly} />
      </Grid>
    </Grid>
  );
};

export default DropdownType;
