import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    get: createEnum(requestState, 'file.get'),
    put: createEnum(requestState, 'file.put'),
    download: createEnum(requestState, 'file.download'),
    remove: createEnum(requestState, 'file.remove'),
    add: createEnum(requestState, 'file.add'),
  },
  'file',
);
