import { yesNoQuestionsAll } from '../../actionTypes';

const initialState = {
  questions: [],
  loading: false,
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case yesNoQuestionsAll.get.start:
      return { ...state, loading: true };

    case yesNoQuestionsAll.get.end:
      return {
        ...state,
        loading: false,
        questions: action.payload.questions,
      };

    case yesNoQuestionsAll.get.error:
      return {
        ...initialState,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
