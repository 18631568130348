import React from 'react';
import { FastField } from 'formik';

import FormInput from '../../../components/FormInput';
import FormDatePicker from '../../../components/FormDataPicker';
import FormSelect from '../../../components/FormSelect';
import { emptyValueFieldKeys } from '../../../../utils/enums/questions';
import optionTypes from '../../../../utils/enums/options';

const loadedOptionsKey = 'FormBuilderField';

export default function(type, optionType, createOptions, conditionType, index) {
  const options = optionType === loadedOptionsKey ? createOptions : optionTypes[optionType];

  const fieldType = {
    Text: <FastField name={`companyRules[${index}].conditionValue`} component={FormInput} />,
    Paragraph: <FastField name={`companyRules[${index}].conditionValue`} component={FormInput} />,
    Number: <FastField name={`companyRules[${index}].conditionValue`} component={FormInput} number="float" />,
    Date: <FastField name={`companyRules[${index}].conditionValue`} component={FormDatePicker} />,
    Upload: <FastField name={`companyRules[${index}].conditionValue`} component={FormInput} number="integer" min={1} />,
    Table: <FastField name={`companyRules[${index}].conditionValue`} component={FormInput} number="integer" min={1} />,
    Dropdown: <FastField name={`companyRules[${index}].conditionValue`} component={FormSelect} options={options} />,
  };
  return (!emptyValueFieldKeys.includes(conditionType) && fieldType[type]) || null;
}
