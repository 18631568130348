import { call, put, takeLatest } from 'redux-saga/effects';

import { archive, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { params = {} } }) {
  try {
    const { data, total } = yield call(dataProvider.getList, `Users/deleted-users`, params);

    yield put({
      type: archive.user.get.end,
      payload: { list: data, total },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
    yield put({ type: archive.user.get.error });
  }
}

export default function* userListPageSaga() {
  yield takeLatest(archive.user.get.start, get);
}
