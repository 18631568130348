import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import CollapseCard from '../../../components/Cards/CollapseCard';
import dashboardStyles from '../styles';

const Services = ({ services }) => {
  const dashboardClasses = dashboardStyles();

  return (
    <CollapseCard title="Services">
      <Grid container>
        <Grid item xs={12} className={dashboardClasses.cardWrapper}>
          {services && services.length > 0 ? (
            services.map(service => (
              <Typography key={service.id} variant="subtitle2" gutterBottom>
                {service.name}
              </Typography>
            ))
          ) : (
            <Typography variant="body1" align="center" gutterBottom>
              There are no services yet
            </Typography>
          )}
        </Grid>
      </Grid>
    </CollapseCard>
  );
};

export default Services;
