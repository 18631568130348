import { auth } from '../../actionTypes';

const initialState = {
  state: null,
  loading: false,
};

export default function check(state = initialState, action) {
  switch (action.type) {
    case auth.login.start:
      return { ...state, loading: true };

    case auth.login.end:
      return {
        ...state,
        loading: false,
        error: false,
        state: action.payload ? action.payload.state : null,
      };

    default:
      return state;
  }
}
