import { FastField, Formik } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import { createCompanySchema } from '../../../utils/validate/shemas';
import PaperWrapper from '../../components/PaperWrapper';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormNumberInput from '../../components/FormNumberInput';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import useStyles from './styles';
import companyRoleTypes from '../../../utils/constants/companyRoleTypes';
import PromptNotification from '../../components/PromptComponent';
import { userTypes } from '../../../utils/constants';

const typeValueKey = 'type';

const CreateCompanyForm = ({ error, loading, success, handleSubmit, allowUpdate, isSafetyCouncilUserType }) => {
  const classes = useStyles();

  const onSubmit = values => {
    const newValues = Object.keys(values).reduce((acc, key) => {
      if (key === typeValueKey && values[key] !== userTypes.Owner)
        return {
          ...acc,
          type: userTypes.Contractor,
          level: values[key],
        };
      return {
        ...acc,
        [key]: ['phoneNumber', 'fax'].includes(key) ? values[key].replace(/-/g, '') : values[key],
      };
    }, {});
    handleSubmit(newValues);
  };
  const initialValues = {
    type: '',
    companyName: '',
    phoneNumber: '',
    fax: '',
    emailAddress: '',
    website: '',
    taxId: '',
  };

    //console.log(companyRoleTypes)
   
  return (
    <PaperWrapper>
      <Formik initialValues={initialValues} validationSchema={createCompanySchema} onSubmit={onSubmit}>
        {props => (
          <>
            <PromptNotification formStatus={props.dirty && !props.isSubmitting} />
            <FormBody loading={loading} disabled={!allowUpdate}>
              <Grid item xs={4}>
                <Grid container>
                  <Grid>
                    <Typography variant="h3" gutterBottom>
                      Create New Company
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {isSafetyCouncilUserType && (
                      <FastField
                        className={classes.inputRow}
                        label="Type"
                        name="type"
                        id="createCompanyRole"
                        options={companyRoleTypes}
                        component={FormSelect}
                      />
                    )}
                    <FastField
                      className={classes.inputRow}
                      label="Company Name"
                      name="companyName"
                      component={FormInput}
                    />
                    <FastField
                      className={classes.inputRow}
                      label="Phone Number"
                      type="tel"
                      name="phoneNumber"
                      component={FormInput}
                    />
                    <FastField className={classes.inputRow} label="Fax" type="tel" name="fax" component={FormInput} />
                    <FastField
                      className={classes.inputRow}
                      label="Email"
                      name="emailAddress"
                      type="email"
                      component={FormInput}
                    />
                    <FastField className={classes.inputRow} label="Web Site" name="website" component={FormInput} />
                    <FastField
                      className={classes.inputRow}
                      label="Tax ID"
                      name="taxId"
                      component={FormNumberInput}
                      maskType="fromZero"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {allowUpdate && (
                <LoadingButton
                  fixed
                  skipCheckPQF
                  disabled={!props.dirty || !props.isValid}
                  success={success}
                  error={error}
                  loading={loading}
                  onClick={props.handleSubmit}
                  text="save"
                />
              )}
            </FormBody>
          </>
        )}
      </Formik>
    </PaperWrapper>
  );
};

export default CreateCompanyForm;
