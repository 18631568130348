import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableRow: {
    position: 'relative',
    overflow: 'hidden',
    '& td:nth-child(1)': {
      width: '50%',
      maxWidth: theme.spacing(15),
      wordWrap: 'break-word',
    },
    '& td:nth-child(2)': {
      width: '20%',
    },
    '& td:nth-child(3)': {
      width: '20%',
    },
    '& td:nth-child(4)': {
      width: '10%',
    },
  },
}));

export default useStyles;
