import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    '&>.MuiGrid-container': {
      marginBottom: theme.spacing(0.5),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

export default useStyles;
