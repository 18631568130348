// here must be all pages by url company/:id/${any_url}
import { combineReducers } from 'redux';

import workCategory from './workCategory';
import service from './service';
import finInfo from './finInfo';
import user from './user';
import reference from './reference';
import parentCompany from './parentCompany';
import organization from './organization';
import officer from './officer';
import yesNoQuestions from './yesNoQuestions';
import companyInfo from './companyInfo';
import healthProf from './healthProf';
import insurancePolicy from './insurancePolicy';
import info from './info';
import emrate from './emrate';
import ownerAddendum from './ownerAddendum';
import injuryIllness from './injuryIllness';
import craftsman from './craftsman';
import contractorAddendum from './contractorAddendum';
import questions from './questions';
import settings from './settings';
import companyAdminInfo from './companyAdminInfo';

export default combineReducers({
  workCategory,
  service,
  finInfo,
  user,
  reference,
  parentCompany,
  organization,
  officer,
  yesNoQuestions,
  companyInfo,
  healthProf,
  insurancePolicy,
  info,
  emrate,
  ownerAddendum,
  contractorAddendum,
  injuryIllness,
  craftsman,
  questions,
  settings,
  companyAdminInfo,
});
