import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  logoWrapper: {
    padding: theme.spacing(1, 2, 0.5, 0.75),
    cursor: 'pointer',
  },
  image: {
    height: theme.spacing(8),
    verticalAlign: 'middle',
  },
  menuWrapper: {
    maxHeight: theme.spacing(8),
  },
  linksWrapper: {
    height: '100%',
    padding: theme.spacing(0, 1),
  },
  link: {
    display: 'block',
  },
}));

export default useStyles;
