import { all, call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import get from 'lodash.get';

import { pages, notification, entity, currentCompany } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import {
  company as companySchema,
  document,
  uploadDocument,
  workCategory as workCategorySchema,
  workCategoryGroup as workCategoryGroupSchema,
} from '../../../schema';
import { userTypes } from '../../../utils/constants';

export function* getSaga({ payload: { companyId, documentQuery, type, ownerId } }) {
  try {
    const [
      dashboard,
      documents,
      uploadDocuments,
      groups,
      categories,
      grade,
      conditions,
      validation,
      addendumContractor,
      listOwnerGrading,
      singleOwner,
    ] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/dashboard`),
      call(dataProvider.getList, `companies/${companyId}/pqf`, documentQuery),
      call(dataProvider.getList, `companies/${companyId}/related-document`, {}),
      call(dataProvider.getList, 'Definitions/work-categories', {}),
      call(dataProvider.getList, `companies/${companyId}/work-categories/approved`, {}),
      call(dataProvider.getOnly, `companies/${companyId}/grade/scores/owner`),
      call(dataProvider.getOnly, `companies/${companyId}/conditions`),
      (type === userTypes.Contractor &&
        call(dataProvider.getOnly, `companies/${companyId}/pqf/validate`, {
          filter: { fullValidation: false },
        })) ||
        {},
      call(dataProvider.getOnly, `companies/${companyId}/contractor-addendum/all`, {}),
      // ,
      (type === userTypes.SafetyCouncil &&
        call(dataProvider.getOnly, `companies/${companyId}/grade/owner-grades`, {})) ||
        {},

      (type === userTypes.Owner &&
        call(dataProvider.getOnly, `companies/${companyId}/grade/scores/owner/${ownerId}`)) ||
        {},
    ]);

    const { company, organizationInfo, services } = dashboard.data;

    // normalize company
    const normalizeData = normalize([{ ...company, level: company.companyLevel }], [companySchema]); // todo: fix if BE-Dev change field name
    yield put({ type: entity.add, payload: normalizeData.entities });

    // normalize documents
    const normalizeDoc = normalize(documents.data.data, [document]);
    yield put({ type: entity.add, payload: normalizeDoc.entities });

    // normalize uploadDocuments
    const normalizeUploadDoc = normalize(uploadDocuments.data.data, [uploadDocument]);
    yield put({ type: entity.add, payload: normalizeUploadDoc.entities });

    // // normalize data
    const listCategories = normalize(categories.data.data, [workCategorySchema]);
    const listGroups = normalize(groups.data.data, [workCategoryGroupSchema]);

    yield put({ type: entity.add, payload: listCategories.entities });
    yield put({ type: entity.add, payload: listGroups.entities });

    yield put({
      type: currentCompany.workCategory.get.end,
      payload: { groups: listGroups.result, categories: listCategories.result },
    });

    yield put({
      type: pages.dashboard.get.end,
      payload: {
        generalInfo: get(dashboard, 'data.generalInfo', {}),
        notification: {
          isGenerationStarted: get(validation, 'data.isGenerationStarted', false),
          sections: get(validation, 'data.sections', []),
          conditions: (conditions && conditions.data) || {},
        },
        documents: {
          list: normalizeDoc.result,
          page: documentQuery.pagination.page,
          total: documents.total,
        },
        uploadDocuments: {
          list: normalizeUploadDoc.result,
        },
        organizationInfo,
        services,
        addendum: {
          contractor: get(addendumContractor, 'data', []),
        },
        grade: {
          list: grade.data.scores,
          mark: grade.data.grade,
        },
        listOwnerGrading: get(listOwnerGrading, 'data', []),
        ownerGrading: {
          list: get(singleOwner, 'data.scores', []),
          mark: get(singleOwner, 'data.grade', null),
        },
      },
    });
  } catch (e) {
    yield put({
      type: pages.dashboard.get.end,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getDashboardData() {
  yield takeLatest(pages.dashboard.get.start, getSaga);
}
