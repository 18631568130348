import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    borderWidth: 1,
    borderTopColor: theme.palette.grey[100],
    borderTopStyle: 'solid',
    maxWidth: theme.spacing(50),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
