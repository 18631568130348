import * as Yup from 'yup';

import { enums } from '../../../utils/constants/formConstats';
import errors from '../../../utils/validate/errorMessages';

const validateField = {
  YesNo: Yup.string().oneOf(Object.keys(enums.yesno), errors.valueIncorrect),
  YesNoNA: Yup.string().oneOf(Object.keys(enums.yesnona), errors.valueIncorrect),
  Text: Yup.string().min(10, errors.requiredDescriptionMin10),
  Section: Yup.string(),
  Number: Yup.mixed().when({
    is: value => value !== 0,
    then: shema => shema.string,
    otherwise: shema => shema.number,
  }),
  Frequency: Yup.string().oneOf(Object.keys(enums.frequency), errors.valueIncorrect),
  Uploads: Yup.object(),
};

const sectionType = 'Section';

const defaultSchema = Yup.object().shape({});

export default (feilds, schema = defaultSchema) =>
  Object.keys(feilds).reduce((acc, id) => {
    // if field type 'Section' - dont need to validate
    if (feilds[id].type === sectionType) return acc;
    // if (feilds[id].type === 'Uploads') return acc;

    return acc.concat(
      Yup.object().shape({
        [id]: validateField[feilds[id].type].test({
          name: id,
          message: errors.required,
          test: function(value) {
            const formValues = this.parent;

            const hasParent = feilds[id].parent;

            // if field is not required
            if (!feilds[id].isRequiredForForm) {
              return true;
            }

            // check if field has parent
            if (!hasParent) {
              // check is value not empty
              if (feilds[id].type === 'Uploads') {
                return value.count > 0;
              }
              return value && value !== '';
            } else {
              const parentId = feilds[id].parent;
              const isParentSection = feilds[id].parent && feilds[parentId].type === sectionType;

              // if field hide - not required
              if (feilds[id].showOnCondition && formValues[parentId] !== feilds[id].parentAnswerCondition) {
                return true;
              }

              // if field parent is type 'Section' and it hide - not required
              if (
                isParentSection &&
                feilds[parentId].showOnCondition &&
                formValues[feilds[parentId].parent] !== feilds[parentId].parentAnswerCondition
              ) {
                return true;
              }

              // if field parent is type 'Section' and it not hide
              if (isParentSection && !feilds[parentId].showOnCondition) {
                if (feilds[id].type === 'Uploads') {
                  return value.count > 0;
                }
                return value && value !== '';
              }

              // check is field valid and parent answers are equal with current
              const validValueRule = feilds[id].type === 'Uploads' ? value.count > 0 : value && value !== '';
              return isParentSection
                ? formValues[feilds[parentId].parent] === feilds[parentId].parentAnswerCondition && validValueRule
                : formValues[parentId] === feilds[id].parentAnswerCondition && validValueRule;
            }
          },
        }),
      }),
    );
  }, schema);
