import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { notification, review } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';
import { pqfStatuses } from '../../utils/constants';
import routes from '../../ui/routes/path';
import { successMessages } from '../../store/constants';

export function* save({ payload: { companyId, pqfDocumentId, params } }) {
  try {
    const { data } = yield call(
      dataProvider.createOne,
      `companies/${companyId}/pqf/${pqfDocumentId}/review/${params ? 'reject-document' : 'approve-document'}`,
      params,
    );

    yield put({
      type: review.save.end,
      payload: {
        pqfDocumentStatus: params ? pqfStatuses.Rejected.key : pqfStatuses.Completed.key,
        completerName: data ? data.completerName : '',
        completedDate: data ? data.completedDate : '',
      },
    });
    yield put(push(routes.private.index));
    yield put({
      type: notification.add,
      payload: { success: params ? successMessages.pqfRejected : successMessages.pqfApproved },
    });
  } catch (e) {
    yield put({
      type: review.save.error,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* saveReviewSaga() {
  yield takeLatest(review.save.start, save);
}
