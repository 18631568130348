import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    borderTopWidth: 0.5,
    borderTopColor: theme.palette.grey[100],
    borderTopStyle: 'solid',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.5),
    },
    '& > tbody > tr > td:last-child': {
      width: theme.spacing(13),
    },
  },
  title: {
    padding: theme.spacing(1.5),
  },
  buttons: {
    margin: theme.spacing(1, 0),
  },
}));

export default useStyles;
