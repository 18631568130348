import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    company: {
      get: createEnum(requestState, 'archive.company.get'),
      recover: createEnum(requestState, 'archive.company.recover'),
    },
    user: {
      get: createEnum(requestState, 'archive.user.get'),
      recover: createEnum(requestState, 'archive.user.recover'),
    },
  },
  'archive',
);
