import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialLink from '@material-ui/core/Link';
import { useDispatch, useSelector } from 'react-redux';

import { addendumFile } from '../../../../actionTypes';
import useStyles from './styles';

const LinkType = ({ label, readOnly, level, ownerCompanyId, formBuilderFieldId }) => {
  const classes = useStyles({ level });

  const dispatch = useDispatch();

  const { loading } = useSelector(store => store.addendumFile.addendumFile);

  const handleDownload = () => {
    dispatch({
      type: addendumFile.downloadByFormBuilderFieldId.start,
      payload: {
        companyId: ownerCompanyId,
        fileId: formBuilderFieldId,
      },
    });
  };

  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <MaterialLink className={classes.downloadText} onClick={handleDownload}>
            {label}
          </MaterialLink>
        )}
      </Grid>
    </Grid>
  );
};

export default LinkType;
