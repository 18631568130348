import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  containerLabel: {
    textAlign: 'end',
    textTransform: 'none',
    padding: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        textDecoration: 'underline',
      },
    },
  },
  labelText: {
    maxWidth: `calc(100% - ${theme.spacing(5)}px)`,
    marginRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.common.black,
  },
  container: {
    position: 'relative',
    maxWidth: `calc(100% - ${theme.spacing(5)}px)`,
  },
  fullSize: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  notificationIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  relativeContainer: {
    position: 'relative',
  },
}));

export default useStyles;
