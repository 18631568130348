import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const { data: craftsmanData } = yield call(
      dataProvider.getList,
      `companies/${companyId}/craftsmen-certification`,
      {},
    );

    yield put({
      type: currentCompany.craftsman.get.end,
      payload: { state: craftsmanData },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getCraftsmanSaga() {
  yield takeLatest(currentCompany.craftsman.get.start, get);
}
