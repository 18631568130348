import React from 'react';
import { Field } from 'formik';
import { Typography, Grid } from '@material-ui/core';

import FormSelect from '../../../components/FormSelect';

const TitleCell = ({ name, edit, year, options }) => {
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12}>
          {edit ? (
            <Field name={name} component={FormSelect} options={options} />
          ) : (
            <Typography variant="body1" align="center">
              {year}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TitleCell;
