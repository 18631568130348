import React from 'react';
import { Formik, FastField } from 'formik';
import { Grid } from '@material-ui/core';
import debounce from 'lodash.debounce';

import { filterArchiveUserSchema } from '../../../../utils/validate/shemas';
import FormInput from '../../../components/FormInput';
import Effect from '../../../components/Effect';

const CreateFilterForm = ({ handleOnChange, values }) => {
  const debounceFunc = debounce(handleOnChange, 1000, {
    leading: true,
    trailing: true,
  });

  return (
    <Formik
      initialValues={{ userName: values.q }}
      validationSchema={filterArchiveUserSchema}
      onSubmit={debounceFunc}
      validateOnChange={false}
    >
      {({ submitForm, values }) => {
        return (
          <Grid container>
            <Grid item sm={6}>
              <Effect values={values} submitForm={submitForm} debounceFunc={debounceFunc} />
              <Grid container>
                <Grid item sm={6}>
                  <FastField placeholder="Search User" name="userName" type="search" component={FormInput} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default CreateFilterForm;
