import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { currentCompany } from '../../../actionTypes';
import ServicesPerformedForm from '../../containers/ServicesPerformedForm';
import { title, sectionKey } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const ServicesPerformedPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const services = useSelector(state => state.currentCompany.service.services.map(id => state.entity.service[id]));
  const { loading, success, error, others } = useSelector(state => state.currentCompany.service);

  const companyServices = useSelector(state => state.currentCompany.service.companyServices);

  useEffect(() => {
    dispatch({ type: currentCompany.service.get.start, payload: { companyId, params: {} } });
  }, [companyId, dispatch]);

  const onSubmit = ({ keys, othersText }) => {
    dispatch({
      type: currentCompany.service.update.start,
      payload: { companyId, values: keys, other: othersText, sectionKey: sectionKey.private.company.services },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.services, { companyName })}</title>
      </Helmet>
      <ServicesPerformedForm
        services={services}
        others={others}
        companyServices={companyServices}
        handleSubmit={onSubmit}
        loading={loading}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default ServicesPerformedPage;
