import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  containerForm: ({ loading, disabled }) => ({
    overflow: loading ? 'hidden' : 'auto',
    pointerEvents: loading || disabled ? 'none' : 'auto',
    position: 'relative',
    minWidth: '100%',
    alignSelf: 'stretch',
  }),
});

export default useStyles;
