import { call, put, takeLatest } from 'redux-saga/effects';

import { notification, pages } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* download({ payload: { companyId, companyName, ownerId, scores } }) {
  try {
    yield call(dataProvider.createOne, `companies/${companyId}/grade/report?companyName=${companyName}`, scores);

    yield put({ type: pages.dashboard.downloadOwnerScorecard.end });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: pages.dashboard.downloadOwnerScorecard.error });
  }
}

export default function* downloadPQFScorecardSaga() {
  yield takeLatest(pages.dashboard.downloadOwnerScorecard.start, download);
}
