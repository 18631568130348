import { makeStyles } from '@material-ui/core/styles';

import { iconSpacing } from '../styles';

const useStyles = makeStyles(theme => ({
  wrapper: ({ current, hasCurrentChild }) => {
    const isAccentColor = current || hasCurrentChild;

    return {
      padding: theme.spacing(0.75, 0),
      textDecoration: 'none',
      cursor: 'pointer',
      backgroundColor: isAccentColor ? theme.palette.grey['200'] : 'transparent',
      transition: 'all 0.3s',
      '&:hover': {
        backgroundColor: theme.palette.grey['300'],
      },
    };
  },
  childContainer: {
    padding: theme.spacing(0, 1),
  },
  menuIcon: {
    width: theme.spacing(iconSpacing),
  },
  menuItemWidth: {
    width: `calc(100% - ${theme.spacing(iconSpacing)}px)`,
  },
  iconContainer: {
    '& div': {
      lineHeight: 1,
      textAlign: 'center',
    },
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.common.black,
    lineHeight: 1,
  },
  success: {
    color: theme.palette.labels.success,
  },
  error: {
    color: theme.palette.labels.error,
  },
}));

export default useStyles;
