import { review } from '../../actionTypes';

const initialState = {
  loading: true,
  list: [],
  updateList: [],
  completerName: '',
  completedDate: '',
  rejectReason: '',
  pqfDocumentStatus: null,
  downloadList: [],
};

export default function reviewReducer(state = initialState, action) {
  switch (action.type) {
    case review.get.start:
      return initialState;
    case review.get.end:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        completerName: action.payload.completerName,
        completedDate: action.payload.completedDate,
        rejectReason: action.payload.rejectReason,
        pqfDocumentStatus: action.payload.pqfDocumentStatus,
      };
    case review.get.error:
      return {
        ...state,
        loading: false,
        list: [],
        completerName: '',
        completedDate: '',
        rejectReason: '',
      };

    case review.update.start:
      return {
        ...state,
        updateList: [...state.updateList, action.payload.params.id],
      };
    case review.update.end: {
      const listUpdated = action.payload.params.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
      const updateKeys = Object.keys(listUpdated);
      return {
        ...state,
        updateList: state.updateList.filter(id => !updateKeys.includes(`${id}`)),
        list: state.list.map(item => (updateKeys.includes(`${item.id}`) ? listUpdated[item.id] : item)),
      };
    }
    case review.update.error: {
      const updateKeys = action.payload.params.map(item => item.id);
      return {
        ...state,
        updateList: state.updateList.filter(id => !updateKeys.includes(`${id}`)),
      };
    }

    case review.save.start:
      return {
        ...state,
        loading: true,
      };
    case review.save.end:
      return {
        ...state,
        loading: false,
        completerName: action.payload.completerName,
        completedDate: action.payload.completedDate,
        pqfDocumentStatus: action.payload.pqfDocumentStatus,
      };
    case review.save.error:
      return {
        ...state,
        loading: false,
      };

    case review.downloadFile.start:
      return {
        ...state,
        downloadList: [...state.downloadList, action.payload.fileId],
      };
    case review.downloadFile.end:
      return {
        ...state,
        downloadList: state.downloadList.filter(id => id !== action.payload.fileId),
      };
    case review.downloadFile.error:
      return {
        ...state,
        downloadList: state.downloadList.filter(id => id !== action.payload.fileId),
      };

    default:
      return state;
  }
}
