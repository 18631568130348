import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(1),
  },
  itemContainer: {
    paddingTop: theme.spacing(1),
  },
  bottomContainer: {
    marginTop: theme.spacing(4),
  },
  label: {
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
}));

export default useStyles;
