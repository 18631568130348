import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  textArea: {
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.grey['400'],
    borderRadius: theme.spacing(0.5),
  },
}));

export default useStyles;
