import React from 'react';
import { FastField, Field } from 'formik';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Grid, Typography } from '@material-ui/core';

import FormSelect from '../../../components/FormSelect';
import { options, emptyValueFieldKeys } from '../../../../utils/enums/questions';
import renderValue from './renderValue';
import FormInput from '../../../components/FormInput';

const FormTypes = ({ values, optionType, createOptions, arrayHelpers, type, pqfFieldId }) => {
  const generatedefaultConditionType = () => {
    const existConditionType = values.map(item => item.conditionType);
    return options[type].filter(type => !existConditionType.includes(type.value));
  };

  const generateCurrentOptions = current => {
    const exceptions = values
      .map(item => item.conditionType)
      .filter(type => type !== current && emptyValueFieldKeys.includes(type));
    return options[type].filter(option => !exceptions.includes(option.value));
  };

  const defaultAdd = {
    pqfFieldId,
    conditionType: generatedefaultConditionType().length
      ? generatedefaultConditionType()[0].value
      : generatedefaultConditionType().value,
    conditionValue: '',
    grade: options.Mark[0].value,
  };

  const columns = [
    {
      title: 'Options',
      field: 'conditionType',
      render: ({ index, conditionType }) => (
        <Field
          name={`companyRules[${index}].conditionType`}
          component={FormSelect}
          options={generateCurrentOptions(conditionType)}
        />
      ),
    },
    {
      title: 'Values',
      field: 'conditionValue',
      render: ({ conditionType, index }) => renderValue(type, optionType, createOptions, conditionType, index),
    },
    {
      title: 'Score',
      field: 'score',
      render: ({ index }) => (
        <FastField name={`companyRules[${index}].grade`} component={FormSelect} options={options.Mark} />
      ),
    },
  ];

  const actions = [
    {
      icon: () => <DeleteIcon color="error" />,
      tooltip: 'Remove',
      onClick: (event, rowData) => arrayHelpers.remove(rowData.index),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field name="description" label="Description" rows={3} multiline component={FormInput} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          List rules:
        </Typography>
        <MaterialTable
          data={values.map((item, index) => ({ ...item, index }))}
          columns={columns}
          actions={actions}
          options={{
            sorting: false,
            search: false,
            editable: false,
            draggable: false,
            toolbar: false,
            paging: false,
            actionsColumnIndex: -1,
            rowStyle: {
              verticalAlign: 'top',
            },
          }}
        />
      </Grid>
      {!!generateCurrentOptions().length && (
        <Grid item xs={12}>
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => arrayHelpers.push(defaultAdd)}>
                ADD
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FormTypes;
