import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: false,
  services: [],
  others: null,
  companyServices: [],
  success: false,
  error: false,
};

export default function service(state = initialState, action) {
  switch (action.type) {
    case currentCompany.service.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.service.get.end:
      return {
        ...state,
        loading: false,
        services: action.payload.services,
        others: action.payload.others,
        companyServices: action.payload.companyServices,
      };

    case currentCompany.service.update.start:
      return { ...state, loading: true };

    case currentCompany.service.update.end:
      return {
        ...state,
        loading: false,
        companyServices: action.payload.companyServices,
        others: action.payload.others,
        success: true,
        error: false,
      };

    case currentCompany.service.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
