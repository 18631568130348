import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import { Paper, Typography, Grid, Button, CircularProgress, Backdrop, Link as MaterialLink } from '@material-ui/core';

import { resetPasswordSchema } from '../../../utils/validate/shemas';
import FormInput from '../../components/FormInput';
import routes from '../../routes/path';
import useStyles from './styles';

const ResetPasswordForm = ({ loading, error, handleSubmit }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Backdrop timeout={300} open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={handleSubmit}
      >
        {props => (
          <form onSubmit={props.handleSubmit} noValidate>
            <Typography align="center" variant="h3" gutterBottom>
              Password Reset
            </Typography>

            {!!error && (
              <Typography gutterBottom variant="subtitle2" color="error" align="center">
                {error}
              </Typography>
            )}
            <FastField className={classes.inputRow} label="Email" type="email" name="email" component={FormInput} />
            <div className={classes.inputRow}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item sm={8}>
                  <MaterialLink component={Link} to={routes.public.login}>
                    Back to Sign In
                  </MaterialLink>
                </Grid>
                <Grid item sm={4}>
                  <Button size="large" fullWidth variant="contained" color="primary" type="submit">
                    Send
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default ResetPasswordForm;
