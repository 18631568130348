import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

import useStyles from './styles';

const PageLoader = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center" className={classes.container}>
      <CircularProgress />
    </Grid>
  );
};

export default PageLoader;
