import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
} from '@material-ui/core';

import FileType from '../../../components/FileType';
import DeleteFile from '../DeleteFile';
import formatDate from '../../../../utils/textFormaters/date';
import useStyles from './styles';

const FileList = ({ files, editable, downloadList, expirationDate, deleteList, handleDownload, handleDelete }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell> File description </TableCell>
            <TableCell align="center"> Date uploaded </TableCell>
            {expirationDate && <TableCell align="center"> Expiration date </TableCell>}
            {editable && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map(item => (
            <TableRow key={item.id} className={classes.tableRow}>
              <TableCell>
                <FileType
                  name={item.description}
                  loading={downloadList.includes(item.id)}
                  type={item.type}
                  handleClick={() => handleDownload(item.id)}
                />
              </TableCell>
              <TableCell align="center">{formatDate(item.createDate)}</TableCell>
              {expirationDate && (
                <TableCell align="center">{item.expirationDate ? formatDate(item.expirationDate) : 'N/A'}</TableCell>
              )}
              {editable && (
                <TableCell align="center">
                  {deleteList.includes(item.id) ? (
                    <CircularProgress size={34} />
                  ) : (
                    <DeleteFile handleDelete={() => handleDelete(item.id)} />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
          {files.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="body1" align="center">
                  No uploaded files
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileList;
