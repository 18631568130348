import React from 'react';
import { Formik, FastField } from 'formik';
import { Grid } from '@material-ui/core';
import debounce from 'lodash.debounce';

import { filterArchiveCompanySchema } from '../../../../utils/validate/shemas';
import FormInput from '../../../components/FormInput';
import Effect from '../../../components/Effect';
import { companyRolesSelectionOptions } from '../../../../utils/constants/companyRoleTypes';
import FormAutocomplete from '../../../components/FormAutocomplete';

const CreateFilterForm = ({ handleOnChange, values }) => {
  const debounceFunc = debounce(handleOnChange, 1000, {
    leading: true,
    trailing: true,
  });
  return (
    <Formik
      initialValues={values}
      validationSchema={filterArchiveCompanySchema}
      onSubmit={debounceFunc}
      validateOnChange={false}
    >
      {({ submitForm, values, resetForm }) => (
        <Grid container>
          <Grid item sm={6}>
            <Effect values={values} submitForm={submitForm} resetForm={resetForm} debounceFunc={debounceFunc} />
            <Grid container justify="space-between" spacing={4}>
              <Grid item sm={6}>
                <FastField placeholder="Search Company" name="companyName" type="search" component={FormInput} />
              </Grid>
              <Grid item sm={6}>
                <FastField
                  label="Company Type"
                  name="companyType"
                  options={companyRolesSelectionOptions}
                  component={FormAutocomplete}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default CreateFilterForm;
