import React from 'react';
import { Grid, Button, CircularProgress, Paper, Typography } from '@material-ui/core';

import YesNoFormConstructor from '../YesNoFormConstructor';
import { recursiveInclude } from '../../../utils/containersHelpers';
import useStyles from './styles';

const YesNoQuestionsRenderer = ({ questions, loading, handleSubmit }) => {
  const setQuestionStructure = recursiveInclude(questions);
  const classes = useStyles();
  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  const renderStructure = (item, fields) =>
    fields[item.key] && (
      <Grid item lg={12} key={`render_${item.key}`} className={classes.containerChild}>
        <Grid container spacing={2} alignItems="center">
          {item.type === 'text' ? (
            <Grid item lg={12}>
              {fields[item.key]}
            </Grid>
          ) : (
            <>
              <Grid item lg={8}>
                <Typography className={classes.text} variant="subtitle2" gutterBottom>
                  {item.text}
                </Typography>
              </Grid>
              <Grid item lg={4}>
                {fields[item.key]}
              </Grid>
            </>
          )}
        </Grid>
        {!!item.children.length && item.children.map(level => renderStructure(level, fields))}
      </Grid>
    );

  return (
    <Grid container justify="center">
      <Paper className={classes.containerPaper}>
        <Grid item>
          <YesNoFormConstructor
            initialValues={{
              textValidate: '',
            }}
            fields={questions}
            onSubmit={handleSubmit}
          >
            {({ fields, handleSubmit }) => {
              return (
                <Grid container spacing={2}>
                  {setQuestionStructure.children.map(item => renderStructure(item, fields))}

                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Button onClick={handleSubmit} variant="contained" color="primary">
                        save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }}
          </YesNoFormConstructor>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default YesNoQuestionsRenderer;
