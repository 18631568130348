import React from 'react';
import { Tooltip, Typography, Button, IconButton, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EventNoteIcon from '@material-ui/icons/EventNote';

import routes from '../../../../routes/path';
import formatDate from '../../../../../utils/textFormaters/date';
import formatPath from '../../../../../utils/textFormaters/formatPath';
import { pqfStatusesEnum } from '../../../../../utils/constants';
import useStyles from './styles';

const CellStatus = ({ id, currentPqfDocumentId, status, rejectReason, completedOn, showReviewForOwner }) => {
  const classes = useStyles();
  const Wrapper = ({ children }) =>
    rejectReason ? (
      <Tooltip
        arrow
        leaveDelay={200}
        placement="bottom"
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        title={<Typography variant="body1">{rejectReason}</Typography>}
      >
        {children}
      </Tooltip>
    ) : (
      children
    );

  return (
    <>
      {status === pqfStatusesEnum.Completed && (
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="flex-start">
            <Grid item>
              <Typography variant="body1" className={classes.completed}>
                Complete on {formatDate(completedOn)}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Full review">
                <IconButton
                  variant="contained"
                  size="small"
                  color="primary"
                  component={Link}
                  to={formatPath(routes.private.review, { id, pqfDocumentId: currentPqfDocumentId })}
                  classes={{ colorPrimary: classes.successPQF }}
                >
                  <EventNoteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
      {status === pqfStatusesEnum.InProgress && (
        <Typography variant="body1" className={classes.inProgress}>
          In Progress
        </Typography>
      )}
      {status === pqfStatusesEnum.Rejected && (
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="flex-start">
            <Grid item>
              <Typography variant="body1" className={classes.rejected}>
                Rejected
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Full review">
                <IconButton
                  variant="contained"
                  size="small"
                  color="secondary"
                  component={Link}
                  to={formatPath(routes.private.review, { id, pqfDocumentId: currentPqfDocumentId })}
                  classes={{ colorPrimary: classes.rejectPQF }}
                >
                  <EventNoteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
      {status === pqfStatusesEnum.PendingReview && (
        <>
          {!showReviewForOwner ? (
            <Wrapper>
              <Typography variant="body1" className={classes.pendingReview}>
                Pending Review
              </Typography>
            </Wrapper>
          ) : (
            <>
              <Button
                variant="contained"
                size="small"
                color="primary"
                component={Link}
                to={formatPath(routes.private.review, { id, pqfDocumentId: currentPqfDocumentId })}
                classes={{ containedPrimary: classes.reviewPqf }}
              >
                Review PQF
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CellStatus;
