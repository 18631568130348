import { call, put, takeLatest } from 'redux-saga/effects';

import { filePsmAudit, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* download({ payload: { companyId, id } }) {
  try {
    yield call(dataProvider.getOnly, `companies/${companyId}/psm-audit/${id}`);

    yield put({ type: filePsmAudit.download.end, payload: { id } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: filePsmAudit.download.error, payload: { id } });
  }
}

export default function* downloadFileSaga() {
  yield takeLatest(filePsmAudit.download.start, download);
}
