import { contractorLevelPathEnum } from '../../utils/constants';

const recursiveKey = (obj, key) =>
  Object.keys(obj).reduce((acc, item) => {
    return {
      ...acc,
      [item]: Object.keys(obj[item]).filter(sub => typeof obj[item][sub] === 'object').length
        ? recursiveKey(obj[item], key)
        : obj[item][key],
    };
  }, {});

export const routeCreator = (companyPrefix = '/company/:id', key = 'url') => {
  const name = 'iPQF';

  const stack = {
    public: {
      login: {
        url: '/sign-in',
        title: `${name} - Sign in`,
      },
      resetPassword: {
        url: '/password-reset',
        title: `${name} - Password Reset`,
      },
      setNewPassword: {
        url: '/set-new-password/:token',
        title: `${name} - Set New Password`,
      },
      invitationAccept: {
        url: '/invitation-accept/:token',
        title: `${name} - Accept Invitation`,
      },
    },
    private: {
      index: {
        url: '/',
        title: `${name} - Dashboard`,
      },
      notFound: {
        url: '/404',
        title: `${name} - Not Found`,
      },
      testYesNoQuestions: {
        url: '/test/yesnoquestions',
        title: `${name} - Test YesNoQuestions page`,
      },
      report: {
        url: '/report',
        title: `${name} - Report`,
      },
      settings: {
        url: '/settings',
        title: `${name} - Settings`,
      },
      review: {
        url: '/company/:id/review/:pqfDocumentId',
        title: `${name} - Review :companyName`,
      },
      fileDownload: {
        url: '/download-file/:hash',
        title: `${name} - Download file`,
      },
      company: {
        list: {
          url: '/company',
          title: `${name} - Companies`,
        },
        new: {
          url: '/company/new',
          title: `${name} - New Company`,
        },
        id: {
          url: companyPrefix,
          title: `${name} - :companyName`,
        },
        questions: {
          url: `${companyPrefix}/questions/:contractorLevel(${Object.keys(contractorLevelPathEnum)
            .map(item => contractorLevelPathEnum[item])
            .join('|')})`,
          title: `${name} - Grading Questions for :contractorLevel`,
        },
        temp: {
          url: `${companyPrefix}/temp`,
          title: `${name} - Temp`,
        },
        // adminPanel: {
        //   url: `${companyPrefix}/adminpanel`,
        //   title: `${name} - Admin Panel`,
        //   sectionKey: 'cmpninfo',
        // },
        users: {
          url: `${companyPrefix}/users`,
          title: `${name} - :companyName - Users`,
        },
        settingsInfo: {
          url: `${companyPrefix}/company-information`,
          title: `${name} - :companyName - Company Information`,
          sectionKey: 'cmpninfo',
        },
        officers: {
          url: `${companyPrefix}/officers`,
          title: `${name} - :companyName - Officers`,
          sectionKey: 'officers',
        },
        parentCompany: {
          url: `${companyPrefix}/parent-company`,
          title: `${name} - :companyName - Parent Company`,
          sectionKey: 'parcmpn',
        },
        insurancePolicies: {
          url: `${companyPrefix}/insurance-policies`,
          title: `${name} - :companyName - Insurance Policies`,
          sectionKey: 'insurpol',
        },
        financialInformation: {
          url: `${companyPrefix}/financial-information`,
          title: `${name} - :companyName - Financial Information`,
          sectionKey: 'fininfo',
        },
        organizationInformation: {
          url: `${companyPrefix}/organization-information`,
          title: `${name} - :companyName - Organization Information`,
          sectionKey: 'orginfo',
        },
        workCategories: {
          url: `${companyPrefix}/work-categories`,
          title: `${name} - :companyName - Work Categories`,
          sectionKey: 'workcat',
        },
        services: {
          url: `${companyPrefix}/services`,
          title: `${name} - :companyName - Services Performed`,
          sectionKey: 'services',
        },
        references: {
          url: `${companyPrefix}/references`,
          title: `${name} - :companyName - References`,
          sectionKey: 'referenc',
        },
        legal: {
          url: `${companyPrefix}/legal`,
          title: `${name} - :companyName - Legal`,
          sectionKey: 'legal',
        },
        citations: {
          url: `${companyPrefix}/citations`,
          title: `${name} - :companyName - Citations`,
          sectionKey: 'citations',
        },
        emr: {
          url: `${companyPrefix}/emr`,
          title: `${name} - :companyName - Experience Modification Rate`,
          sectionKey: 'emr',
        },
        envprog: {
          url: `${companyPrefix}/environmental-program`,
          title: `${name} - :companyName - Safety, Health & Environmental Program`,
          sectionKey: 'envprog',
        },
        hearconserv: {
          url: `${companyPrefix}/hearing-conservation`,
          title: `${name} - :companyName - Hearing Conservation`,
          sectionKey: 'hearconserv',
        },
        respprot: {
          url: `${companyPrefix}/respiratory-protection`,
          title: `${name} - :companyName - Respiratory Protection`,
          sectionKey: 'respprot',
        },
        hazardcomm: {
          url: `${companyPrefix}/hazard-communication`,
          title: `${name} - :companyName - Hazard Communication`,
          sectionKey: 'hazardcomm',
        },
        environmentalManagement: {
          url: `${companyPrefix}/environmental-management`,
          title: `${name} - :companyName - Safety, Health & Environmental Management`,
          sectionKey: 'envmgmt',
        },
        osha: {
          url: `${companyPrefix}/osha`,
          title: `${name} - :companyName - OSHA 29 CFR 1910`,
          sectionKey: 'osha',
        },
        spillwaste: {
          url: `${companyPrefix}/spill-waste`,
          title: `${name} - :companyName - Spill Prevention and Waste Minimization`,
          sectionKey: 'spillwaste',
        },
        substabuse: {
          url: `${companyPrefix}/substance-abuse-program`,
          title: `${name} - :companyName - Substance Abuse Program`,
          sectionKey: 'substabuse',
        },
        empeng: {
          url: `${companyPrefix}/employee-english`,
          title: `${name} - :companyName - Employee English`,
          sectionKey: 'empeng',
        },
        medprog: {
          url: `${companyPrefix}/medical-program`,
          title: `${name} - :companyName - Medical Program`,
          sectionKey: 'medprog',
        },
        envmeet: {
          url: `${companyPrefix}/environmental-meetings`,
          title: `${name} - :companyName - Safety, Health and Environmental Meetings`,
          sectionKey: 'envmeet',
        },
        protecteq: {
          url: `${companyPrefix}/personal-protection-equipment`,
          title: `${name} - :companyName - Personal Protection Equipment`,
          sectionKey: 'protecteq',
        },
        corrproc: {
          url: `${companyPrefix}/corrective-action-process`,
          title: `${name} - :companyName - Corrective Action Process`,
          sectionKey: 'corrproc',
        },
        equipmat: {
          url: `${companyPrefix}/equipment-materials`,
          title: `${name} - :companyName - Equipment and Materials`,
          sectionKey: 'equipmat',
        },
        subprog: {
          url: `${companyPrefix}/subcontractor-program`,
          title: `${name} - :companyName - Subcontractor Program`,
          sectionKey: 'subprog',
        },
        inspprog: {
          url: `${companyPrefix}/inspections-and-audit-program`,
          title: `${name} - :companyName - Inspections and Audit Program`,
          sectionKey: 'inspprog',
        },
        envtrain: {
          url: `${companyPrefix}/environmental-training`,
          title: `${name} - :companyName - Safety, Health & Environmental Training`,
          sectionKey: 'envtrain',
        },
        craftsmanCertification: {
          url: `${companyPrefix}/craftsman-certification`,
          title: `${name} - :companyName - Craftsman Certification`,
          sectionKey: 'crafts',
        },
        workfordev: {
          url: `${companyPrefix}/workforce-development`,
          title: `${name} - :companyName - Workforce Development`,
          sectionKey: 'workfordev',
        },
        envdoc: {
          url: `${companyPrefix}/environmental-documentation`,
          title: `${name} - :companyName - Safety, Health & Environmental Documentation`,
          sectionKey: 'envdoc',
        },
        ownerAddendum: {
          url: `${companyPrefix}/addendum/:contractorLevel(${Object.keys(contractorLevelPathEnum)
            .map(item => contractorLevelPathEnum[item])
            .join('|')})`,
          title: `${name} - :companyName - Addendum for :contractorLevel`,
        },
        injuryIllness: {
          url: `${companyPrefix}/injury-illness`,
          title: `${name} - :companyName - Injury & Illness`,
          sectionKey: 'injury',
        },
        contractorAddendum: {
          url: `${companyPrefix}/addendum/:ownerId`,
          title: `${name} - :companyName - Addendum`,
        },
        systemSettings: {
          url: `${companyPrefix}/system-settings`,
          title: `${name} - System Settings`,
        },
        archiveCompany: {
          url: `${companyPrefix}/archive-company`,
          title: `${name} - Archive companies`,
        },
        archiveUser: {
          url: `${companyPrefix}/archive-user`,
          title: `${name} - Archive users`,
        },
        bls: {
          url: `${companyPrefix}/bls-settings`,
          title: `${name} - BLS Settings`,
        },
      },
    },
  };

  return recursiveKey(stack, key);
};

export default routeCreator();

export const title = routeCreator(undefined, 'title');
export const sectionKey = routeCreator(undefined, 'sectionKey');
