import React, { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import formatDate from '../../../../../utils/textFormaters/date';
import { useCompanyInfo } from '../../../../../utils/effects/useCompanyInfo';
import CellName from './CellName';
import ExpirationDate from './ExpirationDate';
import useStyles from './styles';

const DocumentsTable = ({ list, handleLoad, fileDocument, handelSort }) => {
  const { id } = useParams();
  const { companyId } = useCompanyInfo(id);
  const classes = useStyles();

  const columns = [
    {
      title: 'Name',
      field: 'fileName',
      width: '25%',
      render: ({ fileName, key, id }) => (
        <CellName
          fileName={fileName}
          handleLoad={handleLoad}
          companyId={companyId}
          id={id}
          documentKey={key}
          fileDocument={fileDocument}
        />
      ),
      customSort: (a, b) => (a.fileName.toLowerCase() < b.fileName.toLowerCase() ? -1 : 1),
      defaultSort: 'asc',
    },
    {
      title: 'Upload By',
      field: 'createBy',
      width: '30%',
      render: ({ createBy }) => (
        <Typography display="inline" variant="body1">
          {createBy}
        </Typography>
      ),
    },
    {
      title: 'Upload Day',
      field: 'createDate',
      width: '20%',
      render: ({ createDate }) => (
        <Typography display="inline" variant="body1">
          {formatDate(createDate)}
        </Typography>
      ),
    },
    {
      title: 'Expiration Date',
      field: 'expirationDate',
      width: '25%',
      render: ({ expirationDate }) => <ExpirationDate expirationDate={expirationDate} />,
    },
  ];

  return (
    <div className={classes.table}>
      <MaterialTable
        data={list}
        columns={columns}
        options={{
          sorting: true,
          search: false,
          editable: false,
          draggable: false,
          toolbar: false,
          paging: false,
          thirdSortClick: false,
        }}
        icons={{ SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />) }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No Records to Display',
          },
        }}
      />
    </div>
  );
};

export default DocumentsTable;
