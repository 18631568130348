// fork from https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/dataProvider/fetch.ts

import { stringify } from 'query-string';
import { format } from 'date-fns';

import HttpError from './HttpError';

export const createHeadersFromOptions = options => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    }); // as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options && options.body && options.body instanceof FormData) {
    requestHeaders.delete('Content-Type');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

const downloadFile = async response => {
  try {
    const fileName = decodeURI(response.headers.get('file-name'))?.replaceAll('{datetime}', format(new Date(), 'MM-dd-yyyy-HH-mm-ss'));
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (e) {
    console.error('Error in "fetchUtils -> incorrect file loading');
  }
};

export const fetchJson = async (url, options = {}) => {
  const requestHeaders = createHeadersFromOptions(options);

  try {
    const response = await fetch(url, { ...options, headers: requestHeaders });

    let json = {};
    let body = {};

    // check is file
    if (['application/octet-stream', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(response.headers.get('content-type'))) {
      await downloadFile(response);
    } else {
      body = await response.text();
      try {
        json = JSON.parse(body);
      } catch (e) {
        json = body;
      }
    }

    if (response.status === 400) {
      return Promise.reject(new HttpError(json && (json.message || json.messages), response.status, json));
    }

    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(new HttpError(response.statusText, response.status, json));
    }

    return Promise.resolve({
      status: response.status,
      headers: response.headers,
      body,
      json,
    });
  } catch (e) {
    console.error('Error in "fetchUtils -> fetchJson"');
  }
};

export const queryParameters = stringify;

const isValidObject = value => {
  if (!value) {
    return false;
  }

  const isArray = Array.isArray(value);
  const isBuffer = typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
  const isObject = Object.prototype.toString.call(value) === '[object Object]';
  const hasKeys = !!Object.keys(value).length;

  return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path = []) => {
  if (isValidObject(value)) {
    return Object.assign({}, ...Object.keys(value).map(key => flattenObject(value[key], path.concat([key]))));
  } else {
    return path.length ? { [path.join('.')]: value } : value;
  }
};
