import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  load: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
