import React, { memo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { format } from 'date-fns';

import useStyles from '../styles';
import LabelField from '../LabelField';

const Months = () => {
  const classes = useStyles();
  const { values, handleChange } = useFormikContext();

  const getMonthName = monthNumber => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return format(date, 'LLL');
  };

  return (
    <FieldArray
      name="months"
      render={() => (
        <div className={classes.columns}>
          <div className={classes.column}>
            {values.months.slice(0, 6).map((item, index) => (
              <LabelField
                key={item.month}
                name={`months[${index}].hours`}
                id={`months[${index}].hours`}
                onChange={handleChange}
                value={values.months[index].hours}
                label={getMonthName(item.month)}
              />
            ))}
          </div>
          <div className={classes.column}>
            {values.months.slice(6).map((item, index) => (
              <LabelField
                name={`months[${index + 6}].hours`}
                id={`months[${index + 6}].hours`}
                key={item.month}
                onChange={handleChange}
                value={values.months[index + 6].hours}
                label={getMonthName(item.month)}
              />
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default memo(Months);
