import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { workCategory as workCategorySchema, workCategoryGroup as workCategoryGroupSchema } from '../../../schema';
import { currentCompany, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const [groups, categories] = yield all([
      call(dataProvider.getList, 'Definitions/work-categories', {}),
      call(dataProvider.getList, `companies/${companyId}/work-categories`, {}),
    ]);

    // normalize data
    const listCategories = normalize(categories.data.data, [workCategorySchema]);
    const listGroups = normalize(groups.data.data, [workCategoryGroupSchema]);

    yield put({ type: entity.add, payload: listCategories.entities });
    yield put({ type: entity.add, payload: listGroups.entities });

    yield put({
      type: currentCompany.workCategory.get.end,
      payload: { groups: listGroups.result, categories: listCategories.result },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getworkCategorySaga() {
  yield takeLatest(currentCompany.workCategory.get.start, get);
}
