import { call, put, takeLatest } from 'redux-saga/effects';

import { yesNoQuestionsAll, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* get() {
  try {
    const { data } = yield call(dataProvider.getList, `Definitions/yes-no-questions`);
    yield put({
      type: yesNoQuestionsAll.get.end,
      payload: { questions: data },
    });
  } catch (e) {
    yield put({
      type: yesNoQuestionsAll.get.end,
      payload: { questions: [] },
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getYesNoQuestionsAllSaga() {
  yield takeLatest(yesNoQuestionsAll.get.start, get);
}
